import React from 'react';
import Button from '../../components/Button';
import SplitScreen from '../../components/SplitScreen';
import { ReactComponent as Mail } from "../../icons/mail.svg";
import { useNavigate } from 'react-router-dom';

const RegistrationRequestSent: React.FC = () => {
  
  let navigate = useNavigate();

  return (
    <SplitScreen>
      <div className='request-registration-sent'>
        <div className="request-registration-sent__header">
          <div className="typography typography--h1">Registration Request Sent</div>
          <Mail />
        </div>
        <div className="request-registration-sent__content">
          <span className="typography">
            Thanks for creating a new account. Our team will now review your request and if the details you've entered match our systems, they'll invite you by email to create your password.
          </span>
          <Button onClick={() => {navigate("/login")}}>Finish</Button>
        </div>
      </div>
    </SplitScreen>
  );
};

export default RegistrationRequestSent;
