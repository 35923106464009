interface Props {
  onClick?: any;
  children?: any;
  type?: ButtonType;
  size?: string;
  isDisabled?: boolean;
  modifierClassName?: string;
  htmlType?: "button" | "submit" | "reset";
}

export enum ButtonType {
  AdminNavBarButton = "admin-navbar-button",
  Primary = "primary",
  Secondary = "secondary",
}

const Button = ({
  onClick,
  children,
  type = ButtonType.Primary,
  size = "regular",
  isDisabled,
  modifierClassName,
  htmlType = "button",
}: Props) => {
  return (
    <button
      type={htmlType}
      disabled={isDisabled}
      onClick={onClick}
      className={`button ${
        modifierClassName ? modifierClassName : ""
      } button--${type} button--${size} `}
    >
      {children}
    </button>
  );
};

export default Button;
