import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button, { ButtonType } from "../../../components/Button";
import DateRangePicker from "../../../components/DateRangePicker";
import GreenCardsTable from "../../../components/GreenCardTable";
import Search from "../../../components/Search";
import useGetGreenCards from "../../../hooks/useGetGreenCards";
import AdminLayout from "../../../layouts/admin-layout";
import { useAuth } from "../../../contexts/auth";

const GreenCardsList: React.FC = () => {
  const [fetch, setFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const { user } = useAuth();
  const [greenCards, setGreenCards] = useState([]);
  const [numberOfGreenCards, setNumberOfGreenCards] = useState(0);
  const location = useLocation();
  const [dateInterval, setDateInterval] = useState(null);

  const {
    greenCards: greenCardsData,
    isLoading,
    exportGreenCards,
  } = useGetGreenCards(
    fetch,
    itemsPerPage,
    currentPage,
    search,
    dateInterval ? dateInterval[0] : null,
    dateInterval ? dateInterval[1] : null,
    !!location?.pathname?.includes("admin")
  );

  const handlePageChange = (pageNumber: number) => {
    setFetch(true);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
    setFetch(true);
  }, [search, dateInterval]);

  useEffect(() => {
    if (greenCardsData?.greenCards) {
      setGreenCards(greenCardsData?.greenCards);
      setNumberOfGreenCards(greenCardsData.metadata.totalCount);
      setFetch(false);
    }
  }, [greenCardsData]);

  const handleExport = useCallback(() => {
    exportGreenCards()
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = href;
        link.download = `GC-${format(new Date(), "ddMMyyyy")
          .replace(" ", "")
          .replaceAll("/", "")
          .replaceAll(":", "")}.csv`;
        link.click();
        URL.revokeObjectURL(href);
      })
      .catch((err) => console.error(err));
  }, [exportGreenCards]);

  return (
    <AdminLayout loading={isLoading} allowViewOnly>
      <div className="cover-note cover-note--list">
        <div className="cover-note__heading cover-note__heading--list">
          <div className="cover-note__heading__text cover-note__heading__text--gap-l">
            <span className="typography typography--h2">
              Green Card Library
            </span>
          </div>
          <div className="cover-note__header-actions">
            <Button
              size="medium"
              type={ButtonType.Secondary}
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
        </div>
        <div>
          <div className="cover-note__table-actions">
            <div className="cover-note__table-actions__wrapper">
              <Search setReference={setSearch} />
            </div>
            <div className="cover-note__table-actions__double-wrapper">
              <div className="cover-note__table-actions__wrapper cover-note__table-actions__wrapper--small">
                <DateRangePicker
                  value={dateInterval}
                  onChange={setDateInterval}
                />
              </div>
            </div>
          </div>
          <GreenCardsTable
            data={greenCards}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            total={numberOfGreenCards}
            setFetch={setFetch}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default GreenCardsList;
