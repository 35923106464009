import { useCallback, useEffect, useState } from "react";

import { getData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";
import { IpRange } from "../forms/whitelist.form";
import { showAlertMessage } from "../utils/helpers";

const useGetCidrsBlocks = (refresh: boolean) => {
	const { token, silentRenew } = useAuth();
	const [cidrBlocks, setCidrBlocks] = useState<any>(null);

	const getCidrBlocks = useCallback(async (): Promise<void> => {
		try {
			const newToken = await silentRenew();
			const response = await getData(`Infrastructure/ip-whitelist`, newToken);
			if (!response || !response.success || response?.statusCode !== 200 || !Array.isArray(response?.data)) {
				setCidrBlocks({ code: response?.statusCode, message: "Failed to get Ip Whitelist." });
				showAlertMessage("IP Whitelist", "Unable to fetch IP list. Please contact support.", "error");
				return;
			}
			let responseData: IpRange[] = [...response.data];
			responseData.forEach((block) => (block.isNew = false));
			setCidrBlocks(responseData);
		} catch (error: any) {
			setCidrBlocks({ code: error.code, message: error.message });
		}
	}, [silentRenew]);

	useEffect((): void => {
		if (token && refresh) getCidrBlocks();
	}, [token, refresh, getCidrBlocks]);

	return { cidrBlocks };
};

export default useGetCidrsBlocks;
