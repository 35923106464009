import React, { useState } from "react";

import Pagination from "./Pagination";
import GreenCardActionMenu from "./GreenCardActionMenu";
import { format } from "date-fns";
import { hasSixMonthsPassed } from "../utils/helpers";
import { ReactComponent as VoidedIcon } from "../icons/icon-voided.svg";
import { ReactComponent as MigratedIcon } from "../icons/icon-migrated.svg";
import EmptyList from "./EmptyList";
import { Tooltip } from "react-tooltip";
import DocumentMigratedInfo from "./DocumentMigratedInfo";

interface GreenCardsTableProps {
  data: any;
  handlePageChange: (currentPage: number) => void;
  total: number;
  currentPage: number;
  itemsPerPage: number;
  setFetch?: any;
}

const GreenCardsTable = ({
  data,
  handlePageChange,
  currentPage,
  total,
  itemsPerPage,
  setFetch,
}: GreenCardsTableProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      <table className="table table--regular-body">
        <thead>
          <tr>
            <th className="table__header-cell">No</th>
            <th className="table__header-cell">Policy No</th>
            <th className="table__header-cell">Broker</th>
            <th className="table__header-cell">ID</th>
            <th className="table__header-cell">Proposer</th>
            <th className="table__header-cell">Postcode</th>
            <th className="table__header-cell">Reg no</th>
            <th className="table__header-cell">Issued</th>
            <th className="table__header-cell">Start</th>
            <th className="table__header-cell">Expired</th>
            {data.length > 0 && <th className="table__header-cell"></th>}
          </tr>
        </thead>
        <tbody>
          {data.length !== 0 &&
            data.map((greenCard: any) => (
              <tr className={greenCard.isVoided ? "table--void" : ""}>
                <td className="table__cell">
                  {greenCard.number}
                  {greenCard.isVoided ? (
                    <div className="table__cell--icon__voided">
                      <VoidedIcon />
                    </div>
                  ) : greenCard.isMigrated ? (
                    <div className="table__cell--icon__migrated">
                      <MigratedIcon />
                    </div>
                  ) : (
                    <></>
                  )}
                </td>
                <td className="table__cell">{greenCard.policyNumber ?? "-"}</td>

                <td className="table__cell">{greenCard.agencyName}</td>
                <td className="table__cell">{greenCard.brokerName}</td>
                <td
                  className="table__cell table__cell--overflow"
                  onMouseEnter={() => {
                    setShowTooltip(greenCard.policyHolder.length > 12);
                  }}
                  data-tooltip-id={"policy-holder"}
                  data-tooltip-content={greenCard.policyHolder}
                >
                  {greenCard.policyHolder}
                </td>
                <td className="table__cell">{greenCard.postCode}</td>
                <td className="table__cell">
                  {greenCard.registrationNumber ?? "-"}
                </td>
                <td className="table__cell">{greenCard.issued}</td>
                <td className="table__cell">
                  {format(new Date(greenCard.start), "yyyy-MM-dd")}
                </td>
                <td className="table__cell">
                  {format(new Date(greenCard.expired), "yyyy-MM-dd")}
                </td>
                <td className="table__cell">
                  {
                    greenCard.isMigrated ? (
                      <DocumentMigratedInfo />
                    ) : (
                      <GreenCardActionMenu
                        past6Months={hasSixMonthsPassed(
                          new Date(greenCard.expired)
                        )}
                        isExpired={
                          new Date(greenCard.expired).getTime() <
                          new Date().getTime()
                        }
                        id={greenCard.id}
                        setFetch={setFetch}
                        voided={greenCard.isVoided}
                      />
                    )
                  }
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <EmptyList text="There’s no files corresponding to your search." />
      )}
      <Tooltip
        hidden={!showTooltip}
        id="policy-holder"
        noArrow={true}
        className="tooltip tooltip--list"
        place="bottom"
      />
      {data.length > 0 && (
        <Pagination
          currentItemsPerPage={data.length}
          itemsPerPage={itemsPerPage}
          totalItems={total}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default GreenCardsTable;
