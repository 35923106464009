import React, { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../../layouts/admin-layout";
import Button, { ButtonType } from "../../../components/Button";
import questionMarkIcon from "../../../icons/question-mark.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import useGetBroker from "../../../hooks/useGetBroker";
import Input from "../../../components/Input";
import { useAuth } from "../../../contexts/auth";
import { postData, putData } from "../../../utils/fetchApi";
import { BrokerStatus } from "../../../models/broker";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import UnlinkMFA from "../../modals/UnlinkMFA";
import Switch from "../../../components/Switch";
import Checkbox from "../../../components/Checkbox";
import {
  bypassMfaOptions,
  initialForm,
  initialFormErrors,
  userTypeOptions,
} from "../../../forms/user.form";
import Dropdown from "../../../components/Dropdown";
import { handleApiResponseForm, validateForm } from "../../../utils/formHelper";
import { showAlertMessage } from "../../../utils/helpers";

const BrokersRequest: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [fetch, setFetch] = useState(true);
  const params = useParams();
  const [formData, setFormData] = useState(initialForm);
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const location = useLocation();

  const isNew = location.pathname.includes("/new");

  const [loading, setLoading] = useState(false);
  const id = params.id !== undefined ? params.id : "";
  const [postLoading, setPostLoading] = useState(false);
  const { silentRenew } = useAuth();
  const { broker, isLoading } = useGetBroker(id, fetch && !isNew);
  const [openModal, setOpenModal] = useState(false);
  const [isGreenCard, setIsGreenCard] = useState(false || isNew);
  const [isCoverNote, setIsCoverNote] = useState(false || isNew);
  const [isKeyContact, setIsKeyContact] = useState(false || isNew);
  const [isActive, setIsActive] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);

  const navigate = useNavigate();

  const handleApprove = useCallback(async () => {
    setPostLoading(true);
    const newToken = await silentRenew();
    const response = await postData(
      `users/${id}/approveRegistration`,
      {
        userType: formData.userType,
        bypassMfa: formData.bypassMfa
      },
      newToken
    );

    if (!response.success) {
      setHasError(true);
      setPostLoading(false);
      return;
    }

    setPostLoading(false);

    navigate("/admin/brokers");
  }, [id, navigate, silentRenew, formData.userType, formData.bypassMfa]);

  useEffect(() => {
    if (broker) {
      setIsActive(broker.isActive);
      setIsCoverNote(broker.coverNote);
      setIsGreenCard(broker.greenCard);
      setIsKeyContact(broker.keyContact);
      setFormData({
        ...broker
      });
    }
  }, [broker]);

  useEffect(() => {
    if (broker) setFetch(false);
  }, [broker]);

  const handleReject = useCallback(async () => {
    setPostLoading(true);
    const newToken = await silentRenew();
    const response = await postData(
      `users/${id}/rejectRegistration`,
      {},
      newToken
    );

    if (!response.success) {
      showAlertMessage(
        "Error",
        "An error occurred rejecting the broker",
        "error"
      );
      setPostLoading(false);
      setHasError(true);
      return;
    }
    showAlertMessage("Success", "Broker was successfully rejected", "success");
    setPostLoading(false);
    navigate("/admin/brokers");
  }, [id, navigate, silentRenew]);

  useEffect(() => {
    setFormData({
      ...formData,
      isActive: isActive,
      greenCard: isGreenCard,
      coverNote: isCoverNote,
      keyContact: isKeyContact,
      sendWelcomeEmail: sendWelcomeEmail,
    });
  }, [
    isActive,
    sendWelcomeEmail,
    isCoverNote,
    isGreenCard,
    isKeyContact,
    sendWelcomeEmail,
  ]);

  useEffect(() => {
    setFormData({
      ...formData,
      greenCard: formData?.userType === "Broker" ? isGreenCard : true,
      coverNote: formData?.userType === "Broker" ? isCoverNote : true,
    });
  }, [formData?.userType]);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleUnlinkMFA = useCallback(async () => {
    setPostLoading(true);
    const newToken = await silentRenew();
    const response = await postData(
      `users/disableAuthenticator`,
      { brokerId: id },
      newToken
    );
    setOpenModal(false);

    if (!response.success) {
      setPostLoading(false);
      setHasError(true);
      return;
    }
    setFetch(true);

    setPostLoading(false);
  }, [id, silentRenew]);

  const postFormData = useCallback(async () => {
    const newToken = await silentRenew();
    const request = await putData(
      `users/${id as string}/admin`,
      formData,
      newToken
    ).catch(() => {
      setLoading(false);
      return;
    });

    if (!request?.success && request?.statusCode >= 500) {
      setHasError(true);
      setLoading(false);
      return;
    }

    if (!request?.success) {
      handleApiResponseForm(request, initialFormErrors, setFormErrors);
      setLoading(false);
      return;
    }

    setLoading(false);

    navigate("/admin/brokers");
  }, [silentRenew, id, formData, navigate]);

  const handleUpdateProfile = useCallback(
    async (event: any) => {
      event.preventDefault();
      setLoading(true);
      if (!validateForm(formErrors, setFormErrors, formData)) {
        setLoading(false);
        return;
      }
      await postFormData();
    },
    [formData, formErrors, postFormData]
  );

  const handleAddUser = useCallback(
    async (event: any) => {
      event.preventDefault();
      setLoading(true);
      if (!validateForm(formErrors, setFormErrors, formData)) {
        setLoading(false);
        return;
      }
      const newToken = await silentRenew();
      const request = await postData(`users/admin`, formData, newToken).catch(
        () => {
          setLoading(false);
          return;
        }
      );

      if (!request?.success && request?.statusCode >= 500) {
        setHasError(true);
        setLoading(false);

        showAlertMessage("Error", "An error occurred adding the user", "error");

        return;
      }

      if (!request?.success) {
        handleApiResponseForm(request, initialFormErrors, setFormErrors);
        showAlertMessage(
          "Error",
          request?.errorList[0]?.defaultMessage || "Something went wrong",
          "error"
        );
        setHasError(true);
        setLoading(false);
        return;
      }

      setLoading(false);

      showAlertMessage("Success", "User added successfully", "success");

      navigate("/admin/brokers");
    },
    [formData, formErrors, postFormData]
  );

  useEffect(() => {
    if (!hasError) return;

    setHasError(false);
  }, [hasError, formErrors]);

  const handleOnChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      let val = value === "" ? null : value;

      setFormData({
        ...formData,
        [name]: val,
      });
    },
    [formData, setFormData]
  );

  return (
    <AdminLayout
      goBackLink="/admin/brokers"
      loading={isLoading || postLoading || loading}
    >
      <UnlinkMFA
        modalIsOpen={openModal}
        closeModal={onCloseModal}
        onSubmit={handleUnlinkMFA}
      />
      <div className="broker__details">
        <span className="typography typography--h2">
          {isNew
            ? "Add new user"
            : broker && broker.status === BrokerStatus.WaitingForApproval
            ? "User request details"
            : "Edit User details"}
        </span>
        <div className="broker__buttons-wrapper">
          <div className="broker__main-actions">
            <div className="broker__toggles">
              <Switch
                label={"Active"}
                value={formData?.isActive || isActive}
                setValue={setIsActive}
              />
              {broker &&
                broker.status !== BrokerStatus.WaitingForApproval &&
                !isNew && (
                  <Switch
                    label={"Send welcome email now?"}
                    value={sendWelcomeEmail}
                    setValue={setSendWelcomeEmail}
                  />
                )}

              <div className="broker__mfa-button-wrapper">
                <p className="broker__mfa-label typography typography--bold ">
                  Unlink MFA
                  <div
                    className="broker__mfa-icon"
                    data-tooltip-id="unlink-mfa"
                  >
                    <img src={questionMarkIcon} alt="question-mark" />
                  </div>
                </p>
                <Button
                  onClick={() => setOpenModal(true)}
                  size="medium"
                  type={ButtonType.Primary}
                  isDisabled={
                    !broker?.enabledMFA ||
                    broker?.status === BrokerStatus.Rejected
                  }
                >
                  Unlink
                </Button>
              </div>
            </div>
            {formData?.userType === "Broker" &&
              broker?.status === BrokerStatus.Approved && (
                <div className="broker__checkboxs">
                  <Checkbox
                    title="Cover Note"
                    value={formData?.coverNote || isCoverNote}
                    setValue={setIsCoverNote}
                  />
                  <Checkbox
                    title="Green Card"
                    value={formData?.greenCard || isGreenCard}
                    setValue={setIsGreenCard}
                  />

                  <Checkbox
                    title="Key contact"
                    value={formData?.keyContact || isKeyContact}
                    setValue={setIsKeyContact}
                  />
                </div>
              )}
          </div>
          <form className="form form--small-gap">
            <div className="form__input-line">
              <Input
                value={formData.firstName}
                required={!!formErrors.firstName.required}
                label="First name"
                onChange={handleOnChange}
                placeholder="Enter first name"
                maxLength={32}
                hasMessage={
                  formErrors.firstName.error && formErrors.firstName.message
                }
                name={"firstName"}
                disabled={
                  broker &&
                  (broker?.status === BrokerStatus.WaitingForApproval ||
                    broker?.status === BrokerStatus.Rejected)
                }
                smallGap
              />
              <Input
                value={formData.lastName}
                required={!!formErrors.lastName.required}
                label="Last name"
                onChange={handleOnChange}
                maxLength={32}
                placeholder="Enter surname"
                hasMessage={
                  formErrors.lastName.error && formErrors.lastName.message
                }
                name="lastName"
                disabled={
                  broker &&
                  (broker?.status === BrokerStatus.WaitingForApproval ||
                    broker?.status === BrokerStatus.Rejected)
                }
                smallGap
              />
            </div>
            <div className="form__input-line">
              <Input
                value={formData.jobTitle}
                onChange={handleOnChange}
                placeholder="Enter job title"
                name="jobTitle"
                hasMessage={
                  formErrors.jobTitle.error && formErrors.jobTitle.message
                }
                required={!!formErrors.jobTitle.required}
                label="Job title"
                maxLength={75}
                disabled={
                  broker &&
                  (broker?.status === BrokerStatus.WaitingForApproval ||
                    broker?.status === BrokerStatus.Rejected)
                }
                smallGap
              />
              <div className="form__input-line__double">
                <Input
                  value={formData.brokerName}
                  onChange={handleOnChange}
                  placeholder="Enter broker name"
                  hasMessage={
                    formErrors.brokerName.error && formErrors.brokerName.message
                  }
                  maxLength={32}
                  name="brokerName"
                  required={!!formErrors.brokerName.required}
                  label="Broker name"
                  smallGap
                />
                <Input
                  value={formData.agencyNumber}
                  name="agencyNumber"
                  onChange={handleOnChange}
                  placeholder="Enter agency number"
                  hasMessage={
                    formErrors.agencyNumber.error &&
                    formErrors.agencyNumber.message
                  }
                  maxLength={10}
                  required={!!formErrors.agencyNumber.required}
                  label="Agency number"
                  smallGap
                />
              </div>
            </div>
            <div className="form__input-line">
              <Input
                value={formData.companyAddressLine1}
                name="companyAddressLine1"
                placeholder="Enter your company address line 1"
                maxLength={37}
                onChange={handleOnChange}
                hasMessage={
                  formErrors.companyAddressLine1.error &&
                  formErrors.companyAddressLine1.message
                }
                required={!!formErrors.companyAddressLine1.required}
                label="Company Address Line 1"
                disabled={
                  broker &&
                  (broker?.status === BrokerStatus.WaitingForApproval ||
                    broker?.status === BrokerStatus.Rejected)
                }
                smallGap
              />
              <Input
                value={formData.companyAddressLine2}
                label="Company Address Line 2"
                placeholder="Enter your company address line 2"
                maxLength={37}
                hasMessage={
                  formErrors.companyAddressLine2.error &&
                  formErrors.companyAddressLine2.message
                }
                onChange={handleOnChange}
                name="companyAddressLine2"
                required={!!formErrors.companyAddressLine2.required}
                disabled={
                  broker &&
                  (broker?.status === BrokerStatus.WaitingForApproval ||
                    broker?.status === BrokerStatus.Rejected)
                }
                smallGap
              />
            </div>
            <div className="form__input-line">
              <Input
                value={formData.postCode}
                placeholder="Enter Post Code"
                hasMessage={
                  formErrors.postCode.error && formErrors.postCode.message
                }
                name="postCode"
                onChange={handleOnChange}
                label="Post code"
                maxLength={10}
                required={!!formErrors.postCode.required}
                disabled={
                  broker &&
                  (broker?.status === BrokerStatus.WaitingForApproval ||
                    broker?.status === BrokerStatus.Rejected)
                }
                smallGap
              />
              <Input
                value={formData.email}
                name="email"
                placeholder="Enter your email address"
                hasMessage={formErrors.email.error && formErrors.email.message}
                onChange={handleOnChange}
                required={!!formErrors.email.required}
                label="Email address"
                disabled={!isNew}
                smallGap
              />
            </div>
            <div className="form__input-line">
              <Input
                value={formData.workTelephoneNumber}
                placeholder="Enter your phone number"
                hasMessage={
                  formErrors.workTelephoneNumber.error &&
                  formErrors.workTelephoneNumber.message
                }
                name="workTelephoneNumber"
                onChange={handleOnChange}
                required={!!formErrors.workTelephoneNumber.required}
                label="Telephone number"
                disabled={
                  broker &&
                  (broker?.status === BrokerStatus.WaitingForApproval ||
                    broker?.status === BrokerStatus.Rejected)
                }
                smallGap
              />

              <Dropdown
                value={formData.userType}
                required={formErrors.userType.required}
                label="User Type"
                smallGap
                disabled={
                  broker &&
                  broker?.status === BrokerStatus.Rejected
                }
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    userType: option,
                  });
                }}
                options={userTypeOptions}
                placeholder="Please select"
                hasMessage={
                  formErrors.userType.error && formErrors.userType.message
                }
              />
               {broker?.status === BrokerStatus.WaitingForApproval && <Dropdown
                value={formData.bypassMfa ? "Yes":"No"}
                required={formErrors.bypassMfa.required}
                label="Bypass MFA"
                smallGap
                disabled={
                  broker?.status !== BrokerStatus.WaitingForApproval
                }
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    bypassMfa: option === "Yes",
                  });
                }}
                options={bypassMfaOptions}
                hasMessage={
                  formErrors.userType.error && formErrors.userType.message
                }
              />}
            </div>
          </form>
        </div>
        <div className="broker__actions">
          <div className="broker__actions__buttons-wrapper">
            {broker && broker.status === BrokerStatus.WaitingForApproval ? (
              <>
                <Button type={ButtonType.Secondary} onClick={handleReject}>
                  Reject
                </Button>
                <Button type={ButtonType.Primary} onClick={handleApprove}>
                  Approve
                </Button>
              </>
            ) : (
              <>
                <Button
                  type={ButtonType.Secondary}
                  onClick={() => navigate("/admin/brokers")}
                >
                  Cancel
                </Button>
                <Button
                  type={ButtonType.Primary}
                  isDisabled={broker?.status === BrokerStatus.Rejected}
                  onClick={isNew ? handleAddUser : handleUpdateProfile}
                >
                  {isNew ? "Add user" : "Update profile"}
                </Button>
              </>
            )}
          </div>
          {hasError && (
            <span className="typography typography--error">
              Something went wrong.
            </span>
          )}
        </div>
      </div>
      <Tooltip id="unlink-mfa" noArrow={true} className="tooltip" place="right">
        Please use this option when user no longer has access to their device.
        It will send a new email to reconfigure multi-factor-authenticator.
      </Tooltip>
    </AdminLayout>
  );
};

export default BrokersRequest;
