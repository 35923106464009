import { useCallback, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useNavigate } from "react-router-dom";
import { Popover } from "rsuite";
import { ReactComponent as DownloadIcon } from "../icons/export_light.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as RefreshIcon } from "../icons/refresh.svg";

import { useAuth } from "../contexts/auth";
import useGetGreenCardDownloadFile from "../hooks/useGetGreenCardDownloadFile";
import { getData, postData } from "../utils/fetchApi";
import VoidGreenCard from "../pages/modals/VoidGreenCard";
import { UserType } from "../models/broker";

interface Props {
  id: string;
  past6Months: boolean;
  setFetch: (fetch: boolean) => void;
  isExpired?: boolean;
  voided?: boolean;
}

const GreenCardActionMenu: React.FC<Props> = ({
  id,
  past6Months,
  isExpired,
  setFetch,
  voided=false
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const [downloadDocument, setDownloadDocument] = useState(false);
  const { user, token, silentRenew } = useAuth();
  const navigate = useNavigate();
  const [voidOpenModal, setVoidOpenModal] = useState(false);

  const { getGreenCardDownloadFile } = useGetGreenCardDownloadFile(id, downloadDocument);

  const handleDownload = useCallback(async () => {
    try {
      setDownloadDocument(true);
      const downloadedFile = await getGreenCardDownloadFile(id);
      if (!downloadedFile)
        throw new Error("Unable to download file.");
      const blob = await downloadedFile.response.blob();

      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = downloadedFile.suggestedFileName;
      link.click();
      URL.revokeObjectURL(href);
    }
    catch (error) {
      console.error('Error downloading file:', error);
    }
  }, [getGreenCardDownloadFile, id]);

  const voidGreenCard = useCallback(async () => {
    const token = await silentRenew();
    const response = await postData(`greenCards/${id}/void`, {}, token);

    if (!response.success) return;
    onCloseVoidModal();
    setFetch(true);
  }, [id, token]);

  const onCloseVoidModal = () => {
    setVoidOpenModal(false);
  };

  const reuseGreenCard = useCallback(async () => {
    const token = await silentRenew();
    const response = await getData(`greenCards/${id}`, token);

    if (!response.success) return;

    navigate("/greenCards/issue", {
      state: {
        ...response.data,
      },
    });

    setFetch(true);
  }, [id, token]);

  return (
    <>
      <div className="action-menu">
      <div className={voided ? "action-menu__circle action-menu__circle--voided" : "action-menu__circle"} onClick={toggling}>
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="action-menu__circle__ellipsis">
              <div className="action-menu__circle__ellipsis__dot" />
              <div className="action-menu__circle__ellipsis__dot" />
              <div className="action-menu__circle__ellipsis__dot" />
            </div>

            {isOpen && (
              <div className="action-menu__container">
                <Popover visible>
                  <div className="action-menu__container__header">
                    <div className="action-menu__circle__ellipsis">
                      <div className="action-menu__circle__ellipsis__dot action-menu__circle__ellipsis__dot--primary" />
                      <div className="action-menu__circle__ellipsis__dot action-menu__circle__ellipsis__dot--primary" />
                      <div className="action-menu__circle__ellipsis__dot action-menu__circle__ellipsis__dot--primary" />
                    </div>
                  </div>
                  <div className="action-menu__container__actions">
                    {!past6Months && (
                      <Link
                        className="action-menu__container__actions__action"
                        to=""
                        onClick={handleDownload}
                      >
                        <DownloadIcon className="action-menu__container__actions__action__icon" />
                        Download Green Card
                      </Link>
                    )}

                    {!user?.isViewOnly && (
                      <Link
                        className="action-menu__container__actions__action"
                        to=""
                        onClick={reuseGreenCard}
                      >
                        <RefreshIcon className="action-menu__container__actions__action__icon" />
                        Reuse Green Card information
                      </Link>
                    )}

                    {!user?.isViewOnly && !isExpired && !voided && (
                      <Link
                        className="action-menu__container__actions__action"
                        to=""
                        onClick={() => setVoidOpenModal(!voidOpenModal)}
                      >
                        <CloseIcon className="action-menu__container__actions__action__icon" />
                        Void
                      </Link>
                    )}
                  </div>
                </Popover>
              </div>
            )}
          </OutsideClickHandler>
        </div>
      </div>
      <VoidGreenCard
        onSubmit={voidGreenCard}
        modalIsOpen={voidOpenModal}
        closeModal={onCloseVoidModal}
      />
    </>
  );
};

export default GreenCardActionMenu;
