import { CoverNoteStatus } from "../models/broker";
import {
  hasSixMonthsPassed,
  isDateExpired,
  isExpiringIn7DaysOrLess,
} from "../utils/helpers";

import Pagination from "./Pagination";
import CoverNoteActionMenu from "./CoverNoteActionMenu";
import { useAuth } from "../contexts/auth";
import { ReactComponent as VoidedIcon } from "../icons/icon-voided.svg";
import { ReactComponent as MigratedIcon } from "../icons/icon-migrated.svg";
import EmptyList from "./EmptyList";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import DocumentMigratedInfo from "./DocumentMigratedInfo";

interface CoverNotesTableProps {
  coverNotes: any;
  handlePageChange: (currentPage: number) => void;
  numberOfCoverNotes: number;
  currentPage: number;
  itemsPerPage: number;
  setFetch?: any;
}

const CoverNotesTable = ({
  coverNotes,
  handlePageChange,
  currentPage,
  numberOfCoverNotes,
  itemsPerPage,
  setFetch,
}: CoverNotesTableProps) => {
  const { user } = useAuth();
  const [showTooltip, setShowTooltip] = useState(false);
  const isDownloadable = (expireDate: Date, isVoidedDocument: boolean) => {
    if (!user?.isAdmin) return hasSixMonthsPassed(expireDate);

    if (isVoidedDocument) {
      expireDate.setMonth(expireDate.getMonth() + 15);
      const currentDate = new Date();
      return currentDate > expireDate;
    } else {
      expireDate.setMonth(expireDate.getFullYear() + 7);
      const currentDate = new Date();
      return currentDate > expireDate;
    }
  };

  return (
    <>
      <table className="table table--regular-body">
        <thead>
          <tr>
            <th className="table__header-cell">No</th>
            <th className="table__header-cell">Policy No</th>
            <th className="table__header-cell">Product</th>
            <th className="table__header-cell">Broker Ref</th>
            <th className="table__header-cell">Broker</th>
            <th className="table__header-cell">ID</th>
            <th className="table__header-cell">Proposer</th>
            <th className="table__header-cell">Postcode</th>
            <th className="table__header-cell">Reg no</th>
            <th className="table__header-cell">Issued</th>
            <th className="table__header-cell">Start</th>
            <th className="table__header-cell">Expired</th>
            {coverNotes.length > 0 && <th className="table__header-cell"></th>}
          </tr>
        </thead>
        <tbody>
          {coverNotes.length !== 0 &&
            coverNotes.map((coverNote: any) => (
              <tr
                className={coverNote.status === "Voided" ? "table--void" : ""}
              >
                <td className="table__cell">
                  {coverNote.number}
                  {coverNote.status === CoverNoteStatus.Voided ? (
                    <div className="table__cell--icon__voided">
                      <VoidedIcon />
                    </div>
                  ) : coverNote.status === CoverNoteStatus.Migrated ? (
                    <div className="table__cell--icon__migrated">
                      <MigratedIcon />
                    </div>
                  ) : (
                    <></>
                  )}
                </td>
                <td className="table__cell">{coverNote.policyNumber ?? "-"}</td>
                <td className="table__cell">{coverNote.product}</td>
                <td className="table__cell">
                  {coverNote.brokerReference ?? "-"}
                </td>
                <td className="table__cell">{coverNote.agencyName}</td>
                <td className="table__cell">{coverNote.brokerName}</td>
                <td
                  className="table__cell table__cell--overflow"
                  onMouseEnter={() => {
                    setShowTooltip(coverNote.policyHolder.length > 12);
                  }}
                  data-tooltip-id={"policy-holder"}
                  data-tooltip-content={coverNote.policyHolder}
                >
                  {coverNote.policyHolder}
                </td>
                <td className="table__cell">{coverNote.postCode}</td>
                <td className="table__cell">
                  {coverNote.registrationNumber ?? "-"}
                </td>
                <td className="table__cell">{coverNote.issued}</td>
                <td className="table__cell">{coverNote.start}</td>
                <td className="table__cell">{coverNote.expired}</td>
                <td className="table__cell">
                  {
                    coverNote.status === CoverNoteStatus.Migrated ? (
                      <DocumentMigratedInfo />
                    ) : (
                      <CoverNoteActionMenu
                        isDownloadable={isDownloadable(
                          new Date(coverNote.expired),
                          coverNote.status === CoverNoteStatus.Voided
                        )}
                        possibleExtend={
                          isExpiringIn7DaysOrLess(new Date(coverNote.expired)) &&
                          coverNote?.status === CoverNoteStatus.Active
                        }
                        isExpired={isDateExpired(coverNote.expired)}
                        id={coverNote.id}
                        setFetch={setFetch}
                        voided={coverNote.status === CoverNoteStatus.Voided}
                      />
                    )
                  }
                  
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {coverNotes.length === 0 && (
        <EmptyList text="There’s no files corresponding to your search." />
      )}
      <Tooltip
        hidden={!showTooltip}
        id="policy-holder"
        noArrow={true}
        className="tooltip tooltip--list"
        place="bottom"
      />

      {coverNotes.length > 0 && (
        <Pagination
          currentItemsPerPage={coverNotes.length}
          itemsPerPage={itemsPerPage}
          totalItems={numberOfCoverNotes}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default CoverNotesTable;
