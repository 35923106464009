import { BaseErrorForm, BaseField } from "../utils/formHelper";
import { validationType } from "../utils/validations";
import set from "lodash/set";
interface DriverFormErrors extends BaseErrorForm {
  title: BaseField;
  firstName: BaseField;
  surname: BaseField;
  dateOfBirth: BaseField;
}

interface FormErrors extends BaseErrorForm {
  policyNumber: BaseField;
  product: BaseField;
  reasonForIssue: BaseField;
  entityType: BaseField;
  title: BaseField;
  firstName: BaseField;
  surname: BaseField;
  dateOfBirth: BaseField;
  companyName: BaseField;
  addressLine1: BaseField;
  addressLine2: BaseField;
  city: BaseField;
  country: BaseField;
  postCode: BaseField;
  occupation: BaseField;
  driverOptions: BaseField;
  additionalDrivers: DriverFormErrors[];
  vehicle: BaseField;
  make: BaseField;
  model: BaseField;
  cubicCapacityOrGVW: BaseField;
  yearOfManufacture: BaseField;
  registrationNumber: BaseField;
  estimatedValue: BaseField;
  policyUse: BaseField;
  coverBasis: BaseField;
  effective: BaseField;
  expiry: BaseField;
  brokerReference: BaseField;
  specialConditionsOrRestrictions: BaseField;
  agencyName: BaseField;
  agencyNumber: BaseField;
  agencyAddressLine1: BaseField;
  agencyAddressLine2: BaseField;
  agencyPostCode: BaseField;
  agencyTelephoneNumber: BaseField;
}

export const driverForm = {
  title: null,
  firstName: null,
  surname: null,
  dateOfBirth: null,
};

export const initialForm = {
  policyNumber: null,
  product: null,
  reasonForIssue: null,
  entityType: null,
  title: null,
  firstName: null,
  surname: null,
  dateOfBirth: null,
  companyName: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  country: null,
  postCode: null,
  occupation: null,
  driverOptions: null,
  additionalDrivers: [{ ...driverForm }],
  vehicle: null,
  make: null,
  model: null,
  days: null,
  cubicCapacityOrGVW: null,
  yearOfManufacture: null,
  registrationNumber: null,
  estimatedValue: null,
  policyUse: null,
  coverBasis: null,
  effective: null,
  expiry: null,
  brokerReference: null,
  specialConditionsOrRestrictions: null,
  agencyName: null,
  agencyNumber: null,
  agencyAddressLine1: null,
  agencyAddressLine2: null,
  agencyPostCode: null,
  agencyTelephoneNumber: null,
};

export const initialDriverFormErrors: DriverFormErrors = {
  title: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid title.",
    type: validationType.text,
  },
  firstName: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid first name.",
    type: validationType.name,
  },
  surname: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid surname.",
    type: validationType.name,
  },
  dateOfBirth: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid date DD/MM/YYYY.",
    type: validationType.dateISO,
  },
};

export const initialFormErrors: FormErrors = {
  policyNumber: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid policy number.",
    type: validationType.textOrNumber,
  },
  product: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid product.",
    type: validationType.text,
  },
  reasonForIssue: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid reason for issue.",
    type: validationType.text,
  },
  entityType: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid entity type.",
    type: validationType.text,
  },
  title: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid title.",
    type: validationType.text,
  },
  firstName: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid first name.",
    type: validationType.name,
  },
  surname: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid surname.",
    type: validationType.name,
  },
  dateOfBirth: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid date DD/MM/YYYY.",
    type: validationType.dateISO,
  },
  companyName: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid company name.",
    type: validationType.text,
  },
  addressLine1: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid address.",
    type: validationType.text,
  },
  addressLine2: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid address.",
    type: validationType.text,
  },
  city: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid city.",
    type: validationType.name,
  },
  country: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid county.",
    type: validationType.name,
  },
  postCode: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid post code.",
    type: validationType.postCode,
  },
  occupation: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid occupation.",
    type: validationType.text,
  },
  driverOptions: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid driver option.",
    type: validationType.text,
  },
  additionalDrivers: [{ ...initialDriverFormErrors }],
  vehicle: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid vehicle.",
    type: validationType.text,
  },
  make: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid make of vehicle.",
    type: validationType.text,
  },
  model: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid model of vehicle.",
    type: validationType.modelName,
  },
  cubicCapacityOrGVW: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid CC/GVW.",
    type: validationType.number,
  },
  yearOfManufacture: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid year.",
    type: validationType.yearOfManufacture,
  },
  registrationNumber: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid registration number.",
    type: validationType.registrationNumber,
  },
  estimatedValue: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid value.",
    type: validationType.number,
  },
  policyUse: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid value.",
    type: validationType.text,
  },
  coverBasis: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid value.",
    type: validationType.text,
  },
  effective: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid date in the future.",
    type: validationType.dateISO,
  },
  expiry: {
    error: false,
    required: true,
    defaultMessage: "Enter number of days.",
    type: validationType.dateISO,
  },
  brokerReference: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid reference.",
    type: validationType.textNumbersSpecialChars,
  },
  specialConditionsOrRestrictions: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid text.",
    type: validationType.text,
  },
  agencyName: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid broker name.",
    type: validationType.text,
  },
  agencyNumber: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid agency number.",
    type: validationType.agencyNumber,
  },
  agencyPostCode: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid post code.",
    type: validationType.postCode,
  },
  agencyTelephoneNumber: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid telephone number.",
    type: validationType.phoneNumber,
  },
  agencyAddressLine1: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid agency address line 1.",
    type: validationType.text,
  },
  agencyAddressLine2: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid agency address line 2.",
    type: validationType.text,
  },
  defaultMessage: {
    error: false,
    defaultMessage: "Something went wrong",
  },
};

const personalDependencyKeys = [
  "firstName",
  "surname",
  "dateOfBirth",
  "addressLine1",
  "city",
  "title",
  "postCode",
  "country",
  "occupation",
];

const specifiedVehicleDependencyKeys = [
  "make",
  "model",
  "cubicCapacityOrGVW",
  "yearOfManufacture",
  "registrationNumber",
  "estimatedValue",
];

const commercialDependencyKeys = [
  "companyName",
  "addressLine1",
  "city",
  "postCode",
  "country",
  "occupation",
];

export const getAdminFormErrors = (currentForm: FormErrors) => {
  if (!currentForm) currentForm = initialFormErrors;
  const adminKeys = [
    "agencyName",
    "agencyNumber",
    "agencyPostCode",
    "agencyAddressLine1",
    "agencyTelephoneNumber",
  ];

  adminKeys.forEach((it) => {
    set(currentForm, [it, "required"], true);
  });

  return currentForm;
};

export const getFormErrors = (
  type: string,
  currentForm: FormErrors,
  formData: any,
  field?: string,
  formDataValue?: any
) => {
  if (!currentForm) currentForm = initialFormErrors;

  switch (type) {
    case "Personal":
      commercialDependencyKeys.forEach((it) => {
        set(currentForm, [it, "required"], false);
      });
      personalDependencyKeys.forEach((it: string) => {
        set(currentForm, [it, "required"], true);
      });

      break;
    case "Commercial":
      personalDependencyKeys.forEach((it: string) => {
        set(currentForm, [it, "required"], false);
      });
      commercialDependencyKeys.forEach((it) => {
        set(currentForm, [it, "required"], true);
      });
      break;
    case "Specified Vehicle":
      specifiedVehicleDependencyKeys.forEach((it) => {
        set(currentForm, [it, "required"], true);
      });
      break;
      case "Any Vehicle the property of the Policyholder or in their custody or control and for which they are legally responsible":
      case "Any Vehicle owned, hired, loaned or leased to any employee of the Insured, excluding vehicles provided by the Insured":
      specifiedVehicleDependencyKeys.forEach((it) => {
        set(currentForm, [it, "required"], false);
        set(formData, it, null);
      });
      break;
    default:
      break;
  }
  if (
    field === "driverOptions" &&
    type.includes("Named") &&
    type !== null
  ) {
    Array.isArray(currentForm?.additionalDrivers) &&
      currentForm?.additionalDrivers?.forEach((it: DriverFormErrors) => {
        Object.keys(it).forEach((key: any) => {
          //@ts-ignore
          set(it[key], "required", true);
        });
      });
    set(
      formData,
      "additionalDrivers",
      formDataValue ? [...formDataValue] : [{ ...driverForm }]
    );
  } else if (field === "driverOptions") {
    Array.isArray(currentForm?.additionalDrivers) &&
      currentForm?.additionalDrivers?.forEach((it: DriverFormErrors) => {
        Object.keys(it).forEach((key: any) => {
          //@ts-ignore
          set(it[key], "required", false);
        });
      });
  }
  return currentForm;
};

export const selectOptions = {
  policyDetails: {
    product: [
      "Affinities Car (Retail)",
      "Affinities Van (Retail)",
      "Car (Non Standard) (Retail)",
      "Classic Car (Software House) (Retail)",
      "Coach & Bus (Commercial)",
      "Coach, Bus & Minibus (Fleet)",
      "Courier (Commercial)",
      "Enthusiast (Bespoke)",
      "Events (Bespoke)",
      "Farmers Plan (Agriculture)",
      "Goods Hire & Reward (Fleet)",
      "Horsebox (Agriculture)",
      "Minibus (Commercial)",
      "Motor Trade (Commercial)",
      "Motorhome (Bespoke)",
      "Motorhome (Software House) (Retail)",
      "Overseas (Bespoke)",
      "Own Goods & Trades (Fleet)",
      "Prestige (Bespoke)",
      "SCV (Commercial)",
      "Self Drive Hire (Fleet)",
      "Showmans (Commercial)",
      "Taxi (Commercial)",
      "Taxi (Fleet)",
      "Transportation (Commercial)",
      "Van (Non Standard) (Retail)",
      "Classic Car (Direct)",
      "Motorcycle (Motorcycle)"
    ],
    reasonForIssue: [
      "Temporary Driver",
      "Temporary Vehicle",
      "Additional Driver",
      "Additional Vehicle",
      "Change of Driver",
      "Change of Use",
      "Change of Vehicle",
      "Continuation",
      "First Cover Note",
      "New Business",
      "Renewal",
      "Temporary Additional Driver",
      "Temporary Additional Vehicle",
      "Other (ERS Approved)",
    ],
  },
  policyHolderDetails: {
    entityType: ["Personal", "Commercial"],
  },
  driverDetails: {
    driverOptions: [
      "Any Driver",
      "Any Authorised Licensed Driver",
      "Named Drivers Excluding Proposer",
      "Any Authorised Licensed Employee",
      "Any Driver - Excluding Drivers Under 21",
      "Any Driver - Excluding Drivers Under 25 Other Than Those Named",
      "Any Driver - Excluding Drivers Under 25",
      "Any Driver - Excluding Drivers Under 30 Other Than Those Named",
      "Any Driver - Excluding Drivers Under 30",
      "Any Driver - Excluding Drivers Under 35",
      "Any Driver - Excluding Drivers Under 40",
      "Insured And Spouse",
      "Insured And Partner",
      "Insured And Civil Partner",
      "Insured Only",
      "Insured And 1 Named Driver",
      "Insured And 2 Named Drivers",
      "Insured And 3 Named Drivers",
      "Insured And 4 Named Drivers",
      "Insured And Named Drivers",
    ],
  },
  vehicleDetails: {
    vehicles: [
      "Any Vehicle the property of the Policyholder or in their custody or control and for which they are legally responsible",
      "Any Vehicle owned, hired, loaned or leased to any employee of the Insured, excluding vehicles provided by the Insured",
      "Specified Vehicle",
    ],
  },
  policyUse: {
    policyUse: [
      "Commuting",
      "Contract Carried",
      "Carriage of Passengers - Section 22",
      "Carriage of Passengers - Section 22 (2)",
      "Carriage of non paying fare passengers",
      "Public and Private Hire",
      "Personal Business Use",
      "Social, Domestic And Pleasure",
      "Use On Anothers Business",
      "Commercial Travelling",
      "Carriage Of Goods For Hire And Reward",
      "Carriage Of Own Goods",
      "Driving Tuition",
      "Motor Trade",
      "Private Hire",
      "Proposers Business",
      "Public Hire",
      "Proposers, Employers Or Business Partners Business",
      "Named Drivers Business",
      "Wedding Chauffeured Hire",
      "Farming",
      "Class 1 Business",
      "Class 2 Business",
      "z OBU – Internal Use Only",
    ],
  },
  cover: {
    coverBasis: [
      "Comprehensive",
      "Third Party Fire and Theft",
      "Third Party Only",
      "Accidental Damage, Third Party Fire and Theft",
    ],
    numberOfDay: Array.from({ length: 30 }, (_, i) => i + 1),
  },
  title: ["Mr", "Mrs", "Miss", "Ms", "Dr", "Reverend", "Sir", "Lady", "Lord"],
};
