import { useNavigate, useParams } from "react-router";
import Button, { ButtonType } from "../../components/Button";
import MainLayout from "../../layouts/main-layout";
import { useCallback } from "react";
import useGetCoverNoteDownloadFile from "../../hooks/useGetCoverNoteDownloadFile";
import { useAuth } from "../../contexts/auth";

const CreatedCoverNote: React.FC = () => {
  let navigate = useNavigate();
  const { user } = useAuth();

  const { id } = useParams();
  const { getCoverNoteDownloadFile, isLoading } = useGetCoverNoteDownloadFile(id, true);

  const handleDownload = useCallback(async() => {
    if (!id)  return;
    try {
      const downloadedFile = await getCoverNoteDownloadFile(id);
      if (!downloadedFile)
        throw new Error("Unable to download file.");
      const blob = await downloadedFile.response.blob();

      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = downloadedFile.suggestedFileName;
      link.click();
      URL.revokeObjectURL(href);
    }
    catch (error) {
      console.error('Error downloading file:', error);
    }
  }, [getCoverNoteDownloadFile]);

  return (
    <MainLayout isPrivate loading={isLoading} forbidden={!user?.coverNote}>
      <div className="cover-note__created">
        <span className="typography typography--h1">Cover Note created!</span>
        <span className="typography">
          A copy of your cover note has been emailed to your registered address.
          You can also download and reissue cover notes from the Cover Note
          library.
        </span>
        <div className="cover-note__created__button-wrapper">
          <Button
            type={ButtonType.Primary}
            onClick={handleDownload}
            size="medium"
          >
            Download
          </Button>
          <Button
            type={ButtonType.Secondary}
            onClick={() => navigate("/")}
            size="medium"
          >
            Home
          </Button>
        </div>
      </div>
    </MainLayout>
  );
};

export default CreatedCoverNote;
