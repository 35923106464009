import React, { ReactNode } from "react";
import Modal from "react-modal";

interface CustomModalProps {
  open: boolean;
  closeModal: VoidFunction;
  children: ReactNode;
}

const customStyles = {
  content: {
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    border: "0px",
    bottom: "auto",
    maxWidth: 815,
    borderRadius: 0,
    maxHeight: "50%",
    padding: 0,
    backgroundColor: "white",
    width: "100%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 3px 6px #00000029",
  },
};

Modal.setAppElement("#root");

const CustomModal = ({ open, closeModal, children }: CustomModalProps) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles as Modal.Styles}
    >
      <div className="modal">{children}</div>
    </Modal>
  );
};

export default CustomModal;
