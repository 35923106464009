interface Props {
  onChange?: (e: any) => void;
  value: string | null;
  name?: string;
  label?: string;
  placeholder?: string;
  hasMessage?: string | boolean;
  smallGap?: boolean;
  disabled?: boolean;
  maxLength?: number;
  allowNewLines?: boolean
}

const TextArea = ({
  onChange,
  value,
  name,
  label,
  placeholder,
  hasMessage,
  smallGap = false,
  disabled = false,
  maxLength,
  allowNewLines = true
}: Props) => {

  const handleOnKeyDown = (e: any) => {
    if (!allowNewLines && e.key === 'Enter')
      e.preventDefault();
  }

  const onPaste = (e: any) => {
    const pastedText = e.clipboardData.getData('text/plain');
    const pastedTextWithoutLineBreaks = pastedText.replace(/[\r\n]/gm, ' ');
    e.preventDefault(); 

    if (document.queryCommandSupported?.("insertText")) {
      return document.execCommand("insertText", false, pastedTextWithoutLineBreaks);
    }
  }

  return (
    <div
      className={`text-area-wrapper ${
        smallGap && "text-area-wrapper--small-gap"
      }`}
    >
      {label != null && (
        <p className="typography text-2base typography--bold">{label}</p>
      )}
      <textarea
        className={`text-area-wrapper__text-area  ${
          hasMessage && "text-area-wrapper__text-area--error"
        }`}
        value={value || ""}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength ?? 750}
        onKeyDown={handleOnKeyDown}
        onPaste={onPaste}
      ></textarea>
      {!!hasMessage && (
        <div className="text-area-wrapper__message">
          <span className="typography typography--error">{hasMessage}</span>
        </div>
      )}
    </div>
  );
};

export default TextArea;
