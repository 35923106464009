import { validate, ValidationResponse, validationType } from "./validations";

export interface BaseField {
  error: boolean;
  required?: boolean;
  defaultMessage: string;
  message?: string;
  type?: validationType;
}
export interface BaseErrorForm {
  defaultMessage?: BaseField;
}
const isFormValid = <T extends BaseErrorForm>(
  initialFormErrors: T,
  setFormErrors: (form: object) => void,
  formData: any,
  initialDriverFormErrors?: T
): boolean => {
  let formErrorsTemp: any = { ...initialFormErrors };
  let driversErrorsTemp: any = { ...initialDriverFormErrors };

  let newDriversErrorsTemp: any = [];

  Object.keys(formErrorsTemp).forEach((field) => {
    let validation: ValidationResponse = { hasError: false };
    if (formErrorsTemp[field].required || !!formData[field]) {
      validation = validate(
        formData[field],
        formErrorsTemp[field].type,
        formErrorsTemp[field].required
      );
    }
    if (Array.isArray(formErrorsTemp[field])) {
      formErrorsTemp[field].forEach((it: object, index: number) => {
        Object.keys(it).forEach((key) => {
          //@ts-ignore
          const driverKey = it[key];
          const driverData = formData[field][index];
          let validation: ValidationResponse = { hasError: false };
          if (
            Array.isArray(formData[field]) &&
            formData[field].length === 0 &&
            !driverKey.required
          ) {
            validation = { hasError: false };
          } else if (driverKey.required && !!key && !!driverData) {
            //@ts-ignore
            validation = validate(
              driverData[key],
              driverKey.type,
              driverKey.required
            );
          }
          driversErrorsTemp = getFormFieldError(
            driversErrorsTemp,
            key,
            validation.message,
            validation.hasError
          );
        });
        newDriversErrorsTemp.push(driversErrorsTemp);
      });
    } else {
      formErrorsTemp = getFormFieldError(
        formErrorsTemp,
        field,
        validation.message,
        validation.hasError
      );
    }
  });
  //@ts-ignore
  setFormErrors({ ...formErrorsTemp, additionalDrivers: newDriversErrorsTemp });

  for (let driverError of newDriversErrorsTemp) {
    if (
      Object.keys(driverError).some(
        //@ts-ignore
        (field) => driverError[field].error === true
      )
    )
      return false;
  }

  return Object.keys(formErrorsTemp)
    .filter((it) => it !== "additionalDrivers")
    .every((field) => formErrorsTemp[field].error === false);
};

export const validateFormAsync = async <T extends BaseErrorForm>(
  initialFormErrors: T,

  setFormErrors: (form: object) => void,
  formData: object,
  initialDriverFormErrors?: T,
  onSuccess?: () => Promise<void>
): Promise<boolean> => {
  if (
    !isFormValid(
      initialFormErrors,
      setFormErrors,
      formData,
      initialDriverFormErrors
    )
  ) {
    return false;
  }
  if (onSuccess) {
    await onSuccess();
  }
  return true;
};

export const validateForm = <T extends BaseErrorForm>(
  initialFormErrors: T,
  setFormErrors: any,
  formData: object,
  initialDriverFormErrors?: T,
  onSuccess?: () => void
): boolean => {
  if (
    !isFormValid(
      initialFormErrors,
      setFormErrors,
      formData,
      initialDriverFormErrors
    )
  ) {
    return false;
  }
  if (onSuccess) {
    onSuccess();
  }
  return true;
};

export const handleApiResponseForm = <T extends BaseErrorForm>(
  request: any,
  initialFormErrors: T,
  setFormErrors: any
) => {
  let formErrorsTemp: any = { ...initialFormErrors };

  if (!formErrorsTemp.hasOwnProperty("defaultMessage")) {
    return;
  }

  if (request?.errorList?.length) {
    //to add error message if available
    request.errorList.map((err: any) => {
      let prop =
        Object.keys(err)[0].charAt(0).toLowerCase() +
        Object.keys(err)[0].slice(1);
      //validate if key exists in formErrors before adding it
      if (Object.keys(formErrorsTemp).indexOf(prop) > -1) {
        formErrorsTemp = getFormFieldError(formErrorsTemp, prop, err[prop]);
      }
    });
  }
  if (
    Object.keys(formErrorsTemp).every(
      (field) => formErrorsTemp[field].error === false
    )
  ) {
    formErrorsTemp.defaultMessage.error = true;
  }
  setFormErrors(formErrorsTemp);
};

export const getFormFieldError = <T extends BaseErrorForm>(
  formErrors: any,
  prop: string,
  errorMessage?: string,
  error?: boolean
): T =>
  errorMessage
    ? {
        ...formErrors,
        [prop]: {
          ...formErrors[prop],
          error: error ?? true,
          message: errorMessage,
        },
      }
    : {
        ...formErrors,
        [prop]: {
          ...formErrors[prop],
          error: error ?? true,
          message: formErrors[prop].defaultMessage,
        },
      };
