import { BaseErrorForm, BaseField } from "../utils/formHelper";
import { validationType } from "../utils/validations";
import set from "lodash/set";

interface FormErrors extends BaseErrorForm {
  policyNumber: BaseField;
  entityType: BaseField;
  title: BaseField;
  firstName: BaseField;
  surname: BaseField;
  companyName: BaseField;
  addressLine1: BaseField;
  addressLine2: BaseField;
  city: BaseField;
  county: BaseField;
  postCode: BaseField;
  categoryVehicle: BaseField;
  make: BaseField;
  registrationNumber: BaseField;
  effective: BaseField;
  expiry: BaseField;
}

export const initialForm = {
  policyNumber: null,
  entityType: null,
  title: null,
  firstName: null,
  surname: null,
  companyName: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  county: null,
  postCode: null,
  categoryVehicle: null,
  make: null,
  registrationNumber: null,
  effective: null,
  expiry: null,
};

export const initialFormErrors: FormErrors = {
  policyNumber: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid policy number.",
    type: validationType.textOrNumber,
  },
  entityType: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid entity type.",
    type: validationType.text,
  },
  title: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid title.",
    type: validationType.text,
  },
  firstName: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid first name.",
    type: validationType.name,
  },
  surname: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid surname.",
    type: validationType.name,
  },
  companyName: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid company name.",
    type: validationType.text,
  },
  addressLine1: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid address.",
    type: validationType.text,
  },
  addressLine2: {
    error: false,
    required: false,
    defaultMessage: "Enter a valid address.",
    type: validationType.text,
  },
  city: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid city.",
    type: validationType.name,
  },
  county: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid county.",
    type: validationType.name,
  },
  postCode: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid post code.",
    type: validationType.postCode,
  },
  categoryVehicle: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid category of vehicle.",
    type: validationType.text,
  },
  make: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid make.",
    type: validationType.text,
  },
  registrationNumber: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid registration number.",
    type: validationType.registrationNumber,
  },
  effective: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid date.",
    type: validationType.dateISO,
  },
  expiry: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid date.",
    type: validationType.dateISO,
  },

  defaultMessage: {
    error: false,
    defaultMessage: "Something went wrong",
  },
};

const personalDependencyKeys = [
  "firstName",
  "surname",
  "addressLine1",
  "city",
  "title",
  "postCode",
  "county",
];

const specifiedVehicleDependencyKeys = ["make", "registrationNumber"];

const commercialDependencyKeys = [
  "companyName",
  "addressLine1",
  "city",
  "postCode",
  "county",
];

export const getFormErrors = (
  type: string,
  currentForm: FormErrors,
  formData: any
) => {
  if (!currentForm) currentForm = initialFormErrors;

  switch (type) {
    case "Personal":
      commercialDependencyKeys.forEach((it) => {
        set(currentForm, [it, "required"], false);
        set(formData, it, null);
      });
      personalDependencyKeys.forEach((it: string) => {
        set(currentForm, [it, "required"], true);
      });

      break;
    case "Commercial":
      personalDependencyKeys.forEach((it: string) => {
        set(currentForm, [it, "required"], false);
        set(formData, it, null);
      });
      commercialDependencyKeys.forEach((it) => {
        set(currentForm, [it, "required"], true);
      });
      break;
    case "Specified Vehicle":
      specifiedVehicleDependencyKeys.forEach((it) => {
        set(currentForm, [it, "required"], true);
      });
      break;
    case "Any Vehicle the property of the Policyholder or in their custody or control and for which they are legally responsible":
      specifiedVehicleDependencyKeys.forEach((it) => {
        set(currentForm, [it, "required"], false);
        set(formData, it, null);
      });
      break;
    default:
      break;
  }

  return currentForm;
};

export const selectOptions = {
  nameOfInsured: {
    entityType: ["Personal", "Commercial"],
  },
  vehicleDetails: {
    vehicles: [
      "Car",
      "Motorcycle",
      "Lorry Or Tractor",
      "Cycle fitted with auxiliary engine",
      "Bus",
      "Trailer",
      "Other",
    ],
  },
  title: ["Mr", "Mrs", "Miss", "Ms", "Dr", "Reverend", "Sir", "Lady", "Lord"],
};
