import { useCallback, useEffect, useState } from "react";

import { getData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";

const useGetCoverNotesFiles = (year: number = new Date().getFullYear()) => {
  const { token, silentRenew } = useAuth();
  const [coverNotesFiles, setCoverNotesFiles] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const getCoverNotesFiles = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      const newToken = await silentRenew();
      const response = await getData(
        `coverNotes/monthlytotals?year=${year}`,
        newToken
      );
      setCoverNotesFiles(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setCoverNotesFiles({ code: error.code, message: error.message });
      setIsLoading(false);
    }
  }, [silentRenew, year]);

  useEffect((): void => {
    if (token) getCoverNotesFiles();
  }, [token, year]);

  return { coverNotesFiles, isLoading };
};

export default useGetCoverNotesFiles;
