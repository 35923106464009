const baseUrl = process.env.REACT_APP_PUBLIC_DOMAIN
  ? process.env.REACT_APP_PUBLIC_DOMAIN
  : `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }`;

export interface ApiResponse {
  success: boolean;
  error: string | null;
  errorList: string[] | null;
  statusCode: number;
  data: any;
}

export const getData = async (
  url: string,
  token: string | null,
  isExport = false
) => {
  const res = await fetch(`${baseUrl}/api/${url}`, {
    method: "GET",
    headers: createHeaders(token),
  });
  return await TryGetData(res, isExport);
};

export const putData = async (
  url: string,
  post: any,
  token?: string | null
) => {
  const res = await fetch(`${baseUrl}/api/${url}`, {
    method: "PUT",
    headers: createHeaders(token),
    body: JSON.stringify(post),
  });

  return await TryGetData(res);
};
export const postData = async (
  url: string,
  post: any,
  token: string | null = null
) => {
  const res = await fetch(`${baseUrl}/api/${url}`, {
    method: "POST",
    headers: createHeaders(token),
    body: JSON.stringify(post),
  });

  return await TryGetData(res);
};
const createHeaders = (token?: string | null): HeadersInit => {
  let headers: HeadersInit;
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
    };
  }
  return headers;
};
async function TryGetData(res: any, isExport?: boolean) {
  let response: ApiResponse = {
    success: false,
    error: null,
    errorList: ["Something went wrong"],
    statusCode: 500,
    data: null,
  };
  try {
    if (!res || res.status >= 500) {
      return response;
    }
    if (res.status === 200 && isExport) {
      return res;
    }
    if (res.status === 400) {
      response.statusCode = res.status;
      const data = await res.json();
      response.errorList = data.errorList;

      return response;
    }
    if (res.status === 403) {
      response.statusCode = res.status;
      return response;
    }
    if (res.status === 401) {
      response.statusCode = res.status;
      const data = await res.json();
      response.errorList = data.errorList;
      return response;
    }
    if (res.status >= 300) {
      response.statusCode = res.status;
      return response;
    }
    if (res.status !== 204) {
      response.data = await res.json();
    }
    response.success = true;
    response.statusCode = res.status;
    response.errorList = null;
    return response;
  } catch (error: any) {
    if (error.hasOwnProperty("message")) {
      response.error = error.message;
    }

    return response;
  }
}
