import { format, zonedTimeToUtc } from "date-fns-tz";
import { parseISO } from "date-fns";
import {
  CreateTokenResponse,
  ResponseToken,
  UserToken,
} from "../contexts/auth";
import jwt_decode from "jwt-decode";
import { ReactComponent as InfoSuccess } from "../icons/info-success.svg";
import { ReactComponent as InfoError } from "../icons/info-error.svg";
import { toast } from "react-toastify";
import React from "react";
import { UserType, HiddenUserType } from "../models/broker";

const formatInTimeZone = (date: string | Date, fmt: string) =>
  format(zonedTimeToUtc(date, "+0100"), fmt);

const formatDate = (date: string, fmt: string) =>
  formatInTimeZone(parseISO(`${date}`), fmt);

const fromNumberToOrdinalWord = (number: number): string => {
  const ordinals = ["First", "Second", "Third", "Fourth"];

  if (ordinals[number] === undefined) return "No ordinal implemented yet!";

  return ordinals[number];
};

const download = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const getMinimumDateOfBirthForADriver = (): Date => {
  let date = new Date();
  date.setFullYear(date.getFullYear() - 17);

  return date;
};

const isDateExpired = (givenDate: string) => {
  // Parse the given date string into a Date object
  const dateObj = new Date(givenDate);

  // Get the current date and time
  const now = new Date();

  // Compare the given date to the current date
  return dateObj < now;
};

function isExpiringIn7DaysOrLess(expiryDate: Date) {
  const currentDate = new Date();

  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() + 7);

  const expirationDate = new Date(expiryDate);

  if (expirationDate.getTime() < currentDate.getTime()) return false;
  if (expirationDate.getTime() <= futureDate.getTime()) {
    return true;
  } else {
    return false;
  }
}

const hasSixMonthsPassed = (expiredDate: Date) => {
  expiredDate.setMonth(expiredDate.getMonth() + 6);
  const currentDate = new Date();
  return currentDate > expiredDate;
};

const convertToken = (response: any): UserToken | null => {
  try {
    const tokenResponse = response as CreateTokenResponse;
    const responseToken = jwt_decode<ResponseToken>(tokenResponse.accessToken);
    return {
      enabledMFA: responseToken?.enabledMFA,
      firstName: responseToken?.firstName,
      expire: new Date(1000 * responseToken?.exp),
      isAdmin: responseToken?.userType === UserType.Admin,
      isViewOnly: responseToken?.userType === UserType.ViewOnly,
      greenCard:
        responseToken.documentsAccessType === "GreenCardOnly" ||
        responseToken.documentsAccessType === "All" ||
        responseToken?.userType === UserType.Admin,
      coverNote:
        responseToken.documentsAccessType === "CoverNoteOnly" ||
        responseToken.documentsAccessType === "All" ||
        responseToken?.userType === UserType.Admin,

      accessToken: tokenResponse.accessToken,
      refreshToken: tokenResponse.refreshToken,
      userId: responseToken.userId,
      isInfrastructure: responseToken?.userType === HiddenUserType.InfrastructureAdmin,
    };
  } catch {
    return null;
  }
};

const ToastContent = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => (
  <>
    <div className="font-TTNPBold text-2base text-color-text">{title}</div>
    <div className="font-TTNPRegular text-base text-color-text">{message}</div>
  </>
);

const showAlertMessage = (title: string, message: string, type: string) => {
  if (type === "error") {
    toast.error(<ToastContent title={title} message={message} />, {
      icon: () => <InfoError />,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else if (type === "success") {
    toast.success(<ToastContent title={title} message={message} />, {
      icon: () => <InfoSuccess />,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};

function extractFileNameFromHeader(headerValue: string) {
  const match = headerValue.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  if (!match) {
    return null;
  }

  const encodedFileName = match[1];
  try {
    const decodedFileName = decodeURIComponent(encodedFileName);
    return decodedFileName;
  } catch (error) {
    return null;
  }
}

export {
  formatDate,
  isExpiringIn7DaysOrLess,
  fromNumberToOrdinalWord,
  download,
  getMinimumDateOfBirthForADriver,
  showAlertMessage,
  isDateExpired,
  convertToken,
  hasSixMonthsPassed,
  extractFileNameFromHeader,
};
