import { useCallback, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useNavigate } from "react-router-dom";
import { Popover } from "rsuite";
import { ReactComponent as DownloadIcon } from "../icons/export_light.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as RefreshIcon } from "../icons/refresh.svg";
import { ReactComponent as ContinuationIcon } from "../icons/folder-send.svg";
import { download, showAlertMessage } from "../utils/helpers";
import useGetCoverNoteDownloadFile from "../hooks/useGetCoverNoteDownloadFile";
import { getData, postData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";
import VoidCoverNote from "../pages/modals/VoidCoverNote";

interface Props {
  id: string;
  setFetch: (fetch: boolean) => void;
  possibleExtend: boolean;
  isDownloadable: boolean;
  isExpired?: boolean;
  voided?: boolean;
}

const CoverNoteActionMenu: React.FC<Props> = ({
  id,
  isExpired,
  isDownloadable,
  possibleExtend,
  setFetch,
  voided = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const [downloadDocument, setDownloadDocument] = useState(false);
  const { user, silentRenew } = useAuth();
  const navigate = useNavigate();
  const [voidOpenModal, setVoidOpenModal] = useState(false);
  const { getCoverNoteDownloadFile } = useGetCoverNoteDownloadFile(
    id,
    downloadDocument
  );

  const handleDownload = useCallback(async () => {
    try {
      setDownloadDocument(true);
      const downloadedFile = await getCoverNoteDownloadFile(id);
      if (!downloadedFile) throw new Error("Unable to download file.");
      const blob = await downloadedFile.response.blob();

      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = downloadedFile.suggestedFileName;
      link.click();
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }, [getCoverNoteDownloadFile, id]);

  const voidCoverNote = useCallback(async () => {
    const newToken = await silentRenew();
    const response = await postData(`coverNotes/${id}/void`, {}, newToken);

    if (!response.success) {
      showAlertMessage(
        "Error",
        "An error occurred voiding a cover note",
        "error"
      );

      onCloseVoidModal();
      return;
    }
    showAlertMessage(
      "Success",
      "The cover note was successfully voided",
      "success"
    );

    onCloseVoidModal();
    setFetch(true);
  }, [id, setFetch, silentRenew]);

  const onCloseVoidModal = () => {
    setVoidOpenModal(false);
  };

  const reuseCoverNote = useCallback(async () => {
    const token = await silentRenew();
    const response = await getData(`coverNotes/${id}`, token);

    if (!response.success) return;
    if (response.data)
      navigate("/coverNotes/issue", {
        state: {
          ...response.data,
        },
      });

    setFetch(true);
  }, [id, navigate, setFetch, silentRenew]);

  const handleIssueContinuationCoverNote = useCallback(async () => {
    const token = await silentRenew();
    const response = await getData(`coverNotes/${id}`, token);

    if (!response.success) return;
    if (navigate) {
      navigate(`/coverNotes/issue/${id}/extension`, {
        replace: true,
        state: {
          coverNote: response.data,
          isExtension: true,
        },
      });
    }
  }, [id, navigate, silentRenew]);

  return (
    <>
      <div className="action-menu">
        <div
          className={
            voided
              ? "action-menu__circle action-menu__circle--voided"
              : "action-menu__circle"
          }
          onClick={toggling}
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="action-menu__circle__ellipsis">
              <div className="action-menu__circle__ellipsis__dot" />
              <div className="action-menu__circle__ellipsis__dot" />
              <div className="action-menu__circle__ellipsis__dot" />
            </div>

            {isOpen && (
              <div className="action-menu__container">
                <Popover visible>
                  <div className="action-menu__container__header">
                    <div className="action-menu__circle__ellipsis">
                      <div className="action-menu__circle__ellipsis__dot action-menu__circle__ellipsis__dot--primary" />
                      <div className="action-menu__circle__ellipsis__dot action-menu__circle__ellipsis__dot--primary" />
                      <div className="action-menu__circle__ellipsis__dot action-menu__circle__ellipsis__dot--primary" />
                    </div>
                  </div>
                  <div className="action-menu__container__actions">
                    {!isDownloadable && (
                      <Link
                        className="action-menu__container__actions__action"
                        to=""
                        onClick={handleDownload}
                      >
                        <DownloadIcon className="action-menu__container__actions__action__icon" />
                        Download Cover Note
                      </Link>
                    )}
                    {!user?.isViewOnly && (
                      <Link
                        className="action-menu__container__actions__action"
                        to=""
                        onClick={reuseCoverNote}
                      >
                        <RefreshIcon className="action-menu__container__actions__action__icon" />
                        Reuse Cover Note information
                      </Link>
                    )}
                    {!user?.isViewOnly && possibleExtend && (
                      <button
                        className="action-menu__container__actions__action"
                        onClick={handleIssueContinuationCoverNote}
                      >
                        <ContinuationIcon className="action-menu__container__actions__action__icon" />
                        Issue continuation Cover Note
                      </button>
                    )}

                    {!user?.isViewOnly && !isExpired && !voided && (
                      <Link
                        className="action-menu__container__actions__action"
                        to=""
                        onClick={() => setVoidOpenModal(!voidOpenModal)}
                      >
                        <CloseIcon className="action-menu__container__actions__action__icon" />
                        Void
                      </Link>
                    )}
                  </div>
                </Popover>
              </div>
            )}
          </OutsideClickHandler>
        </div>
      </div>
      <VoidCoverNote
        onSubmit={voidCoverNote}
        modalIsOpen={voidOpenModal}
        closeModal={onCloseVoidModal}
      />
    </>
  );
};

export default CoverNoteActionMenu;
