import { useCallback, useEffect, useState } from "react";
import useGetCoverNotes from "../../hooks/useGetCoverNotes";
import MainLayout from "../../layouts/main-layout";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import Button, { ButtonType } from "../../components/Button";
import Search from "../../components/Search";
import Dropdown from "../../components/Dropdown";
import { selectOptions } from "../../forms/brokerIssueCoverNote.form";
import DateRangePicker from "../../components/DateRangePicker";

import CoverNotesTable from "../../components/CoverNotesTable";
import { format } from "date-fns";

const CoverNoteLibrary: React.FC = () => {
  const [fetch, setFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [product, setProduct] = useState("");
  const { user } = useAuth();
  const [coverNotes, setCoverNotes] = useState([]);
  const navigate = useNavigate();
  const [numberOfCoverNotes, setNumberOfCoverNotes] = useState(0);

  const [dateInterval, setDateInterval] = useState(null);

  const {
    coverNotes: coverNotesData,
    isLoading,
    exportCoverNotes,
  } = useGetCoverNotes(
    fetch,
    itemsPerPage,
    currentPage,
    search,
    product,
    dateInterval ? dateInterval[0] : null,
    dateInterval ? dateInterval[1] : null
  );

  const handlePageChange = (pageNumber: number) => {
    setFetch(true);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
    setFetch(true);
  }, [search, product, dateInterval]);

  useEffect(() => {
    if (coverNotesData?.coverNotes) {
      setCoverNotes(coverNotesData?.coverNotes);
      setNumberOfCoverNotes(coverNotesData.metadata.totalCount);
      setFetch(false);
    }
  }, [coverNotesData]);

  const handleExport = useCallback(() => {
    exportCoverNotes()
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = href;
        link.download = `CN-${format(new Date(), "ddMMyyyy")
          .replace(" ", "")
          .replaceAll("/", "")
          .replaceAll(":", "")}.csv`;
        link.click();
        URL.revokeObjectURL(href);
      })
      .catch((err) => console.error(err));
  }, [exportCoverNotes]);

  return (
    <MainLayout isPrivate loading={isLoading} forbidden={!user?.coverNote}>
      <div className="cover-note cover-note--list">
        <Link
          className="typography text-2base typography--link typography--bold"
          to={"/"}
        >
          Go back
        </Link>
        <div className="cover-note__heading cover-note__heading--list">
          <div className="cover-note__heading__text cover-note__heading__text--gap-l">
            <span className="typography typography--h2">
              Cover Note Library
            </span>
            <span className="typography typography--h3">
              Showing for{" "}
              <span className="typography typography--h3 typography--primary">
                {user?.firstName}
              </span>
            </span>
          </div>
          <div className="cover-note__header-actions">
            {!user?.isViewOnly && (
              <Button onClick={() => navigate("/coverNotes/issue")}>
                Create cover note
              </Button>
            )}
            <Button
              size="medium"
              type={ButtonType.Secondary}
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
        </div>
        <div>
          <div className="cover-note__table-actions">
            <div className="cover-note__table-actions__wrapper">
              <Search setReference={setSearch} />
            </div>
            <div className="cover-note__table-actions__double-wrapper">
              <div className="cover-note__table-actions__wrapper">
                <Dropdown
                  onChange={setProduct}
                  isClearable={true}
                  placeholder="Filter by product"
                  options={selectOptions.policyDetails.product}
                  value={product}
                />
              </div>
              <div className="cover-note__table-actions__wrapper cover-note__table-actions__wrapper--small">
                <DateRangePicker
                  value={dateInterval}
                  onChange={setDateInterval}
                />
              </div>
            </div>
          </div>
          <CoverNotesTable
            coverNotes={coverNotes}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            numberOfCoverNotes={numberOfCoverNotes}
            setFetch={setFetch}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default CoverNoteLibrary;
