import { useCallback, useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactComponent as ArrowSelect } from "../icons/arrow-select.svg";
import { ReactComponent as CloseIcon } from "../icons/clear-dropdown.svg";
import { ReactComponent as SearchIcon } from "../icons/search.svg";

interface Props {
  onChange: (e: any) => void;
  value: string | null;
  options: any;
  label?: string;
  placeholder?: string;
  hasMessage?: string | boolean;
  smallGap?: boolean;
  disabled?: boolean;
  fullHeight?: boolean;
  isClearable?: boolean;
  required?: boolean;
}

const Dropdown = ({
  onChange,
  value,
  options,
  label,
  placeholder,
  hasMessage,
  disabled,
  required,
  smallGap = false,
  isClearable,
  fullHeight = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const toggling = () => !disabled && setIsOpen(!isOpen);

  const handleClear = useCallback(() => {
    if (onChange && !disabled) onChange("");
  }, [disabled, onChange]);

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };

  const filteredOptions = useMemo(() => {
    const orderedOptions = [...options];

    if (
      orderedOptions &&
      orderedOptions.length > 0 &&
      isNaN(orderedOptions[0])
    ) {
      orderedOptions.sort();
    }

    return (
      (search &&
        orderedOptions.filter((option: any) =>
          !isNaN(option)
            ? option.toString().includes(search)
            : option.toLowerCase().includes(search.toLowerCase())
        )) ||
      orderedOptions
    );
  }, [search, options]);

  return (
    <div className={`drop-down ${smallGap && "input-wrapper--small-gap"}`}>
      {label != null && (
        <p className="typography typography--bold typography--label-input">
          {label}
          {required && (
            <span className="typography typography--bold typography--label-input">
              *
            </span>
          )}
        </p>
      )}
      <OutsideClickHandler
        disabled={disabled}
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <div
          className={`drop-down__header ${
            fullHeight && "drop-down__header--full-height"
          } ${hasMessage && "drop-down__header--error"}`}
        >
          {value ? (
            <span
              onClick={toggling}
              className={`flex-1  typography ${
                disabled ? "cursor-not-allowed opacity-30" : ""
              }`}
            >
              {value}
            </span>
          ) : (
            <span
              onClick={toggling}
              className={`flex-1  typography typography--italic ${
                disabled ? "cursor-not-allowed opacity-30" : ""
              }`}
            >
              {placeholder}
            </span>
          )}
          <div className="drop-down__header__arrow">
            {value && isClearable && <CloseIcon onClick={handleClear} />}

            <ArrowSelect onClick={toggling} />
          </div>
        </div>

        {isOpen && (
          <div className="drop-down__container">
            <ul className="drop-down__list">
              <div className="search">
                <div className="search__container">
                  <div className="search__icon">
                    <SearchIcon />
                  </div>
                  <input
                    className="search__input"
                    autoComplete={
                      !process.env.NODE_ENV ||
                      process.env.NODE_ENV === "development"
                        ? "on"
                        : "new-password"
                    }
                    name="Search"
                    value={search}
                    placeholder="Search"
                    type="Text"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {filteredOptions.map((option: any, key: any) => (
                <li
                  className="drop-down__list-item"
                  onClick={() => {
                    onChange(option);
                    setIsOpen(!isOpen);
                  }}
                  key={key}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </OutsideClickHandler>

      {!!hasMessage && (
        <div className="input-wrapper__message">
          <span className="typography typography--error">{hasMessage}</span>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
