import React, { Dispatch, SetStateAction } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import YearDropdown from "./YearDropdown";
interface FilesChartProps {
  title: string;
  dataSet: any;
  currentYear: number;
  years: number[];
  onChangeYear: Dispatch<SetStateAction<number>>;
}

const FilesChart = ({
  title,
  dataSet,
  currentYear,
  years,
  onChangeYear,
}: FilesChartProps) => {
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="charts__tooltip">
          <p className="charts__tooltip-text">
            <span className="charts__tooltip-text charts__tooltip-text--bold">{`${payload[0].value}`}</span>{" "}
            Records
          </p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="charts">
      <div className="charts__header">
        <div className="typography typography--h4">{title}</div>
        <YearDropdown
          value={currentYear}
          options={years}
          onChangeYear={onChangeYear}
        />
      </div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minHeight={220}
        maxHeight={230}
      >
        <LineChart
          width={500}
          height={300}
          data={dataSet}
          margin={{
            top: 5,
            right: 50,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid
            strokeDasharray="4 0"
            stroke="#9E9E9E80"
            horizontal={true}
            vertical={false}
          />
          <XAxis
            dataKey="month"
            stroke="#9E9E9E90"
            fontFamily={"TT Norms Pro Regular"}
            tickMargin={8}
            fontSize={12}
            tickLine={false}
          />
          <YAxis
            stroke="#9E9E9E90"
            fontFamily={"TT Norms Pro Regular"}
            tickMargin={8}
            fontSize={12}
            axisLine={false}
          />
          <Tooltip content={<CustomTooltip />} cursor={false} />

          <Line
            type="monotone"
            dataKey="total"
            stroke="#BF000D"
            strokeWidth={2}
            dot={{ fill: "#BF000D" }}
            activeDot={{ stroke: "#000000", fill: "#000000" }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FilesChart;
