import { Navigate, useLocation } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import { useAuth } from "../contexts/auth";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isLoading) {
    if (!auth.signed || auth.isExpired) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  if (auth.isLoading) {
    return <LoadingSpinner fullHeight={true} />;
  } else {
    return process.env.REACT_APP_PUBLIC_GO_LIVE === "false" &&
      location.pathname !== "/liveSoon" ? (
      <Navigate to="/liveSoon" />
    ) : (
      children
    );
  }
};
export default RequireAuth;
