import { validate, validationType } from "../utils/validations";

export interface IpRange {
	cidrIp: string;
	description: string;
	isNew?: boolean | null;
	cidrIpMessage?: string;
	descriptionMessage?: string;
}

export const validateForm = (cidrList: IpRange[]) => {
	let hasError = false;
	let validateCidrBlocks = [...cidrList];

	validateCidrBlocks.forEach((block) => {
		const validateCidrId = validate(block.cidrIp, validationType.cidr, true);
		if (validateCidrId?.hasError) {
			block.cidrIpMessage = "Invalid CIDR block";
			hasError = true;
		}
		const duplicates = cidrList.filter((d) => d.cidrIp === block.cidrIp);
		if (duplicates && duplicates.length > 1) {
			block.cidrIpMessage = "Duplicates are not accepted";
			hasError = true;
		}
		const validateDescription = validate(block.description, validationType.cidrDescription, false);
		if (block.description && validateDescription?.hasError) {
			block.descriptionMessage = "Valid descriptions are strings less than 256 characters from the following set: a-zA-Z0-9. _-:/()#,@[]+=&;{}!$*";
			hasError = true;
		}
	});
    return {hasError, validateCidrBlocks};
};
