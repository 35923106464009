import { ReactComponent as ArrowDown } from "../icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../icons/arrow-up.svg";

interface Props {
  children: React.ReactNode;
  title: string;
  additionalLabel?: string | null;
  collapsed: boolean;
  isScrollable?: boolean;
  handleCollapsed: VoidFunction;
}

const Collapsible: React.FC<Props> = ({
  children,
  title,
  additionalLabel = null,
  collapsed = false,
  isScrollable = false,
  handleCollapsed,
}: Props) => {
  return (
    <div className={`collapsible ${isScrollable ? "cover" : ""}`}>
      <button className="collapsible__button" onClick={handleCollapsed}>
        <div className="collapsible__button__label">
          {title}
          {additionalLabel && (
            <span className="collapsible__button__label__caption">
              {additionalLabel}
            </span>
          )}
        </div>
        <div>{collapsed ? <ArrowDown /> : <ArrowUp />}</div>
      </button>
      <div
        className={`collapsible__content ${
          !collapsed ? "collapsible__content--active" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
