import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Navigation from "../components/Navbar/Navigation";
import RequireAuth from "../routes/RequireAuth";
import LoadingSpinner from "../components/LoadingSpinner";
import { useAuth } from "../contexts/auth";
interface Props {
  children: React.ReactNode;
  isPrivate: boolean;
  maxWidth?: boolean;
  bgImage?: boolean;
  navigation?: boolean;
  loading?: boolean;
  allowViewOnly?: boolean;
  forbidden?: boolean;
  allowInfrastructure?: boolean;
}
const MainLayout: React.FC<Props> = ({
  children,
  isPrivate,
  loading,
  maxWidth = false,
  bgImage = false,
  navigation = true,
  allowViewOnly = false,
  forbidden = false,
  allowInfrastructure = false
}) => {

  const { user } = useAuth();

  if ((!allowViewOnly && user?.isViewOnly) || 
  (!allowInfrastructure && user?.isInfrastructure)) {
    return <>Forbidden</>;
  }

  const layout = () => forbidden ? (<>Forbidden</>) : (
    <>
      <Navbar />
      <div
        className={`main-layout__container ${
          bgImage ? "main-layout__container--image" : ""
        }`}
      >
        {navigation && <Navigation />}
        {loading && <LoadingSpinner fullHeight={true} />}
        <div
          className={`main-layout__container__content ${
            maxWidth ? "main-layout__container__content--max-width" : ""
          } ${!navigation ? "main-layout__container__content--no-navbar" : ""}`}
        >
          {children}
        </div>
        <Footer />
      </div>
    </>
  );

  return isPrivate ? <RequireAuth>{layout()}</RequireAuth> : layout();
};

export default MainLayout;
