import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button, { ButtonType } from "../../../components/Button";
import CoverNotesTable from "../../../components/CoverNotesTable";
import DateRangePicker from "../../../components/DateRangePicker";
import Dropdown from "../../../components/Dropdown";
import Search from "../../../components/Search";
import { selectOptions } from "../../../forms/brokerIssueCoverNote.form";
import useGetCoverNotes from "../../../hooks/useGetCoverNotes";
import AdminLayout from "../../../layouts/admin-layout";
import { useAuth } from "../../../contexts/auth";
import { format } from "date-fns";

const CoverNotesList: React.FC = () => {
  const [fetch, setFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const { user } = useAuth();
  const [product, setProduct] = useState("");
  const [coverNotes, setCoverNotes] = useState([]);
  const [numberOfCoverNotes, setNumberOfCoverNotes] = useState(0);
  const location = useLocation();
  const [dateInterval, setDateInterval] = useState(null);

  const {
    coverNotes: coverNotesData,
    isLoading,
    exportCoverNotes,
  } = useGetCoverNotes(
    fetch,
    itemsPerPage,
    currentPage,
    search,
    product,
    dateInterval ? dateInterval[0] : null,
    dateInterval ? dateInterval[1] : null,
    !!location?.pathname?.includes("admin")
  );

  const handlePageChange = (pageNumber: number) => {
    setFetch(true);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
    setFetch(true);
  }, [search, product, dateInterval]);

  useEffect(() => {
    if (coverNotesData?.coverNotes) {
      setCoverNotes(coverNotesData?.coverNotes);
      setNumberOfCoverNotes(coverNotesData.metadata.totalCount);
      setFetch(false);
    }
  }, [coverNotesData]);

  const handleExport = useCallback(() => {
    exportCoverNotes()
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = href;
        link.download = `CN-${format(new Date(), "ddMMyyyy")
        .replace(" ", "")
        .replaceAll("/", "")
        .replaceAll(":", "")}.csv`;
        link.click();
        URL.revokeObjectURL(href);
      })
      .catch((err) => console.error(err));
  }, [exportCoverNotes]);

  return (
    <AdminLayout loading={isLoading} allowViewOnly>
      <div className="cover-note cover-note--list">
        <div className="cover-note__heading cover-note__heading--list">
          <div className="cover-note__heading__text cover-note__heading__text--gap-l">
            <span className="typography typography--h2">
              Cover Note Library
            </span>
          </div>
          <div className="cover-note__header-actions">
            <Button
              size="medium"
              type={ButtonType.Secondary}
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
        </div>
        <div>
          <div className="cover-note__table-actions">
            <div className="cover-note__table-actions__wrapper">
              <Search setReference={setSearch} />
            </div>
            <div className="cover-note__table-actions__double-wrapper">
              <div className="cover-note__table-actions__wrapper">
                <Dropdown
                  onChange={setProduct}
                  isClearable={true}
                  placeholder="Filter by product"
                  options={selectOptions.policyDetails.product}
                  value={product}
                />
              </div>
              <div className="cover-note__table-actions__wrapper cover-note__table-actions__wrapper--small">
                <DateRangePicker
                  value={dateInterval}
                  onChange={setDateInterval}
                />
              </div>
            </div>
          </div>
          <CoverNotesTable
            coverNotes={coverNotes}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            numberOfCoverNotes={numberOfCoverNotes}
            setFetch={setFetch}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default CoverNotesList;
