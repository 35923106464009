import React from "react";
import { ReactComponent as UserIcon } from "../../icons/user.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import { ReactComponent as BookOpenIcon } from "../../icons/book_open.svg";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../../contexts/auth";

const Navigation: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const adminSelected = location.pathname.startsWith("/admin");
  const profileSelected = location.pathname.startsWith("/brokerDetails");


  return (
    <div className="main-layout__navigation">
      <div className="main-layout__navigation__container">
        { ( !user?.isViewOnly && !user?.isInfrastructure) && (
          <div className="main-layout__navigation__wrapper">
            <button
              onClick={() => navigate("/")}
              className={`main-layout__navigation__wrapper__button ${
                !adminSelected && !profileSelected
                  ? "main-layout__navigation__wrapper__button--selected"
                  : ""
              }`}
            >
              <BookOpenIcon />
              Broker
            </button>
          </div>
        )}
        { ( user?.isInfrastructure) && (
          <div className="main-layout__navigation__wrapper">
            <button
              onClick={() => navigate("/infra/ipwhitelist")}
              className={`main-layout__navigation__wrapper__button ${
                !profileSelected
                  ? "main-layout__navigation__wrapper__button--selected"
                  : ""
              }`}
            >
              <SettingsIcon />
              IP Whitelist
            </button>
          </div>
        )}
        { ( user?.isAdmin || user?.isViewOnly) && (
          <div className="main-layout__navigation__wrapper">
            <button
              className={`main-layout__navigation__wrapper__button ${
                adminSelected
                  ? "main-layout__navigation__wrapper__button--selected"
                  : ""
              }`}
              onClick={() => navigate(!user?.isViewOnly ? "/admin/brokers" : "/admin/stats")}
            >
              <SettingsIcon />
              Admin
            </button>
          </div>
        )}

        <div className="main-layout__navigation__wrapper">
          <button 
            className={`main-layout__navigation__wrapper__button ${
              profileSelected
                ? "main-layout__navigation__wrapper__button--selected"
                : ""
            }`}
            onClick={() => navigate("/brokerDetails")}
          >
            <UserIcon />
            Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
