import { DateRangePicker as RSDateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

interface Props {
  onChange?: (d: any) => void;
  value: [Date, Date] | null;
}

const DateRangePicker: React.FC<Props> = ({ value, onChange }: Props) => {
  return (
    <div className="date-picker">
      <RSDateRangePicker
        block
        format="dd/MM/yyyy"
        value={value}
        onChange={onChange}
        isoWeek
        ranges={[]}
        placement="bottomEnd"
        editable={false}
      />
    </div>
  );
};

export default DateRangePicker;
