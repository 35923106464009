import React from "react";
import arrowLeftActive from "../icons/arrow-left-active.svg";
import arrowLeft from "../icons/arrow-left.svg";

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentItemsPerPage: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination = ({
  itemsPerPage,
  currentItemsPerPage,
  totalItems,
  currentPage,
  onPageChange,
}: PaginationProps) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const leadingPages = 3; // first 3 pages
  const trailingPages = 3; // last 3 pages

  const generatePageNumbers = () => {
    const numbers = [];

    // Add the first few page numbers
    if (totalPages > 6) {
      for (let i = 1; i <= leadingPages && i <= totalPages; i++) {
        numbers.push(i);
      }

      // Decide when to add the first ellipsis
      if (currentPage > leadingPages) {
        numbers.push("...");
      }

      // Add current page and surrounding numbers
      for (
        let i = Math.max(leadingPages + 1, currentPage);
        i <= Math.min(totalPages - trailingPages, currentPage);
        i++
      ) {
        numbers.push(i);
      }

      // Decide when to add the second ellipsis
      if (currentPage < totalPages - trailingPages + 1) {
        numbers.push("...");
      }

      // Add the last few page numbers
      for (
        let i = Math.max(totalPages - trailingPages + 1, leadingPages + 2);
        i <= totalPages;
        i++
      ) {
        numbers.push(i);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className="pagination__container">
      <span className="pagination__label">
        Showing {currentItemsPerPage} of {totalItems}
      </span>
      <ul className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <img
            src={currentPage === 1 ? arrowLeft : arrowLeftActive}
            alt="arrow-right"
          />
        </button>
        {pageNumbers.map((number, index) => (
          <li key={number} className="pagination__item">
            {number !== "..." ? (
              <button
                onClick={() => onPageChange(number as number)}
                className={`pagination__button ${
                  currentPage === number ? "pagination__button--active" : ""
                }`}
              >
                {number}
              </button>
            ) : (
              <span>...</span>
            )}
          </li>
        ))}
        <button
          disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <img
            src={
              currentPage === pageNumbers[pageNumbers.length - 1]
                ? arrowLeft
                : arrowLeftActive
            }
            alt="arrow-right"
            className="rotate-180"
          />
        </button>
      </ul>
    </div>
  );
};

export default Pagination;
