import { useEffect, useState } from "react";
import { selectOptions } from "../../forms/brokerIssueCoverNote.form";
import Dropdown from "../Dropdown";
import Input from "../Input";
import { getFormErrors } from "../../forms/brokerIssueCoverNote.form";
import DatePicker from "../DatePicker/DatePicker";
import { getMinimumDateOfBirthForADriver } from "../../utils/helpers";

interface Props {
	formData: any;
	formErrors: any;
	setFormErrors: any;
	setFormData: any;
	handleOnChange: any;
	isExtension?: boolean;
	isReused?: boolean;
}

const PolicyHolderDetails: React.FC<Props> = ({ formData, formErrors, setFormErrors, setFormData, isExtension, handleOnChange, isReused }: Props) => {
	const [policyHolderDateOfBirth, setPolicyHolderDateOfBirth] = useState<Date | null>(null);
	const [isReusedCoverNote, setIsReusedCoverNote] = useState(isReused);

	useEffect(() => {
		if (formData.dateOfBirth) setPolicyHolderDateOfBirth(new Date(formData.dateOfBirth));
	}, [formData?.dateOfBirth]);

	useEffect(() => {
		if (formData?.entityType && !isReusedCoverNote) {
			setFormErrors({
				...getFormErrors(formData?.entityType, formErrors, formData),
			});
      
		}
		setIsReusedCoverNote(false);
	}, [formData?.entityType]);

	useEffect(() => {
		let auxPolicyHolderDOB :string|null = null;
		if (policyHolderDateOfBirth !== null && policyHolderDateOfBirth instanceof Date === true) {
			policyHolderDateOfBirth.setHours(12, 0, 0, 0);
			auxPolicyHolderDOB = policyHolderDateOfBirth.toISOString();
		}
		setFormData((formData: any) => {
			return {
				...formData,
				dateOfBirth: auxPolicyHolderDOB,
			};
		});
	}, [policyHolderDateOfBirth]);

	return (
		<>
			<div className="cover-note__input-wrapper">
				<Dropdown
					value={formData.entityType}
					label="Entity type"
					isClearable={false}
					required={true}
					disabled={!!isExtension}
					smallGap
					onChange={(option) => {
						setFormData({
							...formData,
							entityType: option,
						});
					}}
					options={selectOptions.policyHolderDetails.entityType}
					placeholder="Please select"
					hasMessage={formErrors.entityType.error && formErrors.entityType.message}
				/>
			</div>
			{formData.entityType === selectOptions.policyHolderDetails.entityType[0] && (
				<>
					<div className="cover-note__input-wrapper">
						<Dropdown
							required={!!formErrors?.title?.required}
							value={formData?.title}
							isClearable={false}
							label="Title"
							disabled={!!isExtension}
							smallGap
							onChange={(option) => {
								setFormData({
									...formData,
									title: option,
								});
							}}
							options={selectOptions?.title}
							placeholder="Please select"
							hasMessage={formErrors.title.error && formErrors.title.message}
						/>
					</div>

					<div className="cover-note__input-wrapper">
						<Input
							value={formData.firstName}
							required={formErrors.firstName.required}
							label="First name"
							smallGap
							disabled={!!isExtension}
							onChange={handleOnChange}
							placeholder="Enter first name"
							name="firstName"
							maxLength={32}
							hasMessage={formErrors.firstName.error && formErrors.firstName.message}
						/>
					</div>
					<div className="cover-note__input-wrapper">
						<Input
							value={formData.surname}
							required={formErrors.surname.required}
							label="Surname"
							smallGap
							disabled={!!isExtension}
							onChange={handleOnChange}
							placeholder="Enter surname"
							name="surname"
							maxLength={32}
							hasMessage={formErrors.surname.error && formErrors.surname.message}
						/>
					</div>

					<div className="cover-note__input-wrapper">
						<DatePicker
							disabled={!!isExtension}
							label="Date of birth"
							required={!!formErrors.dateOfBirth.required}
							value={policyHolderDateOfBirth}
							onChange={setPolicyHolderDateOfBirth}
							disableDateAfter={getMinimumDateOfBirthForADriver()}
							valueOnOpen={getMinimumDateOfBirthForADriver()}
							hasMessage={formErrors.dateOfBirth.error && formErrors.dateOfBirth.message}
						/>
					</div>
				</>
			)}
			{formData.entityType === selectOptions.policyHolderDetails.entityType[1] && (
				<div className="cover-note__input-wrapper cover-note__input-wrapper--full-line">
					<Input
						value={formData.companyName}
						required={formErrors.companyName.required}
						label="Company name"
						disabled={!!isExtension}
						smallGap
						onChange={handleOnChange}
						placeholder="Enter company name"
						name="companyName"
						maxLength={63}
						hasMessage={formErrors.companyName.error && formErrors.companyName.message}
					/>
					<span className="typography typography--caption mt-1">If the Policyholder is "trading As" please enter the full title under Company Name</span>
				</div>
			)}

			<div className="flex flex-row w-full justify-between">
				<div className="cover-note__input-wrapper ">
					<Input
						value={formData.addressLine1}
						required={formErrors.addressLine1.required}
						label="Address Line 1"
						disabled={!!isExtension}
						smallGap
						name="addressLine1"
						onChange={handleOnChange}
						placeholder="Enter address line 1"
						maxLength={37}
						hasMessage={formErrors.addressLine1.error && formErrors.addressLine1.message}
					/>
				</div>
				<div className="cover-note__input-wrapper">
					<Input
						value={formData.addressLine2}
						required={formErrors.addressLine2.required}
						smallGap
						disabled={!!isExtension}
						label="Address Line 2"
						name="addressLine2"
						onChange={handleOnChange}
						placeholder="Enter address line 2"
						maxLength={37}
						hasMessage={formErrors.addressLine2.error && formErrors.addressLine2.message}
					/>
				</div>
			</div>
			<div className="cover-note__input-wrapper">
				<Input
					value={formData.city}
					required={formErrors.city.required}
					label="Town/City"
					disabled={!!isExtension}
					smallGap
					name="city"
					onChange={handleOnChange}
					placeholder="Enter town/city name"
					maxLength={37}
					hasMessage={formErrors.city.error && formErrors.city.message}
				/>
			</div>
			<div className="cover-note__input-wrapper">
				<Input
					value={formData.country}
					required={formErrors.country.required}
					label="County"
					disabled={!!isExtension}
					smallGap
					name="country"
					onChange={handleOnChange}
					placeholder="Enter county"
					maxLength={37}
					hasMessage={formErrors.country.error && formErrors.country.message}
				/>
			</div>
			<div className="cover-note__input-wrapper">
				<Input
					value={formData.postCode}
					required={formErrors.postCode.required}
					label="Post Code"
					smallGap
					disabled={!!isExtension}
					name="postCode"
					onChange={handleOnChange}
					placeholder="Enter Post Code"
					maxLength={10}
					hasMessage={formErrors.postCode.error && formErrors.postCode.message}
				/>
			</div>
			<div className="cover-note__input-wrapper">
				<Input
					value={formData.occupation}
					required={formErrors.occupation.required}
					label="Trade/Occupation"
					smallGap
					disabled={!!isExtension}
					name="occupation"
					onChange={handleOnChange}
					placeholder="Enter trade/Occupation"
					maxLength={75}
					hasMessage={formErrors.occupation.error && formErrors.occupation.message}
				/>
			</div>
		</>
	);
};

export default PolicyHolderDetails;
