import IPCIDR from "ip-cidr";

export enum validationType {
	text,
	name,
	email,
	number,
	phoneNumber,
	agencyNumber,
	postCode,
	textOrNumber,
	dateDDMMYYYY,
	year,
	dateISO,
	yearOfManufacture,
	registrationNumber,
	textNumbersSpecialChars,
	modelName,
	cidr,
	cidrDescription
}

export interface ValidationResponse {
	hasError: boolean;
	message?: string;
}

export const validate = (field: string | number, type: validationType = validationType.text, required: boolean): ValidationResponse => {
	if ((field === null || field === undefined || (typeof field === "string" && field?.length === 0)) && required) {
		return { hasError: true, message: "This field is required" };
	}

	if (!field) {
		return { hasError: true };
	}
	if (Array.isArray(field) && field.length === 0) {
		return { hasError: false };
	}

	switch (type) {
		case validationType.email:
			return { hasError: !regexValidate(field, emailRegex) };
		case validationType.name:
			return {
				hasError: !regexValidate(field, /^[a-zA-ZÀ-ÖØ-öø-ÿ-' ]{2,32}?$/),
			};
		case validationType.number:
			return { hasError: !regexValidate(field, /^\d+$/) };
		case validationType.phoneNumber:
			return {
				hasError: !regexValidate(field, /^((\+44)?|0?|(0044)?)\d{10}$/),
			};
		case validationType.postCode:
			return {
				hasError: !regexValidate(field, /^[A-Za-z0-9\s\-]{3,10}$/),
			};
		case validationType.text:
			return { hasError: !regexValidate(field, /[a-zA-Z]+/) };
		case validationType.textOrNumber:
			return { hasError: !regexValidate(field, /^[a-zA-Z0-9\s]+$/) };
		case validationType.agencyNumber:
			return {
				hasError: !regexValidate(field, /^(?:[0-9]\d{0,9}|0)$/),
			};
		case validationType.dateDDMMYYYY:
			return {
				hasError: !regexValidate(field, /^([0-3][0-9])\/(0[1-9]|1[0-2])\/(\d{4})$/),
			};
		case validationType.year:
			return { hasError: !regexValidate(field, /^\d{4}$/) };
		case validationType.yearOfManufacture:
			return {
				hasError: !regexValidate(field, /^(18\d\d|19\d\d|20\d\d|21\d\d)$/) || Number(field) > new Date().getFullYear(),
			};
		case validationType.dateISO:
			return { hasError: new Date(field).toISOString() !== field };
		case validationType.registrationNumber:
			return {
				hasError: !regexValidate(field, /^[a-zA-Z\d\s]{0,17}$/i),
			};
		case validationType.textNumbersSpecialChars:
			return {
				hasError: !regexValidate(field, /^.*$/i),
			};
		case validationType.modelName:
			return { hasError: !regexValidate(field, /^[a-zA-Z0-9\s\-\']+$/) };
		case validationType.cidr:
			return { hasError: !IPCIDR.isValidCIDR(String(field)) };
		case validationType.cidrDescription:
			return { hasError: !regexValidate(field, /^[a-zA-Z0-9\.\_\-\:\/\(\)\#\,\@\[\]\+\=\&\;\{\}\!\$\*]{1,255}$/) };	
		default:
			return { hasError: false };
	}
};
const regexValidate = (field: string | number, regex: RegExp): boolean => !!String(field).toLowerCase().match(regex);

const emailRegex = /^[\w\-\.\+\']+@([\w-]+\.)+[\w-]{2,20}$/;


export const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{14,}$/;


