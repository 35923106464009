import { useCallback, useEffect, useState } from "react";
import { selectOptions } from "../../forms/brokerIssueCoverNote.form";
import {
  fromNumberToOrdinalWord,
  getMinimumDateOfBirthForADriver,
} from "../../utils/helpers";
import Dropdown from "../Dropdown";
import Input from "../Input";
import DatePicker from "../DatePicker/DatePicker";

interface Props {
  index: number;
  driverForm: any;
  driverFormErrors: any;
  setFormData: any;
  isExtension?: boolean;
}

const DriverForm: React.FC<Props> = ({
  index,
  driverForm,
  driverFormErrors,
  setFormData,
  isExtension,
}: Props) => {
  const [driversDateOfBirth, setDriversDateOfBirth] = useState<Date | null>(
    null
  );

  useEffect(() => {
    if (driverForm?.dateOfBirth)
      setDriversDateOfBirth(new Date(driverForm.dateOfBirth));
  }, [driverForm?.dateOfBirth]);

  const handleDriverOnChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      let val = value === "" ? null : value;
      let property = name.split("_")[0];
      let driverNumber = name.split("_")[1];

      setFormData((formData: any) => {
        let additionalDrivers: any = [...formData.additionalDrivers];
        additionalDrivers[driverNumber][property] = val;

        return {
          ...formData,
          additionalDrivers: additionalDrivers,
        };
      });
    },
    [setFormData]
  );

  useEffect(() => {
    
    let auxDriversDOB :string|null = null;
		if (driversDateOfBirth !== null && driversDateOfBirth instanceof Date === true) {
			driversDateOfBirth.setHours(12, 0, 0, 0);
			auxDriversDOB = driversDateOfBirth.toISOString();
		}

    setFormData((formData: any) => {
      let additionalDrivers: any = [...formData.additionalDrivers];
      additionalDrivers[index].dateOfBirth = auxDriversDOB;

      return {
        ...formData,
        additionalDrivers: additionalDrivers,
      };
    });
  }, [driversDateOfBirth]);

  return (
    <>
      <div className="cover-note__input-wrapper">
        <span className="typography text-2base typography--bold">
          {fromNumberToOrdinalWord(index)} Driver:
        </span>
        <Dropdown
          value={driverForm?.title}
          required={driverFormErrors?.title.required}
          isClearable={false}
          options={selectOptions?.title}
          disabled={isExtension}
          onChange={(option: any) => {
            setFormData((formData: any) => {
              let additionalDrivers: any = [...formData.additionalDrivers];
              additionalDrivers[index].title = option;

              return {
                ...formData,
                additionalDrivers: additionalDrivers,
              };
            });
          }}
          label="Title"
          smallGap
          placeholder="Please select"
          hasMessage={
            driverFormErrors?.title.error && driverFormErrors?.title.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={driverForm.firstName}
          label="First name"
          required={driverFormErrors?.firstName.required}
          smallGap
          disabled={isExtension}
          onChange={handleDriverOnChange}
          name={`firstName_${index}`}
          placeholder="Enter first name"
          maxLength={32}
          hasMessage={
            driverFormErrors?.firstName.error &&
            driverFormErrors?.firstName.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={driverForm.surname}
          required={driverFormErrors?.surname.required}
          label="Surname"
          smallGap
          disabled={isExtension}
          onChange={handleDriverOnChange}
          name={`surname_${index}`}
          placeholder="Enter surname"
          maxLength={32}
          hasMessage={
            driverFormErrors?.surname.error && driverFormErrors?.surname.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <DatePicker
          value={driversDateOfBirth}
          required={driverFormErrors?.dateOfBirth.required}
          disabled={isExtension}
          label="Date of birth"
          onChange={setDriversDateOfBirth}
          hasMessage={
            driverFormErrors?.dateOfBirth.error &&
            driverFormErrors?.dateOfBirth.message
          }
          disableDateAfter={getMinimumDateOfBirthForADriver()}
          valueOnOpen={getMinimumDateOfBirthForADriver()}
        />
      </div>
    </>
  );
};

export default DriverForm;
