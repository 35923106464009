import { useCallback, useEffect, useState } from "react";
import MainLayout from "../../layouts/main-layout";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import Button, { ButtonType } from "../../components/Button";
import Search from "../../components/Search";
import DateRangePicker from "../../components/DateRangePicker";
import useGetGreenCards from "../../hooks/useGetGreenCards";
import GreenCardsTable from "../../components/GreenCardTable";
import { format } from "date-fns";

const GreenCardLibrary: React.FC = () => {
  const [fetch, setFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const { user } = useAuth();
  const [greenCards, setGreenCards] = useState([]);
  const navigate = useNavigate();
  const [numberOfGreenCards, setNumberOfGreenCards] = useState(0);
  const [dateInterval, setDateInterval] = useState(null);

  const {
    greenCards: greenCardsData,
    isLoading,
    exportGreenCards,
  } = useGetGreenCards(
    fetch,
    itemsPerPage,
    currentPage,
    search,
    dateInterval ? dateInterval[0] : null,
    dateInterval ? dateInterval[1] : null
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setFetch(true);
  };

  useEffect(() => {
    setCurrentPage(1);
    setFetch(true);
  }, [search, dateInterval]);

  useEffect(() => {
    if (greenCardsData?.greenCards) {
      setGreenCards(greenCardsData?.greenCards);
      setNumberOfGreenCards(greenCardsData.metadata.totalCount);
      setFetch(false);
    }
  }, [greenCardsData]);

  const handleExport = useCallback(() => {
    exportGreenCards()
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = href;
        link.download = `GC-${format(new Date(), "ddMMyyyy")
          .replace(" ", "")
          .replaceAll("/", "")
          .replaceAll(":", "")}.csv`;
        link.click();
        URL.revokeObjectURL(href);
      })
      .catch((err) => console.error(err));
  }, [exportGreenCards]);

  return (
    <MainLayout isPrivate loading={isLoading} forbidden={!user?.greenCard}>
      <div className="green-card green-card--list">
        <Link className="typography typography--link typography--bold" to={"/"}>
          Go back
        </Link>
        <div className="green-card__heading green-card__heading--list green-card__heading--row">
          <div className="green-card__heading__text green-card__heading__text--gap-l">
            <span className="typography typography--h2">
              Green Card Library
            </span>
            <span className="typography typography--h3">
              Showing for{" "}
              <span className="typography typography--h3 typography--primary">
                {user?.firstName}
              </span>
            </span>
          </div>
          <div className="cover-note__header-actions">
            {!user?.isViewOnly && (
              <Button onClick={() => navigate("/greenCards/issue")}>
                Create green card
              </Button>
            )}
            <Button
              size="medium"
              type={ButtonType.Secondary}
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
        </div>
        <div>
          <div className="green-card__table-actions">
            <div className="green-card__table-actions__wrapper">
              <Search setReference={setSearch} />
            </div>
            <div className="green-card__table-actions__double-wrapper">
              <div className="green-card__table-actions__wrapper green-card__table-actions__wrapper--small">
                <DateRangePicker
                  value={dateInterval}
                  onChange={setDateInterval}
                />
              </div>
            </div>
          </div>
          <GreenCardsTable
            data={greenCards}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            total={numberOfGreenCards}
            setFetch={setFetch}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default GreenCardLibrary;
