import { AuthProvider } from "./contexts/auth";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import RegistrationRequestSent from "./pages/RegistrationRequestSent";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Brokers from "./pages/Admin/Brokers";
import BrokersRequest from "./pages/Admin/Brokers/request";
import LiveSoon from "./pages/LiveSoon";
import IssueCoverNote from "./pages/CoverNote/Issue";
import CreatedCoverNote from "./pages/CoverNote/Created";
import MFASetup from "./pages/MFASetup/index";
import IssueGreenCard from "./pages/GreenCard/Issue";
import CoverNoteLibrary from "./pages/CoverNote/Library";
import ConfirmProfileDetails from "./pages/ConfirmProfileDetails";
import CoverNotesList from "./pages/Admin/CoverNotes";
import CreatedGreenCard from "./pages/GreenCard/Created";
import GreenCardLibrary from "./pages/GreenCard/Library";
import GreenCardsList from "./pages/Admin/GreenCards";
import Profile from "./pages/Profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stats from "./pages/Admin/Stats";
import MfaSetup from "./pages/Profile/mfaSetup";
import IpWhitelist from "./pages/Infra/IpWhitelist";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <ToastContainer />
        <Routes>
          <Route>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/profile" element={<ConfirmProfileDetails />} />
            <Route path="/brokerDetails" element={<Profile />} />
            <Route path="/brokerDetails/mfaSetup" element={<MfaSetup />} />

            <Route
              path="/registrationRequestSent"
              element={<RegistrationRequestSent />}
            />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/admin/brokers" element={<Brokers />} />
            <Route
              path="/admin/brokers/:id/requestDetails"
              element={<BrokersRequest />}
            />
            <Route path="/admin/brokers/new" element={<BrokersRequest />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/mfaSetup" element={<MFASetup />} />
            <Route path="/liveSoon" element={<LiveSoon />} />
            <Route path="/coverNotes/library" element={<CoverNoteLibrary />} />

            <Route path="/admin/coverNotes" element={<CoverNotesList />} />
            <Route path="/admin/greenCards" element={<GreenCardsList />} />
            <Route path="/admin/stats" element={<Stats />} />

            <Route path="/coverNotes/issue" element={<IssueCoverNote />} />
            <Route
              path="/coverNotes/issue/:issueId/extension"
              element={<IssueCoverNote />}
            />

            <Route
              path="/coverNotes/:id/created"
              element={<CreatedCoverNote />}
            />
            <Route path="/greenCards/issue" element={<IssueGreenCard />} />
            <Route path="/greenCards/library" element={<GreenCardLibrary />} />
            <Route
              path="/greenCards/:id/created"
              element={<CreatedGreenCard />}
            />
            <Route path="/infra/ipwhitelist" element={<IpWhitelist />} />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
