import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button, { ButtonType } from "../../components/Button";

import SplitScreen from "../../components/SplitScreen";
import playStore from "../../icons/play-store.png";
import appleStore from "../../icons/app-store.png";
import authy from "../../icons/authy.png";
import check from "../../icons/check.svg";

import Input from "../../components/Input";
import { QRCodeSVG } from "qrcode.react";
import { postData } from "../../utils/fetchApi";
const MFASetup: React.FC = () => {
  const navigate = useNavigate();
  const { state: authenticatorDetails } = useLocation();
  const [hasMFAApp, setHasMFAApp] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [linkClicked, setLinkClicked] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);
  const [error, setError] = useState(false);

  const handleOnChange = useCallback((e: any) => {
    const { value } = e.target;
    let val = value === "" ? null : value;
    setVerificationCode(val);
  }, []);

  const handleVerify = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (authenticatorDetails && authenticatorDetails?.brokerId) {
        const codeVerifiedResponse = await postData(
          `users/enableAuthenticator/${authenticatorDetails.brokerId}`,
          {
            code: verificationCode,
          }
        ).catch((error) => {
          setError(false);
        });

        if (!codeVerifiedResponse.success) {
          setError(true);
          return;
        }
        setError(false);
        setCodeVerified(true);
        setError(false);
        return;
      } else {
        setError(true);
      }
    },
    [authenticatorDetails, verificationCode]
  );

  return (
    <SplitScreen>
      {codeVerified ? (
        <div className="mfa-setup">
          <div className="typography typography--h1">Your account is set!</div>
          <div className="mfa-setup__check-wrapper">
            <img src={check} alt="check-icon" width={68} />
            <div className="typography">
              Your account was set and secured with a multi-factor
              authenticator. Please return to login.
            </div>
          </div>
          <Button onClick={() => navigate("/login")}>Return to login</Button>
        </div>
      ) : (
        <div className="mfa-setup">
          <div className="typography typography--h1">
            Multi-factor authenticator
          </div>
          <div className="typography typography--bold">
            Configure authenticator app
          </div>
          {error && hasMFAApp && (
            <div className="typography typography--error">
              Verification code is invalid. Please enter a correct verification
              code.
            </div>
          )}
          <div className="mfa-setup__wrapper">
            {!hasMFAApp ? (
              <>
                <div className="typography">
                  On the next page you’ll be guided through linking an
                  authenticator app to your portal account. Use of an
                  authenticator app is a required step and can be installed on a
                  desktop computer, tablet, or smartphone.
                </div>
                <div>
                  <div className="typography typography--bold">
                    Recommended Apps
                  </div>
                  <div className="typography">
                    You can use any authenticator app that links to a unique
                    email address and provides you with a six-digit code. We
                    recommend the following:
                  </div>
                </div>
                <div className="mfa-setup__app-container-wrapper">
                  <div className="typography typography--bold">
                    Android/iOS Device: Microsoft Authenticator
                  </div>
                  <div className="mfa-setup__app-wrapper">
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                      className="cursor-pointer"
                    >
                      <img
                        src={playStore}
                        alt="play-store"
                        height={61}
                        width={206}
                      />
                    </a>
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://apps.apple.com/gb/app/microsoft-authenticator/id983156458"
                      className="cursor-pointer"
                    >
                      <img
                        src={appleStore}
                        alt="apple-store"
                        height={61}
                        width={206}
                      />
                    </a>
                  </div>
                </div>
                <div className="mfa-setup__app-container-wrapper">
                  <div className="typography typography--bold">
                    Windows/macOS Computer: Authy
                  </div>
                  <div className="mfa-setup__app-wrapper">
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://authy.com/"
                      className="cursor-pointer"
                    >
                      <img src={authy} alt="authy" height={63} width={453} />
                    </a>
                  </div>
                </div>
                <div className="typography">
                  If you already have an authenticator app, you may skip this
                  step.
                </div>
                <Button onClick={() => setHasMFAApp(true)}>Next</Button>
              </>
            ) : (
              <>
                <div className="typography">
                  Tap the plus icon in your authenticator app and scan the QR
                  code using your device's camera.
                </div>
                <div className="mfa-setup__qr-code-wrapper">
                  <QRCodeSVG
                    value={
                      authenticatorDetails?.authenticatorUri ??
                      "https://picturesofpeoplescanningqrcodes.tumblr.com/"
                    }
                    size={138}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"Q"}
                    includeMargin={false}
                  />
                  {linkClicked ? (
                    <div className="mfa-setup__code-wrapper">
                      <div className="typography">
                        Enter this key into your authenticator app
                      </div>
                      <div className="mfa-setup__code">
                        {authenticatorDetails?.sharedKey ??
                          "xkgt xers 2xsq thqh e2vb n4ih 3tqv bh2z"}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="typography typography--bold text-2base typography--link mfa-setup__link"
                      onClick={() => setLinkClicked(true)}
                    >
                      Can't see the QR code?
                    </div>
                  )}
                </div>
                <div className="mfa-setup__input-and-message">
                  <div className="mfa-setup__input-wrapper">
                    <Input
                      placeholder="Verification code"
                      value={verificationCode}
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className="typography mfa-setup__message-wrapper">
                    Once you scanned the QR code, please enter the 6 digit code
                    generated by your authenticator app.
                  </div>
                </div>
                <div className="mfa-setup__actions">
                  <Button
                    type={ButtonType.Secondary}
                    onClick={() => {
                      setHasMFAApp(false);
                      setLinkClicked(false);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type={ButtonType.Primary}
                    isDisabled={!verificationCode}
                    onClick={handleVerify}
                  >
                    Verify
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </SplitScreen>
  );
};

export default MFASetup;
