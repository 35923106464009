import Button, { ButtonType } from "../../components/Button";
import CustomModal from "../../components/CustomModal";

const VoidGreenCard = ({
  modalIsOpen,
  closeModal,
  onSubmit,
}: {
  modalIsOpen: boolean;
  closeModal: VoidFunction;
  onSubmit: VoidFunction;
}) => {
  return (
    <CustomModal open={modalIsOpen} closeModal={closeModal}>
      <div className="modal__body">
        <div className="typography typography--h2">
          Are you sure you want to void this Green Card?
        </div>

        <div className="typography">
          Once confirmed, you will no longer have access to this Green Card.
        </div>

        <div className="modal__actions">
          <Button
            type={ButtonType.Secondary}
            isDisabled={false}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            type={ButtonType.Primary}
            onClick={onSubmit}
            isDisabled={false}
          >
            Void now
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default VoidGreenCard;
