import React, { useState } from "react";
import FilesChart from "../../../components/FilesChart";
import useGetCoverNotesFiles from "../../../hooks/useGetCoverNotesFiles";
import useGetGreenCardsFiles from "../../../hooks/useGetGreenCardsFiles";
import AdminLayout from "../../../layouts/admin-layout";

const Stats: React.FC = () => {
  const [coverNotesYear, setCoverNotesYear] = useState(
    new Date().getFullYear()
  );

  const [greenCardsYear, setGreenCardsYear] = useState(
    new Date().getFullYear()
  );

  const { coverNotesFiles, isLoading } = useGetCoverNotesFiles(coverNotesYear);
  const { greenCardsFiles, isLoading: greenCardsLoading } =
    useGetGreenCardsFiles(greenCardsYear);

  const startYear = 2000;
  const endYear = new Date().getFullYear();
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  ).reverse();

  return (
    <AdminLayout loading={isLoading || greenCardsLoading} allowViewOnly>
      <div className="stats">
        <div className="stats__header">
          <span className="typography typography--h2">Stats</span>
        </div>
        <div className="stats__container">
          <FilesChart
            title="Cover Notes"
            dataSet={coverNotesFiles}
            years={years}
            currentYear={coverNotesYear}
            onChangeYear={setCoverNotesYear}
          />
          <FilesChart
            title="Green Cards"
            dataSet={greenCardsFiles}
            years={years}
            currentYear={greenCardsYear}
            onChangeYear={setGreenCardsYear}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default Stats;
