import { BaseErrorForm, BaseField } from "../utils/formHelper";

interface FormErrors extends BaseErrorForm {
  oldPassword: BaseField;
  newPassword: BaseField;
  newPasswordConfirmation: BaseField;
}

export const initialForm = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirmation: "",
};

export const initialFormErrors: FormErrors = {
  oldPassword: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid old password.",
  },
  newPassword: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid password.",
  },
  newPasswordConfirmation: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid password.",
  },
  defaultMessage: {
    error: false,
    defaultMessage: "Something went wrong",
  },
};
