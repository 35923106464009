import { BaseErrorForm, BaseField } from "../utils/formHelper";
import { validationType } from "../utils/validations";

interface FormErrors extends BaseErrorForm {
  firstName: BaseField;
  lastName: BaseField;
  email: BaseField;
  workTelephoneNumber: BaseField;
  jobTitle: BaseField;
  brokerName: BaseField;
  agencyNumber: BaseField;
  companyAddressLine1: BaseField;
  companyAddressLine2: BaseField;
  postCode: BaseField;
}

export const initialForm = {
  firstName: "",
  lastName: "",
  email: "",
  workTelephoneNumber: "",
  jobTitle: "",
  brokerName: "",
  agencyNumber: "",
  companyAddressLine1: "",
  companyAddressLine2: "",
  postCode: "",
};

export const initialFormErrors: FormErrors = {
  firstName: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid first name.",
    type: validationType.name,
  },
  lastName: {
    error: false,
    required: true,
    defaultMessage: "Enter a valid surname.",
    type: validationType.name,
  },
  email: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid email.",
    type: validationType.email,
  },
  workTelephoneNumber: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid telephone number.",
    type: validationType.phoneNumber,
  },
  jobTitle: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid job title.",
    type: validationType.text,
  },
  brokerName: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid broker name.",
    type: validationType.text,
  },
  agencyNumber: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid agency number.",
    type: validationType.agencyNumber,
  },
  companyAddressLine1: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid company address line 1",
    type: validationType.text,
  },
  companyAddressLine2: {
    required: false,
    error: false,
    defaultMessage: "Enter a valid address line or leave it empty.",
    type: validationType.text,
  },
  postCode: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid post code.",
    type: validationType.postCode,
  },
  defaultMessage: {
    error: false,
    defaultMessage: "Something went wrong",
  },
};
