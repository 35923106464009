import MainLayout from "../../layouts/main-layout";

const LiveSoon : React.FC = () => {
  return (
    <MainLayout isPrivate navigation={false}>
      <div className="live-soon">
        <div className="live-soon__wrapper">
          <span className="typography typography--h1">Your new <span className="typography--primary">Cover Note & Green Card</span> portal will be live soon.</span>
          <div className="live-soon__wrapper__message-wrapper">
            <span className="typography">We're busy putting the finishing touches and will be in touch when we're up and running in Q3 2023.</span>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default LiveSoon;