import { selectOptions } from "../../forms/brokerIssueCoverNote.form";
import Dropdown from "../Dropdown";
import Input from "../Input";

interface Props {
  formData: any;
  formErrors: any;
  setFormData: any;
  handleOnChange: any;
  isExtension?: boolean;
}

const PolicyDetails: React.FC<Props> = ({
  formData,
  formErrors,
  isExtension,
  setFormData,
  handleOnChange,
}: Props) => {
  return (
    <>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData.policyNumber}
          name="policyNumber"
          required={formErrors.policyNumber.required}
          label="Policy number (if known)"
          disabled={!!isExtension}
          smallGap
          placeholder="Enter policy number"
          onChange={handleOnChange}
          maxLength={15}
          hasMessage={
            formErrors.policyNumber.error && formErrors.policyNumber.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Dropdown
          value={formData.product}
          isClearable={false}
          required={formErrors.product.required}
          label="Product"
          disabled={!!isExtension}
          smallGap
          onChange={(option) => {
            setFormData({
              ...formData,
              product: option,
            });
          }}
          options={selectOptions.policyDetails.product}
          placeholder="Please select"
          hasMessage={formErrors.product.error && formErrors.product.message}
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Dropdown
          value={formData.reasonForIssue}
          label="Reason for issue"
          disabled={!!isExtension}
          required={formErrors.reasonForIssue.required}
          smallGap
          onChange={(option) => {
            setFormData({
              ...formData,
              reasonForIssue: option,
            });
          }}
          options={selectOptions.policyDetails.reasonForIssue}
          placeholder="Please select"
          hasMessage={
            formErrors.reasonForIssue.error && formErrors.reasonForIssue.message
          }
        />
      </div>
    </>
  );
};

export default PolicyDetails;
