import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Button, { ButtonType } from "../../components/Button";
import Collapsible from "../../components/Collapsible";
import MainLayout from "../../layouts/main-layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  initialForm,
  initialFormErrors,
} from "../../forms/brokerIssueGreenCard.form";
import { handleApiResponseForm, validateForm } from "../../utils/formHelper";
import { postData } from "../../utils/fetchApi";
import { useAuth } from "../../contexts/auth";
import PolicyDetails from "../../components/GreenCard/PolicyDetails";
import NameOfInsured from "../../components/GreenCard/NameOfInsured";
import VehicleDetails from "../../components/GreenCard/VehicleDetails";
import PeriodOfCover from "../../components/GreenCard/PeriodOfCover";

const IssueGreenCard: FC = () => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState(initialForm);
  const { user, silentRenew } = useAuth();
  const { state } = useLocation();

  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (state && Object.keys(state).length > 0) setFormData(state);
  }, [state]);

  const [collapsedItems, setCollapsedItems] = useState({
    brokerDetails: !user?.isAdmin,
    policyDetails: !!user?.isAdmin,
    nameOfInsured: true,
    driverDetails: true,
    vehicleDetails: true,
    policyUse: true,
    cover: true,
    specialConditions: true,
  });

  const isCollapsed = useMemo(() => {
    let property: keyof typeof collapsedItems;

    for (property in collapsedItems) {
      if (collapsedItems[property] === false) continue;

      return true;
    }

    return false;
  }, [collapsedItems]);

  const initCollapsedItems = (inititalValue: boolean) => ({
    brokerDetails: inititalValue,
    policyDetails: inititalValue,
    nameOfInsured: inititalValue,
    driverDetails: inititalValue,
    vehicleDetails: inititalValue,
    policyUse: inititalValue,
    cover: inititalValue,
    specialConditions: inititalValue,
  });

  const collapseAll = useCallback(() => {
    setCollapsedItems(initCollapsedItems(true));
  }, []);

  const expandAll = useCallback(() => {
    setCollapsedItems(initCollapsedItems(false));
  }, []);

  const handleOnChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      let val = value === "" ? null : value;
      setFormData({
        ...formData,
        [name]: val,
      });
    },
    [formData, setFormData]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validateForm(formErrors, setFormErrors, formData)) {
      expandAll();
      const body = document.querySelector(".green-card__heading");
      setIsLoading(false);
      if (body === null) return;

      body.scrollIntoView();
      return;
    }
    await postFormData();
  };

  const postFormData = useCallback(async () => {
    const newToken = await silentRenew();
    const request = await postData("greenCards", formData, newToken).catch(
      () => {
        setHasError(true);
        setIsLoading(false);
        return;
      }
    );

    if (!request.success && request.statusCode >= 500) {
      setHasError(true);
      setIsLoading(false);
      return;
    }

    if (!request.success) {
      handleApiResponseForm(request, initialFormErrors, setFormErrors);
      setHasError(true);
      setIsLoading(false);
      return;
    }

    const id = request.data.id;
    setIsLoading(false);

    navigate(`/greenCards/${id}/created`);
  }, [formData, navigate, silentRenew]);

  return (
    <MainLayout isPrivate loading={isLoading} forbidden={!user?.greenCard}>
      <div className="green-card">
        <Link className="typography typography--link typography--bold" to={"/"}>
          Go back
        </Link>
        <div className="green-card__heading">
          <div className="green-card__heading__text">
            <span className="typography typography--h1">Issue Green Card</span>
            <span className="typography">
              Please complete the Green Card information form and hit the
              "submit" button to issue the Green Card.
            </span>
            <span className="typography">
              Once completed, your Green Card will be instantly emailed to the
              email address you provided at the time of registration.
            </span>
          </div>
          <div className="green-card__heading__text">
            <span className="typography typography--bold">
              Green Card Guidance
            </span>
            <span className="typography mb-5">
              Following the UK's inclusion in the Green Card free zone
              (announced by the European Commission on 13th July 2021), drivers
              do not need to carry a Green Card when driving in the EU
              (including Ireland), Andorra, Bosnia and Herzegovina, Iceland,
              Liechtenstein, Norway, Serbia, and Switzerland. ERS continues to
              offer access to issue Green Cards through this portal to support
              customers who may wish for additional comfort when crossing
              European borders.
            </span>
            <span className="typography">
              Further details can be found on{" "}
              <a
                className="typography typography--link"
                target={"_blank"}
                rel={"noreferrer"}
                href={"https://www.gov.uk/vehicle-insurance/driving-abroad"}
              >
                www.gov.uk/vehicle-insurance/driving-abroad
              </a>
              .
            </span>
          </div>
          <div className="green-card__heading__actions">
            {!isCollapsed ? (
              <Button
                type={ButtonType.Secondary}
                size="medium"
                onClick={collapseAll}
              >
                Collapse All
              </Button>
            ) : (
              <Button
                type={ButtonType.Secondary}
                size="medium"
                onClick={expandAll}
              >
                Expand All
              </Button>
            )}
          </div>
        </div>
        <div className="form form--small-gap">
          {/* {user?.isAdmin && ( // TODO: Check if broker details is needed on green cards
            <Collapsible
              title="Broker details"
              collapsed={collapsedItems.brokerDetails}
              handleCollapsed={() =>
                setCollapsedItems((items) => ({
                  ...items,
                  brokerDetails: !items.brokerDetails,
                }))
              }
            >
              <div className="cover-note__collapsible-wrapper">
                <BrokerDetails
                  formData={formData}
                  isExtension={!!state?.isExtension}
                  setFormErrors={setFormErrors}
                  formErrors={formErrors}
                  setFormData={setFormData}
                  handleOnChange={handleOnChange}
                />
              </div>
            </Collapsible>
          )} */}
          <Collapsible
            title="Policy details"
            collapsed={collapsedItems.policyDetails}
            handleCollapsed={() =>
              setCollapsedItems((items) => ({
                ...items,
                policyDetails: !items.policyDetails,
              }))
            }
          >
            <div className="green-card__collapsible-wrapper">
              <PolicyDetails
                formData={formData}
                formErrors={formErrors}
                handleOnChange={handleOnChange}
              />
            </div>
          </Collapsible>
          <Collapsible
            title="Name of Insured"
            collapsed={collapsedItems.nameOfInsured}
            handleCollapsed={() =>
              setCollapsedItems((items) => ({
                ...items,
                nameOfInsured: !items.nameOfInsured,
              }))
            }
          >
            <div className="green-card__collapsible-wrapper">
              <NameOfInsured
                formData={formData}
                formErrors={formErrors}
                isReused={!!state}
                setFormErrors={setFormErrors}
                setFormData={setFormData}
                handleOnChange={handleOnChange}
              />
            </div>
          </Collapsible>
          <Collapsible
            title="Vehicle details"
            collapsed={collapsedItems.vehicleDetails}
            handleCollapsed={() =>
              setCollapsedItems((items) => ({
                ...items,
                vehicleDetails: !items.vehicleDetails,
              }))
            }
          >
            <div className="green-card__collapsible-wrapper">
              <VehicleDetails
                formData={formData}
                formErrors={formErrors}
                setFormData={setFormData}
                handleOnChange={handleOnChange}
              />
            </div>
          </Collapsible>
          <Collapsible
            title="Period of Cover"
            collapsed={collapsedItems.cover}
            handleCollapsed={() =>
              setCollapsedItems((items) => ({ ...items, cover: !items.cover }))
            }
          >
            <div className="green-card__collapsible-wrapper">
              <PeriodOfCover
                formData={formData}
                formErrors={formErrors}
                setFormData={setFormData}
              />
            </div>
          </Collapsible>

          <div className="green-card__issue-button-wrapper">
            {hasError && (
              <div className="green-card__issue-button-wrapper__error">
                <span className="typography typography--error">
                  Something went wrong.
                </span>
              </div>
            )}
            <Button
              type={ButtonType.Primary}
              size="medium"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default IssueGreenCard;
