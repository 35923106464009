import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { selectOptions } from "../../forms/brokerIssueCoverNote.form";
import Dropdown from "../Dropdown";
import Input from "../Input";
import DatePicker from "../DatePicker/DatePicker";
import HourPicker from "../DatePicker/HourPicker";

interface Props {
  formData: any;
  formErrors: any;
  setFormData: any;
  isExtension?: boolean;
  numberOfDaysToExpire: string;
  setNumberOfDaysToExpire: Dispatch<SetStateAction<string>>;
  handleOnChange: any;
}

const Cover: React.FC<Props> = ({
  formData,
  formErrors,
  setFormData,
  handleOnChange,
  numberOfDaysToExpire,
  setNumberOfDaysToExpire,
  isExtension,
}: Props) => {
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null);
  const [effectiveHours, setEffectiveHours] = useState<Date | null>(null);
  const currentDate = new Date();
  useEffect(() => {
    if (formData.effective) {
      setEffectiveDate(new Date(formData.effective));
      setEffectiveHours(new Date(formData.effective));
      setNumberOfDaysToExpire(formData.days);
    }
  }, [formData?.effective]);

  const clearFormDataDays = () => {
    setFormData((formData: any) => {
      return {
        ...formData,
        days: "",
      };
    });
  } 

  const effectiveFullDate: Date | null = useMemo(() => {
    if (effectiveDate === null) {
      return null;
    }
    if (effectiveHours === null) {
      let nowPlus = new Date();
      //set default at 15m in the future
      nowPlus.setMinutes(nowPlus.getMinutes() + 15);
      effectiveDate.setHours(nowPlus.getHours());
      effectiveDate.setMinutes(nowPlus.getMinutes());

      return new Date(effectiveDate);
    }

    effectiveDate.setHours(effectiveHours.getHours());
    effectiveDate.setMinutes(effectiveHours.getMinutes());

    return new Date(effectiveDate);
  }, [effectiveDate, effectiveHours]);

  const expiryDate = useMemo(() => {
    const numberOfDaysToExpireInt = parseInt(numberOfDaysToExpire);

    if (effectiveFullDate === null || isNaN(numberOfDaysToExpireInt))
      return null;

    let expiryDate = new Date(effectiveFullDate);
    expiryDate.setDate(expiryDate.getDate() + numberOfDaysToExpireInt);

    return expiryDate;
  }, [effectiveFullDate, numberOfDaysToExpire]);

  useEffect(() => {
    const setEffective = (effectiveFullDate: any) => {
      setFormData((formData: any) => {
        return {
          ...formData,
          effective: effectiveFullDate,
        };
      });
    };

    if (effectiveFullDate === null) {
      setEffective(null);
      return;
    }

    setEffective(effectiveFullDate.toISOString());
  }, [effectiveFullDate]);

  useEffect(() => {
    const setExpiry = (expiryDate: any) => {
      setFormData((formData: any) => {
        return {
          ...formData,
          expiry: expiryDate,
        };
      });
    };

    if (expiryDate === null) {
      setExpiry(null);
      return;
    }

    setExpiry(expiryDate.toISOString());
  }, [expiryDate]);

  return (
    <>
      <div className="cover-note__input-wrapper cover-note__input-wrapper--full-line">
        <Dropdown
          value={formData.coverBasis}
          label="Cover basis"
          smallGap
          disabled={isExtension}
          required={formErrors.coverBasis.required}
          isClearable={false}
          onChange={(option) => {
            setFormData({
              ...formData,
              coverBasis: option,
            });
          }}
          options={selectOptions.cover.coverBasis}
          placeholder="Please select"
          hasMessage={
            formErrors.coverBasis.error && formErrors.coverBasis.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <DatePicker
          disabled={isExtension}
          required={formErrors.effective.required}
          disableDateBefore={
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate()
            )
          }
          value={effectiveDate}
          label="Operative from"
          onChange={(option) => {
            clearFormDataDays();
            setEffectiveDate(option);
          }}
          hasMessage={
            formErrors.effective.error && formErrors.effective.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper__row cover-note__input-wrapper--small">
        <HourPicker
          value={effectiveHours}
          required={formErrors.effective.required}
          label="Hours"
          disabled={isExtension || effectiveDate === null}
          onChange={(option) => {
            clearFormDataDays();
            setEffectiveHours(option)
          }}
          hasMessage={
            formErrors.effective.error && formErrors.effective.message
          }
          cleanable={false}
        />
        <Dropdown
          value={numberOfDaysToExpire}
          required={formErrors.expiry.required}
          isClearable={false}
          label="Number of days"
          smallGap
          onChange={(option) => setNumberOfDaysToExpire(option)}
          options={selectOptions.cover.numberOfDay || formData.days}
          placeholder="Please select"
          hasMessage={
            isExtension
              ? "To extend your Cover Note, please select the number of days to apply."
              : formErrors.expiry.error && formErrors.expiry.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData.brokerReference}
          required={formErrors.brokerReference.required}
          label="Broker reference"
          smallGap
          disabled={isExtension}
          onChange={handleOnChange}
          name="brokerReference"
          placeholder="Enter broker reference"
          maxLength={30}
          hasMessage={
            formErrors.brokerReference.error &&
            formErrors.brokerReference.message
          }
        />
      </div>
    </>
  );
};

export default Cover;
