import React, { useCallback, useEffect, useState, useRef, FC } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import SplitScreen from "../../components/SplitScreen";
import { initialForm, initialFormErrors } from "../../forms/signup.form";
import { handleApiResponseForm, validateForm } from "../../utils/formHelper";
import { postData } from "../../utils/fetchApi";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaIgnoreDomain } from "../../utils/constants";

const Signup: FC = () => {
  const [userAlreadyExistsError, setUserAlreadyExistsError] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  let navigate = useNavigate();
  const [formData, setFormData] = useState(initialForm);
  const recaptchaRef = useRef<any>();

  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    if (!validateForm(initialFormErrors, setFormErrors, formData)) {
      setLoading(false);
      return;
    }
    if (
      process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY &&
      recaptchaRef.current && 
      (!recaptchaIgnoreDomain || !formData.email.endsWith(recaptchaIgnoreDomain as string))
    ) {
      await recaptchaRef.current.executeAsync();
    } else {
      await postFormData(null);
    }
  };

  const postFormData = useCallback(async (captchaCode:any) => {
    
    let requestUrl = "users";
    if(captchaCode)
      requestUrl += `?captchaCode=${captchaCode}`

    const request = await postData(requestUrl, formData);

    if (!request.success) {
      handleApiResponseForm(request, initialFormErrors, setFormErrors);
      setLoading(false);
      return;
    }
    setLoading(false);
    navigate("/registrationRequestSent");
  }, [formData, navigate]);

  useEffect(() => {
    if (formErrors.defaultMessage?.error === false) return;

    if (formErrors.defaultMessage?.message === "User Already Exists.") {
      setUserAlreadyExistsError(true);
    } else {
      setHasErrorMessage(true);
    }

    const body = document.querySelector(".sign-up__header");
    if (body === null) return;

    body.scrollIntoView();
    return;
  }, [formErrors]);

  const handleOnChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      let val = value === "" ? null : value;
      setFormData({
        ...formData,
        [name]: val,
      });
    },
    [formData, setFormData]
  );

  const onReCAPTCHAChange = async (captchaCode: any) => {
    recaptchaRef.current.reset();
    if (!captchaCode) {
      return;
    }
    await postFormData(captchaCode);
  };

  return (
    <SplitScreen isLoading={loading}>
      <div className="sign-up">
        <div className="sign-up__header">
          <div className="typography typography--h1">Create an account</div>
          {userAlreadyExistsError && (
            <div className="typography typography--error">
              An account with this email address already exists. Click the
              "Login here" link below to access your account or reset your
              password. If you're having difficulties logging in, please email
              the team:{" "}
              <a
                className="typography typography--error typography--link"
                href="mailto:agency@ers.com"
              >
                agency@ers.com
              </a>
              .
            </div>
          )}
          {hasErrorMessage && (
            <div className="typography typography--error">
              Something went wrong.
            </div>
          )}
        </div>
        <form className="form form--small-gap" onSubmit={handleSubmit}>
          <Input
            smallGap
            label="First name"
            name="firstName"
            value={formData.firstName}
            onChange={handleOnChange}
            maxLength={32}
            placeholder="Enter your first name"
            hasMessage={
              formErrors.firstName.error && formErrors.firstName.message
            }
          />
          <Input
            smallGap
            label="Surname"
            maxLength={32}
            name="lastName"
            value={formData.lastName}
            onChange={handleOnChange}
            placeholder="Enter your surname"
            hasMessage={
              formErrors.lastName.error && formErrors.lastName.message
            }
          />
          <Input
            smallGap
            label="Work email address"
            name="email"
            value={formData.email}
            onChange={handleOnChange}
            placeholder="name@domain.com"
            hasMessage={formErrors.email.error && formErrors.email.message}
          />
          <Input
            smallGap
            label="Telephone number"
            name="workTelephoneNumber"
            value={formData.workTelephoneNumber}
            onChange={handleOnChange}
            placeholder="+44 1234 567 890"
            hasMessage={
              formErrors.workTelephoneNumber.error &&
              formErrors.workTelephoneNumber.message
            }
          />
          <Input
            smallGap
            label="Job title"
            name="jobTitle"
            value={formData.jobTitle}
            maxLength={75}
            onChange={handleOnChange}
            placeholder="Enter your job title"
            hasMessage={
              formErrors.jobTitle.error && formErrors.jobTitle.message
            }
          />
          <Input
            smallGap
            label="Broker name"
            name="brokerName"
            maxLength={32}
            value={formData.brokerName}
            onChange={handleOnChange}
            placeholder="Enter your broker name"
            hasMessage={
              formErrors.brokerName.error && formErrors.brokerName.message
            }
          />
          <Input
            smallGap
            label="Agency number"
            name="agencyNumber"
            maxLength={10}
            value={formData.agencyNumber}
            onChange={handleOnChange}
            placeholder="Enter your agency number"
            hasMessage={
              formErrors.agencyNumber.error && formErrors.agencyNumber.message
            }
          />
          <Input
            smallGap
            label="Company Address Line 1"
            name="companyAddressLine1"
            value={formData.companyAddressLine1}
            maxLength={37}
            onChange={handleOnChange}
            placeholder="Enter your company address line 1"
            hasMessage={
              formErrors.companyAddressLine1.error &&
              formErrors.companyAddressLine1.message
            }
          />
          <Input
            smallGap
            name="companyAddressLine2"
            maxLength={37}
            value={formData.companyAddressLine2}
            onChange={handleOnChange}
            label="Company Address Line 2"
            placeholder="Enter your company address line 2"
            hasMessage={
              formErrors.companyAddressLine2.error &&
              formErrors.companyAddressLine2.message
            }
          />
          <Input
            smallGap
            label="Post code"
            name="postCode"
            value={formData.postCode}
            onChange={handleOnChange}
            maxLength={10}
            placeholder="Enter your post code"
            hasMessage={
              formErrors.postCode.error && formErrors.postCode.message
            }
          />
          {process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY && (
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
              onChange={onReCAPTCHAChange}
            />
          )}
          <Button htmlType="submit" onClick={handleSubmit}>
            Register
          </Button>

          <span className="typography">
            Already have an account?{" "}
            <a className="typography typography--link" href="/login">
              Login Here
            </a>
            .
          </span>
        </form>
      </div>
    </SplitScreen>
  );
};

export default Signup;
