import { Link } from "react-router-dom";
import Button, { ButtonType } from "../Button";
import { useAuth } from "../../contexts/auth";

const Navbar: React.FC = () => {
  const { Logout } = useAuth();

  async function handleLogout() {
    Logout(true);
  }
  return (
    <nav className="main-layout__navbar">
      <div className="main-layout__navbar__container">
        <Link to="/" className="main-layout__navbar__link">
          <img
            src="/images/images/logo-white.png"
            className="main-layout__navbar__logo"
            alt="ERS Logo"
          />
          <span className="main-layout__navbar__title">
            Cover Note & Green Card Portal
          </span>
        </Link>
        <Button
          modifierClassName="button--navbar-logout-button"
          type={ButtonType.Secondary}
          onClick={handleLogout}
        >
          Log out
        </Button>
      </div>
    </nav>
  );
};

export default Navbar;
