import zxcvbn from "zxcvbn";

interface Props {
  password: string;
}

const PasswordStrengthMeter = ({ password }: Props) => {
  const testedResult = zxcvbn(password);

  const createPasswordLabel = (testedResult: zxcvbn.ZXCVBNResult): string => {
    switch (testedResult.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Medium";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  return (
    <div className="password-strength-meter">
      <div className="password-strength-meter__wrapper">
        <span className="typography typography--bold typography--label-input">Password strength</span>
        <progress
          value={testedResult.score}
          max="4"
          className={`password-strength-meter__progress password-strength-meter__progress__value password-strength-meter__progress__value--${createPasswordLabel(
            testedResult
          ).toLowerCase()}`}
        />
      </div>
      <label className="password-strength-meter-label">
        {password && (
          <span className="typography">
            {createPasswordLabel(testedResult)}
          </span>
        )}
      </label>
    </div>
  );
};

export default PasswordStrengthMeter;
