import React, { useCallback, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import SplitScreen from "../../components/SplitScreen";
import { postData } from "../../utils/fetchApi";
import { initialForm, initialFormErrors } from "../../forms/resetPassword.form";
import { handleApiResponseForm, validateForm } from "../../utils/formHelper";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaIgnoreDomain } from "../../utils/constants";

const ForgotPassword: React.FC = () => {
  const [formData, setFormData] = useState(initialForm);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [resetSuccessfull, setResetSuccessfull] = useState<boolean>(false);
  let navigate = useNavigate();
  const { token } = useAuth();
  const recaptchaRef = useRef<any>();

  const handleForgotPassword =
    async (event: any) => {
      event.preventDefault();
      setIsButtonDisabled(true);
      setHasErrorMessage(false);
      if (!validateForm(initialFormErrors, setFormErrors, formData)) {
        setHasErrorMessage(true);
        setIsButtonDisabled(false);
        return;
      }
      if (
        process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY &&
        recaptchaRef.current && 
        (!recaptchaIgnoreDomain || !formData.emailAddress.endsWith(recaptchaIgnoreDomain as string))
      ) {
        await recaptchaRef.current.executeAsync();
      } else {
        await postFormData(null);
      }
    };

  const postFormData = useCallback(async (captchaCode:any) => {
    let requestUrl = "Users/resetPassword";
    if(captchaCode)
      requestUrl += `?captchaCode=${captchaCode}`;

      const request = await postData(requestUrl, formData, token);
      handleApiResponseForm(request, initialFormErrors, setFormErrors);
      if (request.success || request.statusCode === 400) {
        setResetSuccessfull(true);
      } else {
        setHasErrorMessage(true);
      }
      setIsButtonDisabled(false);
  }, [formData]);

  const onReCAPTCHAChange = async (captchaCode: any) => {
    recaptchaRef.current.reset();
    if (!captchaCode) {
      return;
    }
    await postFormData(captchaCode);
  };

  const handleReturnLogin = () => navigate("/login");

  return (
    <SplitScreen>
      <div className="forgotPassword-page">
        <div className="forgotPassword-page__header">
          <div className="typography typography--h1">Forgot your password?</div>
        </div>

        {resetSuccessfull ? (
          <div>
            <div className="typography forgotPassword-page__description">
              If the email address you've entered matches an active account on
              our system, you'll shortly receive instructions on how to reset
              your password. <br></br>
              <br></br> If you're having difficulties resetting your password,
              please contact the team:{" "}
              <Link
                className="typography typography--link"
                to={"mailto:agency@ers.com"}
              >
                agency@ers.com
              </Link>
              .
            </div>
            <Button onClick={handleReturnLogin}>Return to login</Button>
          </div>
        ) : (
          <div>
            <div className="typography forgotPassword-page__description">
              Please enter the email address associated with your account to
              receive instructions on how to reset your password.
            </div>
            <form className="form" onSubmit={handleForgotPassword}>
              <div>
                <Input
                  label="Email address"
                  value={formData.emailAddress}
                  onChange={(e) =>
                    setFormData({ emailAddress: e.target.value })
                  }
                  placeholder="Enter your email address"
                ></Input>
                {hasErrorMessage && (
                  <div className="typography typography--error">
                    <span>
                      {formErrors?.emailAddress?.message ??
                        formErrors?.defaultMessage?.message}
                    </span>
                  </div>
                )}
              </div>
              <Button htmlType="submit" isDisabled={isButtonDisabled}>
                Reset
              </Button>
              <Link className="typography typography--link" to="/login">
                Return to login
              </Link>
              {process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                  onChange={onReCAPTCHAChange}
                />
              )}
            </form>
          </div>
        )}
      </div>
    </SplitScreen>
  );
};

export default ForgotPassword;
