import React, { Dispatch, SetStateAction } from "react";
import { ReactComponent as CheckIcon } from "../icons/done_light.svg";

interface CheckboxProps {
  title: string;
  value: boolean;
  isRememberDevice?: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
}

const Checkbox = ({
  title,
  value,
  isRememberDevice,
  setValue,
}: CheckboxProps) => {
  const toggleCheckboxChange = () => {
    setValue(!value);
  };
  return (
    <div
      className={`checkbox ${
        isRememberDevice ? "checkbox--remember-device" : ""
      }`}
    >
      <div
        className={`checkbox__box ${value ? "checkbox__box--checked" : ""}`}
        onClick={toggleCheckboxChange}
      >
        {value && <CheckIcon width={14} height={14} />}
      </div>
      <div
        className={`checkbox__label ${
          isRememberDevice ? "checkbox__label--remember-device" : ""
        }`}
      >
        {title}
      </div>
    </div>
  );
};

export default Checkbox;
