import React, { Dispatch, SetStateAction } from "react";

interface SwitchProps {
  label: string;
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
}

const Switch = ({ label, value, setValue }: SwitchProps) => {
  return (
    <div className="switch">
      <div className="switch__label">{label}</div>
      <div className="switch__container">
        <div
          className={`switch__item ${!value ? "switch__item--selected" : ""}`}
          onClick={() => setValue(false)}
        >
          No
        </div>
        <div
          className={`switch__item ${value ? "switch__item--selected" : ""}`}
          onClick={() => setValue(true)}
        >
          Yes
        </div>
      </div>
    </div>
  );
};

export default Switch;
