import { useState } from "react";
import MainLayout from "../../../layouts/main-layout";
import { useAuth } from "../../../contexts/auth";
import CidrTable from "../../../components/CidrTable/CidrTable";
import LoadingSpinner from "../../../components/LoadingSpinner";

const IpWhitelist: React.FC = () => {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState<boolean>(true);

	if (!user?.isInfrastructure) {
		return <>Forbidden</>;
	}
	return (
		<MainLayout isPrivate loading={isLoading} allowInfrastructure>
			{isLoading && <LoadingSpinner fullHeight={false}/>}
			<div className="whitelist">
				<div className="whitelist__heading">
					<span className="typography typography--h2">IP Whitelist</span>
				</div>
				<div className="whitelist__subHeading">
					<span className="typography typography--bold">Inbound Rules</span>
					<span className="typography">Inbound rules control the incoming traffic that's allowed to reach the instance.</span>
				</div>
				<div className="whitelist__body">
					<CidrTable setIsLoading={setIsLoading} />
				</div>
			</div>
		</MainLayout>
	);
};
export default IpWhitelist;
