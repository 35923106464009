import { useCallback, useEffect, useState } from "react";
import {
  driverForm,
  getFormErrors,
  initialDriverFormErrors,
  selectOptions,
} from "../../forms/brokerIssueCoverNote.form";
import Button, { ButtonType } from "../Button";
import Dropdown from "../Dropdown";
import DriverForm from "./DriverForm";

interface Props {
  formData: any;
  formErrors: any;
  setFormData: any;
  setFormErrors: any;
  isExtension?: boolean;
  isReused?: boolean;
}

const DriverDetails: React.FC<Props> = ({
  formData,
  formErrors,
  setFormData,
  setFormErrors,
  isExtension,
}: Props) => {
  const addDriver = useCallback(() => {
    setFormData((formData: any) => {
      let addionalDrivers = formData.additionalDrivers;
      addionalDrivers.push({ ...driverForm });

      return { ...formData, additionalDrivers: addionalDrivers };
    });

    setFormErrors((formErrors: any) => {
      let addionalDrivers = formErrors.additionalDrivers;
      addionalDrivers.push({ ...initialDriverFormErrors });

      return { ...formErrors, additionalDrivers: addionalDrivers };
    });
  }, [setFormData, setFormErrors]);

  useEffect(() => {
    if (formData?.driverOptions) {
      setFormErrors({
        ...getFormErrors(
          formData?.driverOptions,
          formErrors,
          formData,
          "driverOptions",
          formData?.additionalDrivers
        ),
      });
    }
  }, [formData?.driverOptions]);

  const removeLastDriver = useCallback(() => {
    setFormData((formData: any) => {
      let addionalDrivers = formData.additionalDrivers;
      addionalDrivers.pop();

      return { ...formData, additionalDrivers: addionalDrivers };
    });

    setFormErrors((formErrors: any) => {
      let addionalDrivers = formErrors.additionalDrivers;
      addionalDrivers.pop();

      return { ...formErrors, additionalDrivers: addionalDrivers };
    });
  }, [setFormData, setFormErrors]);

  return (
    <>
      <div className="cover-note__collapsible-wrapper">
        <div className="cover-note__input-wrapper cover-note__input-wrapper--full-line">
          <Dropdown
            value={formData.driverOptions}
            required={formErrors.driverOptions.required}
            label="Driver Options"
            disabled={!!isExtension}
            smallGap
            onChange={(option) => {
              setFormData({
                ...formData,
                driverOptions: option,
              });
            }}
            options={selectOptions.driverDetails.driverOptions}
            placeholder="Please select"
            hasMessage={
              formErrors.driverOptions.error && formErrors.driverOptions.message
            }
          />
        </div>
      </div>
      {formData?.driverOptions?.includes("Named") &&  (
          <>
            <div className="cover-note__additional-instruction">
              <span className="typography">
                If named driver option selected please provide the following
                details:
              </span>
            </div>
            <div className="cover-note__collapsible-wrapper">
              {formData.additionalDrivers.map(
                (driverForm: any, key: any): any => {
                  return (
                    <DriverForm
                      index={key}
                      isExtension={isExtension}
                      driverForm={driverForm}
                      driverFormErrors={formErrors.additionalDrivers[key]}
                      setFormData={setFormData}
                    />
                  );
                }
              )}
              <div className="cover-note__buttons-wrapper">
                {formData.additionalDrivers.length < 4 && (
                  <Button
                    type={ButtonType.Primary}
                    size="medium"
                    onClick={addDriver}
                  >
                    Add driver
                  </Button>
                )}
                {formData.additionalDrivers.length > 1 && (
                  <Button
                    type={ButtonType.Secondary}
                    size="medium"
                    onClick={removeLastDriver}
                  >
                    Delete last driver
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default DriverDetails;
