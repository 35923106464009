import { useEffect, useState } from "react";
import {
  getFormErrors,
  selectOptions,
} from "../../forms/brokerIssueCoverNote.form";
import Dropdown from "../Dropdown";
import Input from "../Input";

interface Props {
  formData: any;
  formErrors: any;
  setFormData: any;
  setFormErrors: any;
  isExtension?: boolean;
  handleOnChange: any;
  isReused?: boolean;
}

const VehicleDetails: React.FC<Props> = ({
  formData,
  formErrors,
  setFormData,
  handleOnChange,
  setFormErrors,
  isExtension,
  isReused,
}: Props) => {
  const [isReusedCoverNote, setIsReusedCoverNote] = useState(isReused);

  useEffect(() => {
    if (formData?.vehicle && !isReusedCoverNote) {
      setFormErrors({
        ...getFormErrors(formData?.vehicle, formErrors, formData),
      });
    }
    setIsReusedCoverNote(false);
  }, [formData?.vehicle]);

  return (
    <>
      <div className="cover-note__input-wrapper cover-note__input-wrapper--full-line">
        <Dropdown
          value={formData.vehicle}
          label="Vehicles"
          required={!!formErrors.vehicle.required}
          smallGap
          disabled={!!isExtension}
          onChange={(option) => {
            setFormData({
              ...formData,
              vehicle: option,
            });
          }}
          options={selectOptions.vehicleDetails.vehicles}
          placeholder="Please select"
          hasMessage={formErrors.vehicle.error && formErrors.vehicle.message}
        />
        {formData?.vehicle === selectOptions.vehicleDetails.vehicles[2] && (
          <span className="typography">
            Where specified vehicle required please provide the following
            details:
          </span>
        )}
      </div>
      {formData?.vehicle === selectOptions.vehicleDetails.vehicles[2] && (
        <>
          <div className="cover-note__input-wrapper">
            <Input
              value={formData.make}
              required={formErrors.make.required}
              label="Make"
              smallGap
              disabled={isExtension}
              onChange={handleOnChange}
              name="make"
              maxLength={32}
              placeholder="Enter Make"
              hasMessage={formErrors.make.error && formErrors.make.message}
            />
          </div>
          <div className="cover-note__input-wrapper">
            <Input
              value={formData.model}
              required={formErrors.model.required}
              label="Model"
              smallGap
              disabled={isExtension}
              onChange={handleOnChange}
              name="model"
              maxLength={32}
              placeholder="Enter Model"
              hasMessage={formErrors.model.error && formErrors.model.message}
            />
          </div>
          <div className="cover-note__input-wrapper">
            <Input
              value={formData.cubicCapacityOrGVW}
              required={formErrors.cubicCapacityOrGVW.required}
              label="CC/GVW"
              smallGap
              disabled={isExtension}
              onChange={handleOnChange}
              name="cubicCapacityOrGVW"
              placeholder="Enter CC/GVW"
              maxLength={11}
              hasMessage={
                formErrors.cubicCapacityOrGVW.error &&
                formErrors.cubicCapacityOrGVW.message
              }
            />
          </div>
          <div className="cover-note__input-wrapper">
            <Input
              value={formData.yearOfManufacture}
              required={formErrors.yearOfManufacture.required}
              label="Year of make"
              disabled={isExtension}
              smallGap
              onChange={handleOnChange}
              name="yearOfManufacture"
              placeholder="Enter year of make"
              hasMessage={
                formErrors.yearOfManufacture.error &&
                formErrors.yearOfManufacture.message
              }
            />
          </div>
          <div className="cover-note__input-wrapper">
            <Input
              value={formData.registrationNumber}
              required={formErrors.registrationNumber.required}
              disabled={isExtension}
              label="Registration number"
              smallGap
              onChange={handleOnChange}
              name="registrationNumber"
              maxLength={17}
              placeholder="Enter registration number"
              hasMessage={
                formErrors.registrationNumber.error &&
                formErrors.registrationNumber.message
              }
            />
          </div>
          <div className="cover-note__input-wrapper">
            <Input
              value={formData.estimatedValue}
              required={formErrors.estimatedValue.required}
              disabled={isExtension}
              label="Value"
              smallGap
              onChange={handleOnChange}
              name="estimatedValue"
              placeholder="Enter value"
              maxLength={11}
              hasMessage={
                formErrors.estimatedValue.error &&
                formErrors.estimatedValue.message
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default VehicleDetails;
