import Button, { ButtonType } from "../../components/Button";
import CustomModal from "../../components/CustomModal";

const UnlinkMFA = ({
  modalIsOpen,
  closeModal,
  onSubmit,
}: {
  modalIsOpen: boolean;
  closeModal: VoidFunction;
  onSubmit: VoidFunction;
}) => {
  return (
    <CustomModal open={modalIsOpen} closeModal={closeModal}>
      <div className="modal__body">
        <div className="typography typography--h2">
          Are you sure you want to unlink Multi-Factor Authentication?
        </div>

        <div className="typography">
          Please use this option when user no longer has access to their device.
          It will send a new email to reconfigure multi -factor authenticator.
        </div>

        <div className="modal__actions">
          <Button
            type={ButtonType.Secondary}
            isDisabled={false}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            type={ButtonType.Primary}
            onClick={onSubmit}
            isDisabled={false}
          >
            Unlink now
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default UnlinkMFA;
