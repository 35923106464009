import React, { createContext, useState, useContext, useMemo, useCallback, useEffect } from "react";
import { postData } from "../utils/fetchApi";
import { useNavigate } from "react-router-dom";
import { convertToken } from "../utils/helpers";
import { v4 as uuidv4 } from "uuid";

interface AuthContextType {
	signed: boolean;
	user: UserToken | null;
	Login(user: object): Promise<any>;
	Logout(remove: boolean): void;
	silentRenew: (force?: boolean) => Promise<any>;
	token: string | null;
	isLoading: boolean;
	isExpired: boolean;
  getRememberMe(rememberDevice: boolean): RememberMeStorage | null;
  removeRememberMe(): void;
  setRememberMe(value:RememberMeStorage): void;
  bypassMfa: boolean;
}
export interface ResponseToken {
	aud: string;
	exp: number;
	iat: number;
	iss: string;
	jti: string;
	nbf: number;
	sub: string;
	userId: string;
	userType: string;
	enabledMFA: boolean;
	documentsAccessType: string;
	firstName: string;
}
export interface UserToken {
	enabledMFA: boolean;
	firstName: string;
	expire: Date;
	isAdmin: boolean;
	greenCard: boolean;
	coverNote: boolean;
	isViewOnly: boolean;
	accessToken: string;
	refreshToken: string;
	userId: string;
	isInfrastructure: boolean;
}
export interface CreateTokenResponse {
	accessToken: string;
	refreshToken: string;
}
export interface RememberMeStorage {
	deviceId: string;
	expire: Date;
}

const AuthContext = createContext<AuthContextType>(null!);

interface Props {
	children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
	const [user, setUser] = useState<UserToken | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	let navigate = useNavigate();

	useEffect(() => {
		if (process.env.REACT_APP_PUBLIC_GO_LIVE === "false" && !!user) navigate("/liveSoon");
	}, [user]);

	useEffect(() => {
		const storagedToken = localStorage.getItem("@brokerApp:token");
		const storagedRefreshToken = localStorage.getItem("@brokerApp:refreshToken");
		if (storagedToken && storagedRefreshToken) {
			setIsLoading(true);
			const tokenResponse: CreateTokenResponse = {
				accessToken: storagedToken,
				refreshToken: storagedRefreshToken,
			};
			setUser(convertToken(tokenResponse));
		}
		setIsLoading(false);
	}, []);

	const silentRenew = useCallback(
		async (force: boolean = false) => {
			const currentDate = new Date();

			if (user?.accessToken && user?.expire?.getTime() - (currentDate.getTime() - 30000) > 0 && !force) {
				return user?.accessToken;
			}

			const silentRenewResponse = await postData(
				"users/refresh-token",
				{
					accessToken: user?.accessToken,
					refreshToken: user?.refreshToken,
				},
				user?.accessToken
			);

			if (silentRenewResponse.success && silentRenewResponse?.data) {
				const userToken = convertToken(silentRenewResponse.data);
				setUser(userToken);

				if (userToken) {
					localStorage.setItem("@brokerApp:token", silentRenewResponse.data.accessToken);
					localStorage.setItem("@brokerApp:refreshToken", silentRenewResponse.data.refreshToken);

					return silentRenewResponse.data.accessToken;
				}
			} else if (silentRenewResponse.statusCode === 401) {
				Logout(false);
				return null;
			} else {
				return null;
			}
		},
		[user?.accessToken, user?.expire, user?.refreshToken]
	);

	const Login = useCallback(async (userData: any): Promise<any> => {
		setIsLoading(true);
		const code = userData?.code as string;
		const deviceId = userData?.deviceId as string;
		const response = await postData(code.length === 0 && !deviceId && !bypassMfa ? "users/authenticatorDetails" : "users/token", userData, null);
		if (response.success && response?.data) {
			const userToken = convertToken(response.data);
			if (userToken) {
				setUser(userToken);
				localStorage.setItem("@brokerApp:token", response.data.accessToken);
				localStorage.setItem("@brokerApp:refreshToken", response.data.refreshToken);
			}
		}
		setIsLoading(false);
		return response;
	}, []);

	const Logout = useCallback(
		async (removeDevice: boolean) => {
			const rememberMeStorage = getRememberMe(false);
      const storedDeviceId = rememberMeStorage?.deviceId;
			await postData(
				"users/logout",
				{
					deviceId: removeDevice ? storedDeviceId : null,
				},
				user?.accessToken
			).catch(() => {
				if (removeDevice) removeRememberMe();
				localStorage.removeItem("@brokerApp:token");
				localStorage.removeItem("@brokerApp:refreshToken");
				navigate("/");
				setUser(null);
			});

			if (removeDevice) removeRememberMe();
			localStorage.removeItem("@brokerApp:token");
			localStorage.removeItem("@brokerApp:refreshToken");
			navigate("/");
			setUser(null);

			//revoke refresh token
		},
		[navigate, user?.accessToken]
	);

	const isExpired = useMemo(() => (user?.expire ? user?.expire < new Date() : true), [user]);

	const getRememberMe = (rememberDevice: boolean): RememberMeStorage | null => {
		if (rememberDevice) {
			var fiveDaysInFuture = new Date();
			fiveDaysInFuture.setDate(fiveDaysInFuture.getDate() + 5);
			return { deviceId: uuidv4(), expire: fiveDaysInFuture };
    }
		try {
			const rememberMe = localStorage.getItem("@brokerApp:rememberMe");
			let rememberMeStorage: RememberMeStorage | null = null;
			if (rememberMe) {
				const storedDeviceId = JSON.parse(rememberMe)
				rememberMeStorage = { deviceId: storedDeviceId.deviceId, expire: new Date(storedDeviceId.expire) };
				if (rememberMeStorage.expire < new Date()) {
          removeRememberMe();
					return null;
				}
			}
			return rememberMeStorage;
		} catch (error) {
			return null;
		}
	};
	const setRememberMe = (value: RememberMeStorage): void => {
		localStorage.setItem("@brokerApp:rememberMe", JSON.stringify(value));
	};
	const removeRememberMe = (): void => {
		localStorage.removeItem("@brokerApp:rememberMe");
	};
	const bypassMfa = process.env.REACT_APP_PUBLIC_RECAPTCHA_BYPASS_MFA ? process.env.REACT_APP_PUBLIC_RECAPTCHA_BYPASS_MFA?.toUpperCase() === "TRUE" : false;

	const auth = useMemo(
		() => ({
			signed: Boolean(user),
			user,
			Login,
			Logout,
			silentRenew,
			token: user?.accessToken ?? null,
			isLoading,
			isExpired,
      		getRememberMe,
			removeRememberMe,
			setRememberMe,
			bypassMfa
		}),
		[user, Login, silentRenew, isLoading, isExpired, getRememberMe, removeRememberMe, setRememberMe, Logout, bypassMfa]
	);

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export function useAuth() {
	const context = useContext(AuthContext);

	return context;
}
