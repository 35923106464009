import { useCallback, useEffect, useState } from "react";

import { getData, putData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";

const useGetBroker = (id: string, fetch: boolean, privateToken?: string) => {
  const { token, silentRenew } = useAuth();
  const [broker, setBroker] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const confirmBrokerDetails = useCallback(
    async (body: any) => {
      try {
        setIsLoading(true);

        let newToken;

        if (!privateToken) newToken = await silentRenew();
        const response = await putData(
          `users/${id}`,
          body,
          privateToken || newToken
        );
        setIsLoading(false);
        return response;
      } catch (error: any) {
        setIsLoading(false);
      }
    },
    [id, privateToken, silentRenew]
  );

  const getBroker = useCallback(
    async (id: string): Promise<void> => {
      try {
        setIsLoading(true);

        let newToken;

        if (!privateToken) newToken = await silentRenew();

        const response = await getData(
          `users/${id}`,
          privateToken || newToken
        ).catch((error) => {
          setBroker({ code: error.code, message: error.message });
          setIsLoading(false);
          return;
        });

        if (response?.statusCode === 401) {
          setBroker({ code: response.statusCode, message: "Unauthorized" });
          setIsLoading(false);
          return;
        }
        setBroker(response.data);
        setIsLoading(false);
      } catch (error: any) {
        setBroker({ code: error.code, message: error.message });
        setIsLoading(false);
      }
    },
    [privateToken, silentRenew]
  );

  useEffect((): void => {
    if (fetch && (token || privateToken) && id) getBroker(id);
  }, [fetch, token, privateToken, id]);

  return { broker, isLoading, confirmBrokerDetails };
};

export default useGetBroker;
