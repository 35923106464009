import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminLayout from "../../../layouts/admin-layout";
import useGetBrokers from "../../../hooks/useGetBrokers";
import { formatDate } from "../../../utils/helpers";
import { ReactComponent as PenIcon } from "../../../icons/pen.svg";
import Button, { ButtonType } from "../../../components/Button";
import { useNavigate } from "react-router";
import {
  BrokerStatus,
  isActionOptionsMap,
  isActiveOptions,
  userTypeOptions,
  userTypeOptionsMap,
} from "../../../models/broker";
import Pagination from "../../../components/Pagination";
import Search from "../../../components/Search";
import format from "date-fns/format";
import FilterTableDropdown from "../../../components/FilterTableDropdown";
import EmptyList from "../../../components/EmptyList";

const Brokers: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [search, setSearch] = useState("");
  const [userTypesSelected, setUserTypesSelected] = useState(userTypeOptions);
  const [isActiveSelected, setIsActiveSelected] = useState(isActiveOptions);

  const { brokers, isLoading, exportBrokers } = useGetBrokers(
    itemsPerPage,
    currentPage,
    search,
    userTypesSelected.map((type) => userTypeOptionsMap[type]).join(","),
    isActiveSelected.map((option) => isActionOptionsMap[option]).join(",")
  );
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [search, isActiveSelected, userTypesSelected]);

  const currentBrokers = useMemo(
    () => brokers?.brokers ?? [],
    [brokers?.brokers]
  );

  const handleExport = useCallback(() => {
    exportBrokers()
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = href;
        link.download = `brokers-${format(new Date(), "yyyy/MM/dd hh:mm:ss")
          .replace(" ", "")
          .replaceAll("/", "")
          .replaceAll(":", "")}.csv`;
        link.click();
        URL.revokeObjectURL(href);
      })
      .catch((err) => console.error(err));
  }, [exportBrokers]);

  return (
    <AdminLayout loading={isLoading}>
      <div className="broker">
        <div className="broker__header">
          <span className="typography typography--h2">
            Broker Administration
          </span>
        </div>
        <div>
          <div className="broker__table-actions">
            <div className="broker__table-actions__wrapper">
              <Search setReference={setSearch} />
            </div>
            <div className="broker__header-actions">
              <Button
                onClick={() => navigate("/admin/brokers/new")}
                size="medium"
              >
                Add user
              </Button>
              <Button
                size="medium"
                type={ButtonType.Secondary}
                onClick={handleExport}
              >
                Export
              </Button>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="table__header-cell">Broker Name</th>
                <th className="table__header-cell">First and last name</th>
                <th className="table__header-cell">Email</th>
                <th className="table__header-cell">Agency Number</th>
                <th className="table__header-cell">Created date</th>
                <th className="table__header-cell">Invite date</th>
                <th className="table__header-cell">
                  <FilterTableDropdown
                    selected={isActiveSelected}
                    label="Active"
                    options={isActiveOptions}
                    setSelected={setIsActiveSelected}
                  />
                </th>
                <th className="table__header-cell">
                  <FilterTableDropdown
                    selected={userTypesSelected}
                    options={userTypeOptions}
                    label="User type"
                    setSelected={setUserTypesSelected}
                  />
                </th>
                <th className="table__header-cell"></th>
              </tr>
            </thead>
            <tbody>
              {currentBrokers?.length !== 0 &&
                currentBrokers?.map((broker: any) => (
                  <tr
                    className={`${
                      broker.status === BrokerStatus.WaitingForApproval
                        ? "table__highlight"
                        : ""
                    }`}
                  >
                    <td
                      className={`table__cell table__cell table__cell table__cell--bold ${
                        broker.status === BrokerStatus.WaitingForApproval
                          ? "table__cell table__cell--pending"
                          : ""
                      }`}
                    >
                      {broker.agencyName}
                    </td>
                    <td
                      className={`table__cell table__cell table__cell table__cell--bold ${
                        broker.status === BrokerStatus.WaitingForApproval
                          ? "table__cell table__cell--pending"
                          : ""
                      }`}
                    >
                      {broker.userFullName}
                    </td>
                    <td
                      className={`table__cell table__cell table__cell table__cell--bold ${
                        broker.status === BrokerStatus.WaitingForApproval
                          ? "table__cell table__cell--pending"
                          : ""
                      }`}
                    >
                      {broker.email}
                    </td>
                    <td
                      className={`table__cell table__cell table__cell table__cell--bold ${
                        broker.status === BrokerStatus.WaitingForApproval
                          ? "table__cell table__cell--pending"
                          : ""
                      }`}
                    >
                      {broker.agencyNumber}
                    </td>
                    <td
                      className={`table__cell table__cell table__cell table__cell--bold ${
                        broker.status === BrokerStatus.WaitingForApproval
                          ? "table__cell table__cell--pending"
                          : ""
                      }`}
                    >
                      {formatDate(`${broker.createDate}Z`, "dd/MM/yyyy")}
                    </td>
                    <td
                      className={`table__cell table__cell table__cell table__cell--bold ${
                        broker.status === BrokerStatus.WaitingForApproval
                          ? "table__cell table__cell--pending"
                          : ""
                      }`}
                    >
                      -
                    </td>
                    <td
                      className={`table__cell table__cell table__cell table__cell--bold ${
                        broker.status === BrokerStatus.WaitingForApproval
                          ? "table__cell table__cell--pending"
                          : ""
                      }`}
                    >
                      {broker.status === BrokerStatus.Approved ? (
                        <span className="broker__text">Yes</span>
                      ) : broker.status === BrokerStatus.WaitingForApproval ? (
                        "PENDING"
                      ) : (
                        <span className="broker__text--text-not-active">
                          No
                        </span>
                      )}
                    </td>
                    <td
                      className={`table__cell table__cell table__cell table__cell--bold ${
                        broker.status === BrokerStatus.WaitingForApproval
                          ? "table__cell table__cell--pending"
                          : ""
                      }`}
                    >
                      {broker.userType}
                    </td>
                    <td className="table__cell table__cell--bold">
                      {broker.status === BrokerStatus.WaitingForApproval ? (
                        <Button
                          size="tiny"
                          onClick={() => {
                            navigate(
                              `/admin/brokers/${broker.id}/requestDetails`
                            );
                          }}
                        >
                          View
                        </Button>
                      ) : (
                        <div className="broker__view">
                          <PenIcon
                            height={13}
                            width={13}
                            className={"cursor-pointer"}
                            onClick={() => {
                              navigate(
                                `/admin/brokers/${broker.id}/requestDetails`
                              );
                            }}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {currentBrokers.length === 0 && (
            <EmptyList text="There’s no brokers corresponding to your search." />
          )}
          {currentBrokers.length > 0 && (
            <Pagination
              currentItemsPerPage={currentBrokers?.length}
              itemsPerPage={itemsPerPage}
              totalItems={brokers.metadata.totalCount}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Brokers;
