import { useCallback, useEffect, useState } from "react";

import { getData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";

const useGetGreenCards = (
  fetch: boolean,
  itemsPerPage: number,
  currentPage: number,
  search: string,
  start: Date | null,
  expire: Date | null,
  isAdmin?: boolean
) => {
  const { token, silentRenew } = useAuth();
  const [greenCards, setGreenCards] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const exportGreenCards = useCallback(async () => {
    try {
      if (start) start.setHours(0, 0, 0);

      if (expire) expire.setHours(23, 59, 59);

      setIsLoading(true);
      const newToken = await silentRenew();
      const response = await getData(
        `greenCards/${isAdmin ? "admin/" : ""}export?search=${search}&start=${
          start ? start?.toISOString() : ""
        }&expire=${expire ? expire?.toISOString() : ""}`,
        newToken,
        true
      );

      setIsLoading(false);
      return response;
    } catch (error: any) {
      setIsLoading(false);
    }
  }, [expire, search, silentRenew, start, isAdmin]);

  const getGreenCards = useCallback(async (): Promise<void> => {
    try {
      if (start) start.setHours(0, 0, 0);

      if (expire) expire.setHours(23, 59, 59);

      setIsLoading(true);
      const newToken = await silentRenew();
      const response = await getData(
        `${
          isAdmin ? "greenCards/admin" : "greenCards"
        }?search=${search}&page=${currentPage}&limit=${itemsPerPage}&start=${
          start ? start?.toISOString() : ""
        }&expire=${expire ? expire?.toISOString() : ""}`,
        newToken
      );
      setGreenCards(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setGreenCards({ code: error.code, message: error.message });
      setIsLoading(false);
    }
  }, [start, expire, silentRenew, isAdmin, search, currentPage, itemsPerPage]);

  useEffect((): void => {
    if (fetch && token) getGreenCards();
  }, [fetch, token, currentPage, itemsPerPage, isAdmin]);

  return { greenCards, isLoading, exportGreenCards };
};

export default useGetGreenCards;
