import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "../DatePicker/DatePicker";

interface Props {
  formData: any;
  formErrors: any;
  setFormData: any;
}

const PeriodOfCover: React.FC<Props> = ({
  formData,
  formErrors,
  setFormData,
}: Props) => {
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null);
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);

  const currentDate = new Date();

  useEffect(() => {
    if (formData.effective && formData.expiry) {
      const effectiveDateParts = formData?.effective?.split("/");
      const expiryDateParts = formData?.expiry?.split("/");

      if (expiryDateParts?.length === 3 && effectiveDateParts?.length === 3) {
        setEffectiveDate(
          new Date(
            Date.UTC(
              Number(effectiveDateParts[2]),
              Number(effectiveDateParts[1]) - 1,
              Number(effectiveDateParts[0])
            )
          )
        );
        setExpiryDate(
          new Date(
            Date.UTC(
              Number(expiryDateParts[2]),
              Number(expiryDateParts[1]) - 1,
              Number(expiryDateParts[0])
            )
          )
        );
      }
    }
  }, [formData?.effective, formData?.expiry]);

  useEffect(() => {
    const setEffective = (effective: any) => {
      setFormData((formData: any) => {
        return {
          ...formData,
          effective: effective,
        };
      });
    };

    if (effectiveDate === null) {
      setEffective(null);
      setExpiryDate(null);
      return;
    }

    setEffective(effectiveDate.toISOString());
    setExpiryDate(null);
  }, [effectiveDate]);

  useEffect(() => {
    const setExpiry = (expiry: any) => {
      setFormData((formData: any) => {
        return {
          ...formData,
          expiry: expiry,
        };
      });
    };

    if (expiryDate === null) {
      setExpiry(null);
      return;
    }

    setExpiry(expiryDate.toISOString());
  }, [expiryDate]);

  const maxExpiryDate = useMemo(() => {
    if (effectiveDate === null)
      return null;

    let maxExpiryDate = new Date(effectiveDate);
    maxExpiryDate.setFullYear(maxExpiryDate.getFullYear() + 1);

    return maxExpiryDate;
  }, [effectiveDate]);

  const minExpiryDate = useMemo(() => {
    if (effectiveDate === null)
      return null;

    let minExpiryDate = new Date(effectiveDate);
    minExpiryDate.setDate(minExpiryDate.getDate() + 1);

    return minExpiryDate;
  }, [effectiveDate]);

  const updateExpiryDate = useCallback((date : Date|null) => {
    if (!maxExpiryDate || !minExpiryDate) {
      return;
    }

    if (date === null) {
      setExpiryDate(null);
      return;
    }

    if (date < minExpiryDate || date > maxExpiryDate) {
      return;
    }

    setExpiryDate(date);
  }, [maxExpiryDate, minExpiryDate])

  return (
    <>
      <div className="green-card__input-wrapper green-card__input-wrapper--full-line">
        <span className="typography">
          Please note that the expiry date must not exceed the expiry date shown
          on the Certificate of Insurance as this will invalidate the Green
          Card.
        </span>
      </div>
      <div className="green-card__input-wrapper">
        <DatePicker
          value={effectiveDate}
          label="Effective from"
          required={formErrors.effective.required}
          onChange={setEffectiveDate}
          hasMessage={
            formErrors.effective.error && formErrors.effective.message
          }
          //@ts-ignore
          placement="topStart"
          disableDateBefore={
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate()
            )
          }
        />
      </div>
      <div className="green-card__input-wrapper">
        <DatePicker
          value={expiryDate}
          label="Expires"
          onChange={updateExpiryDate}
          required={formErrors.expiry.required}
          hasMessage={formErrors.expiry.error && formErrors.expiry.message}
          //@ts-ignore
          placement="topStart"
          disabled={!effectiveDate}
          disableDateBefore={
            minExpiryDate == null
              ? null
              : new Date(
                  minExpiryDate.getFullYear(),
                  minExpiryDate.getMonth(),
                  minExpiryDate.getDate()
                )
          }
          disableDateAfter={
            maxExpiryDate == null
              ? null
              : new Date(
                  maxExpiryDate.getFullYear(),
                  maxExpiryDate.getMonth(),
                  maxExpiryDate.getDate(),
                  23,
                  59,
                  59
                )
          }
          valueOnOpen={maxExpiryDate}
        />
      </div>
    </>
  );
};

export default PeriodOfCover;
