import React, { useEffect } from "react";
import MainLayout from "../../layouts/main-layout";
import { ReactComponent as CoverNote } from "../../icons/file_filled.svg";
import { ReactComponent as GreenCard } from "../../icons/file.svg";
import Button, { ButtonType } from "../../components/Button";
import { useAuth } from "../../contexts/auth";
import { useNavigate } from "react-router";

const Home: React.FC = () => {
	const { user } = useAuth();

	let navigate = useNavigate();

	useEffect(() => {
		if (!user) return;
		if (user.isInfrastructure) {
			navigate("/infra/ipwhitelist");
			return;
		}
		if (user.isViewOnly) {
			navigate("/admin/stats");
			return;
		}
	}, [user]);

	return (
		<MainLayout isPrivate maxWidth bgImage>
			<div className="home">
				<h1 className="typography--h1">Welcome {user?.firstName}</h1>
				<div className="home__options">
					<div>
						<CoverNote className="home__options__icon" width={132.5} height={170} />
						<h2 className="typography typography--h1">Cover Note</h2>
						<Button modifierClassName="home__buttons" size="large" isDisabled={!user?.coverNote} onClick={() => navigate("/coverNotes/issue")}>
							Issue
						</Button>
						<Button modifierClassName="home__buttons" type={ButtonType.Secondary} size="large" isDisabled={!user?.coverNote} onClick={() => navigate("/coverNotes/library")}>
							Library
						</Button>
					</div>
					<div>
						<GreenCard className="home__options__icon" width={132.5} height={170} />
						<h2 className="typography typography--h1">Green Card</h2>
						<Button isDisabled={!user?.greenCard} modifierClassName="home__buttons" size="large" onClick={() => navigate("/greenCards/issue")}>
							Issue
						</Button>
						<Button isDisabled={!user?.greenCard} modifierClassName="home__buttons" type={ButtonType.Secondary} size="large" onClick={() => navigate("/greenCards/library")}>
							Library
						</Button>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

export default Home;
