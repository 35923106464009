import React, { useCallback, useState } from "react";
import MainLayout from "../../layouts/main-layout";
import { Link, useNavigate } from "react-router-dom";
import playStore from "../../icons/play-store.png";
import appleStore from "../../icons/app-store.png";
import { QRCodeSVG } from "qrcode.react";
import authy from "../../icons/authy.png";

import Button, { ButtonType } from "../../components/Button";
import Input from "../../components/Input";
import useGetAuthenticatorDetails from "../../hooks/useGetAuthenticatorDetails";
import { postData } from "../../utils/fetchApi";

const MfaSetup = () => {
  const { authenticatorDetails, isLoading } = useGetAuthenticatorDetails();

  const navigate = useNavigate();
  const [hasMFAApp, setHasMFAApp] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [linkClicked, setLinkClicked] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState<string[] | null>(null);
  const [error, setError] = useState(false);

  const handleOnChange = useCallback((e: any) => {
    const { value } = e.target;
    let val = value === "" ? null : value;
    setVerificationCode(val);
  }, []);

  const handleVerify = useCallback(
    async (event: any) => {
      event.preventDefault();
      setError(false);
      if (authenticatorDetails && authenticatorDetails?.brokerId) {
        const codeVerifiedResponse = await postData(
          `users/enableAuthenticator/${authenticatorDetails.brokerId}`,
          {
            code: verificationCode,
          }
        ).catch((error) => {
          setError(false);
        });

        if (!codeVerifiedResponse.success) {
          setError(true);
          return;
        }
        setRecoveryCodes(codeVerifiedResponse.data.recoveryCodes);
        setError(false);
        setCodeVerified(true);
        setError(false);
        return;
      } else {
        setError(true);
      }
    },
    [authenticatorDetails, verificationCode]
  );

  return (
    <MainLayout isPrivate loading={isLoading} allowViewOnly allowInfrastructure>
      <div className="profile">
        <Link
          className="typography cover-note__go-back typography--link typography--bold"
          to={"/brokerDetails"}
        >
          Go back
        </Link>
        <div className="profile__heading">
          <div className="profile__heading__text">
            <span className="typography typography--h2">
              Multi-factor authenticator
            </span>
          </div>
        </div>
        <div className="profile__mfa-setup">
          {!hasMFAApp ? (
            <>
              <div>
                <div className="typography typography--bold">
                  Configure authenticator app
                </div>

                <div className="typography">
                  Before advancing, please make sure you have installed an
                  authenticator app on your device of choice. The following are
                  officially endorsed authenticator apps, please follow their
                  installation and setup guides.
                </div>
              </div>
              <div className="profile__container-apps">
                <div className="mfa-setup__app-container-wrapper">
                  <div className="typography typography--bold">
                    Android/iOS Device: Microsoft Authenticator
                  </div>
                  <div className="mfa-setup__app-wrapper">
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                      className="cursor-pointer"
                    >
                      <img
                        src={playStore}
                        alt="play-store"
                        height={61}
                        width={206}
                      />
                    </a>
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://apps.apple.com/gb/app/microsoft-authenticator/id983156458"
                      className="cursor-pointer"
                    >
                      <img
                        src={appleStore}
                        alt="apple-store"
                        height={61}
                        width={206}
                      />
                    </a>
                  </div>
                </div>
                <div className="mfa-setup__app-container-wrapper">
                  <div className="typography typography--bold">
                    Windows/macOS Computer: Authy
                  </div>
                  <div className="mfa-setup__app-wrapper">
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://authy.com/"
                      className="cursor-pointer"
                    >
                      <img src={authy} alt="authy" height={63} width={453} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="typography">
                If you already have an authenticator app, you may skip this
                step.
              </div>
              <div className="profile__action-buttons">
                <Button
                  type={ButtonType.Secondary}
                  onClick={() => navigate("/brokerDetails")}
                >
                  Back
                </Button>

                <Button onClick={() => setHasMFAApp(true)}>Next</Button>
              </div>
            </>
          ) : (
            <>
              {codeVerified ? (
                <>
                  <div>
                    <div className="typography typography--bold">
                      Recovery codes
                    </div>
                  </div>

                  <div className="profile__recovery-codes">
                    <div className="profile__recovery-codes-banner">
                      <span className="typography typography--bold">
                        Put these codes in a safe place.
                      </span>
                      <span className="typography">
                        If you lose your device and don't have the recovery
                        codes you will lose access to your account.
                      </span>
                    </div>
                    <div className="profile__recovery-codes-list">
                      {!!recoveryCodes?.length &&
                        recoveryCodes.map((it, index) => (
                          <div
                            className="profile__recovery-codes-item  typography typography--bold"
                            key={index}
                          >
                            {it}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="mfa-setup__actions mfa-setup__actions--profile">
                    <Button
                      type={ButtonType.Secondary}
                      onClick={() => {
                        navigate("/brokerDetails");
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div className="typography typography--bold">
                      Configure authenticator app
                    </div>
                    {error && hasMFAApp && (
                      <div className="typography typography--error">
                        Verification code is invalid. Please enter a correct
                        verification code.
                      </div>
                    )}
                    <div className="typography">
                      Tap the plus icon in your authenticator app and scan the
                      QR code using your device's camera.
                    </div>
                  </div>

                  <div className="profile__qrcode-container">
                    <div className="mfa-setup__qr-code-wrapper">
                      <QRCodeSVG
                        value={
                          authenticatorDetails?.authenticatorUri ??
                          "https://picturesofpeoplescanningqrcodes.tumblr.com/"
                        }
                        size={138}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"Q"}
                        includeMargin={false}
                      />
                      {linkClicked ? (
                        <div className="mfa-setup__code-wrapper">
                          <div className="typography">
                            Enter this key into your authenticator app
                          </div>
                          <div className="mfa-setup__code">
                            {authenticatorDetails?.sharedKey ??
                              "xkgt xers 2xsq thqh e2vb n4ih 3tqv bh2z"}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="typography typography--bold text-2base typography--link mfa-setup__link"
                          onClick={() => setLinkClicked(true)}
                        >
                          Can't see the QR code?
                        </div>
                      )}
                    </div>

                    <div className="mfa-setup__input-and-message mfa-setup__input-and-message--reverse">
                      <div className="mfa-setup__input-wrapper">
                        <Input
                          placeholder="Verification code"
                          value={verificationCode}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="typography mfa-setup__message-wrapper mfa-setup__message-wrapper--profile">
                        Once you scanned the QR code, please enter the 6 digit
                        code generated by your authenticator app.
                      </div>
                    </div>
                  </div>

                  <div className="mfa-setup__actions mfa-setup__actions--profile">
                    <Button
                      type={ButtonType.Secondary}
                      onClick={() => {
                        setHasMFAApp(false);
                        setLinkClicked(false);
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type={ButtonType.Primary}
                      isDisabled={!verificationCode}
                      onClick={handleVerify}
                    >
                      Verify
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default MfaSetup;
