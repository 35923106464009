import { selectOptions } from "../../forms/brokerIssueGreenCard.form";
import Dropdown from "../Dropdown";
import Input from "../Input";

interface Props {
  formData: any;
  formErrors: any;
  setFormData: any;
  handleOnChange: any;
}

const VehicleDetails: React.FC<Props> = ({
  formData,
  formErrors,
  setFormData,
  handleOnChange,
}: Props) => {
  return (
    <>
      <div className="green-card__input-wrapper green-card__input-wrapper--full-line">
        <Dropdown
          value={formData.categoryVehicle}
          label="Category of vehicle"
          smallGap
          required={formErrors.categoryVehicle.required}
          onChange={(option) => {
            setFormData({
              ...formData,
              categoryVehicle: option,
            });
          }}
          options={selectOptions.vehicleDetails.vehicles}
          placeholder="Please select"
          hasMessage={
            formErrors.categoryVehicle.error &&
            formErrors.categoryVehicle.message
          }
        />
      </div>
      <div className="green-card__input-wrapper">
        <Input
          value={formData.make}
          required={formErrors.make.required}
          label="Make of vehicle"
          smallGap
          onChange={handleOnChange}
          maxLength={32}
          name="make"
          placeholder="Enter Make"
          hasMessage={formErrors.make.error && formErrors.make.message}
        />
      </div>
      <div className="green-card__input-wrapper">
        <Input
          value={formData.registrationNumber}
          label="Registration number or Chassis number"
          required={formErrors.registrationNumber.required}
          smallGap
          onChange={handleOnChange}
          maxLength={17}
          name="registrationNumber"
          placeholder="Enter registration number or chassis number"
          hasMessage={
            formErrors.registrationNumber.error &&
            formErrors.registrationNumber.message
          }
        />
      </div>
    </>
  );
};

export default VehicleDetails;
