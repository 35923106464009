import React, { useCallback, useEffect, useState, FC } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import SplitScreen from "../../components/SplitScreen";
import { initialForm, initialFormErrors } from "../../forms/signup.form";
import useGetBroker from "../../hooks/useGetBroker";
import { handleApiResponseForm, validateForm } from "../../utils/formHelper";

const ConfirmProfileDetails: FC = () => {
  const [userAlreadyExistsError, setUserAlreadyExistsError] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [, setFetch] = useState(true);
  let navigate = useNavigate();
  const [formData, setFormData] = useState(initialForm);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [searchParameters] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const { broker, confirmBrokerDetails, isLoading } = useGetBroker(
    searchParameters.get("id") as string,
    true,
    searchParameters.get("token") as string
  );

  useEffect(() => {
    if (broker) {
      setFetch(false);
      setFormData(broker);
    }
    setFetch(false);
  }, [broker]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (!validateForm(initialFormErrors, setFormErrors, formData)) {
      setLoading(false);
      return;
    }

    await postFormData();
  };

  const postFormData = useCallback(async () => {
    const request = await confirmBrokerDetails(formData);

    if (!request?.success) {
      handleApiResponseForm(request, initialFormErrors, setFormErrors);
      setLoading(false);
      return;
    }
    setLoading(false);
    navigate("/resetPassword", {
      state: {
        token: searchParameters.get("token"),
        id: searchParameters.get("id"),
      },
    });
  }, [confirmBrokerDetails, formData, navigate, searchParameters]);

  useEffect(() => {
    if (formErrors.defaultMessage?.error === false) return;

    if (formErrors.defaultMessage?.message === "User Already Exists.") {
      setUserAlreadyExistsError(true);
    } else {
      setHasErrorMessage(true);
    }

    const body = document.querySelector(".sign-up__header");
    if (body === null) return;

    body.scrollIntoView();
    return;
  }, [formErrors]);

  const handleOnChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      let val = value === "" ? null : value;
      setFormData({
        ...formData,
        [name]: val,
      });
    },
    [formData, setFormData]
  );

  return (
    <SplitScreen isLoading={loading || isLoading}>
      <div className="sign-up">
        {broker?.message ? (
          <div className="sign-up__header sign-up__header--error">
            <div className="typography typography--h1">
              Your link has expired.
            </div>
            <div className="typography">
              Please{" "}
              <Link
                to="/forgotPassword"
                className="typography typography--link"
              >
                click here
              </Link>{" "}
              to get a new one.
            </div>
          </div>
        ) : (
          <>
            <div className="sign-up__header">
              <div className="typography typography--h1">
                Confirm your profile details
              </div>
              {userAlreadyExistsError && (
                <div className="typography typography--error">
                  An account with this email address already exists. Click the
                  "Login here" link below to access your account or reset your
                  password. If you're having difficulties logging in, please
                  email the team:{" "}
                  <a
                    className="typography typography--error typography--link"
                    href="mailto:agency@ers.com"
                  >
                    agency@ers.com
                  </a>
                  .
                </div>
              )}
              {hasErrorMessage && (
                <div className="typography typography--error">
                  Something went wrong.
                </div>
              )}
            </div>
            <form className="form form--small-gap" onSubmit={handleSubmit}>
              <Input
                smallGap
                label="First name"
                name="firstName"
                value={formData.firstName}
                onChange={handleOnChange}
                placeholder="Enter your first name"
                hasMessage={
                  formErrors.firstName.error && formErrors.firstName.message
                }
              />
              <Input
                smallGap
                label="Surname"
                name="lastName"
                value={formData.lastName}
                onChange={handleOnChange}
                placeholder="Enter your surname"
                hasMessage={
                  formErrors.lastName.error && formErrors.lastName.message
                }
              />
              <Input
                smallGap
                label="Work email address"
                name="email"
                disabled={true}
                value={formData.email}
                onChange={handleOnChange}
                placeholder="name@domain.com"
                hasMessage={formErrors.email.error && formErrors.email.message}
              />
              <Input
                smallGap
                label="Telephone number"
                name="workTelephoneNumber"
                value={formData.workTelephoneNumber}
                onChange={handleOnChange}
                placeholder="+44 1234 567 890"
                hasMessage={
                  formErrors.workTelephoneNumber.error &&
                  formErrors.workTelephoneNumber.message
                }
              />
              <Input
                smallGap
                label="Job title"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleOnChange}
                placeholder="Enter your job title"
                hasMessage={
                  formErrors.jobTitle.error && formErrors.jobTitle.message
                }
              />
              <Input
                smallGap
                label="Broker name"
                name="brokerName"
                disabled={true}
                value={formData.brokerName}
                onChange={handleOnChange}
                placeholder="Enter your broker name"
                hasMessage={
                  formErrors.brokerName.error && formErrors.brokerName.message
                }
              />
              <Input
                smallGap
                label="Agency number"
                name="agencyNumber"
                disabled={true}
                value={formData.agencyNumber}
                onChange={handleOnChange}
                placeholder="Enter your agency number"
                hasMessage={
                  formErrors.agencyNumber.error &&
                  formErrors.agencyNumber.message
                }
              />
              <Input
                smallGap
                label="Company Address Line 1"
                name="companyAddressLine1"
                value={formData.companyAddressLine1}
                onChange={handleOnChange}
                placeholder="Enter your company address line 1"
                hasMessage={
                  formErrors.companyAddressLine1.error &&
                  formErrors.companyAddressLine1.message
                }
              />
              <Input
                smallGap
                name="companyAddressLine2"
                value={formData.companyAddressLine2}
                onChange={handleOnChange}
                label="Company Address Line 2"
                placeholder="Enter your company address line 2"
                hasMessage={
                  formErrors.companyAddressLine2.error &&
                  formErrors.companyAddressLine2.message
                }
              />
              <Input
                smallGap
                label="Post code"
                name="postCode"
                value={formData.postCode}
                onChange={handleOnChange}
                placeholder="Enter your post code"
                maxLength={10}
                hasMessage={
                  formErrors.postCode.error && formErrors.postCode.message
                }
              />
              <div className="relative">
                {/* <ReCAPTCHA size="invisible" sitekey={"fd"} /> */}
              </div>
              <Button onClick={handleSubmit}>Confirm details</Button>
            </form>
          </>
        )}
      </div>
    </SplitScreen>
  );
};

export default ConfirmProfileDetails;
