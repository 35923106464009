import React from "react";
import MainLayout from "./main-layout";
import AdminNavbar from "../components/AdminNavbar/AdminNavbar";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/auth";

interface Props {
  children: React.ReactNode;
  goBackLink?: string;
  loading?: boolean;
  allowViewOnly?: boolean;
}
const AdminLayout: React.FC<Props> = ({
  children,
  goBackLink = null,
  loading,
  allowViewOnly = false
}) => {
  const { user } = useAuth();

  if (user && (!user.isAdmin && !user.isViewOnly)) {
    return <>Forbidden</>;
  }

  return (
    <MainLayout isPrivate loading={loading} allowViewOnly={allowViewOnly}>
      <AdminNavbar />
      <div className="admin-layout__content">
        {goBackLink && (
          <Link
            className="typography text-2base typography--link typography--bold"
            to={goBackLink}
          >
            Go back
          </Link>
        )}
        {children}
      </div>
    </MainLayout>
  );
};

export default AdminLayout;
