import React from "react";

const LoadingSpinner = ({ fullHeight }: { fullHeight?: boolean }) => (
  <div
    className={`loading-spinner__container ${
      fullHeight ? "loading-spinner__container--fullHeight" : ""
    }`}
  >
    <svg className="loading-spinner" viewBox="0 0 50 50">
      <defs>
        <linearGradient
          id="gradient"
          gradientTransform="rotate(80, 0.5, 0.5)"
          x1="30%"
          y1="60%"
          x2="70%"
          y2="47%"
        >
          <stop stopColor="	hsl(356, 100%, 37%)" stopOpacity="1" offset="0%" />
          <stop
            stopColor="hsla(356, 100%, 37%, 0.60)"
            stopOpacity="1"
            offset="50%"
          />
          <stop
            stopColor="hsla(356, 900%, 37%, 0.40)"
            stopOpacity="1"
            offset="70%"
          />
          <stop
            stopColor="hsla(356, 100%, 37%, 0.05)"
            stopOpacity="1"
            offset="100%"
          />
        </linearGradient>
      </defs>
      <circle
        strokeLinecap="round"
        className="loading-spinner loading-spinner__circle"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
        stroke="url(#gradient)"
      />
    </svg>
  </div>
);

export default LoadingSpinner;
