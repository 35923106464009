import { useEffect, useState } from "react";
import {
  getFormErrors,
  selectOptions,
} from "../../forms/brokerIssueGreenCard.form";
import Dropdown from "../Dropdown";
import Input from "../Input";

interface Props {
  formData: any;
  formErrors: any;
  setFormErrors: any;
  setFormData: any;
  handleOnChange: any;
  isReused?: boolean;
}

const NameOfInsured: React.FC<Props> = ({
  formData,
  formErrors,
  setFormErrors,
  setFormData,
  handleOnChange,
  isReused = false,
}: Props) => {
  const [isReusedGreenCard, setIsReusedGreenCard] = useState(isReused);

  useEffect(() => {
    if (formData?.entityType && !isReusedGreenCard) {
      setFormErrors({
        ...getFormErrors(formData?.entityType, formErrors, formData),
      });
      setIsReusedGreenCard(false);
    }
  }, [formData?.entityType]);

  return (
    <>
      <div className="cover-note__input-wrapper">
        <Dropdown
          value={formData.entityType}
          label="Entity type"
          smallGap
          required={formErrors.entityType.required}
          onChange={(option) => {
            setFormData({
              ...formData,
              entityType: option,
            });
          }}
          options={selectOptions.nameOfInsured.entityType}
          placeholder="Choose one"
          hasMessage={
            formErrors.entityType.error && formErrors.entityType.message
          }
        />
      </div>
      {formData.entityType === selectOptions.nameOfInsured.entityType[0] && (
        <>
          <div className="cover-note__input-wrapper">
            <Dropdown
              value={formData.title}
              required={formErrors.title.required}
              label="Title"
              smallGap
              onChange={(option) => {
                setFormData({
                  ...formData,
                  title: option,
                });
              }}
              options={selectOptions.title}
              placeholder="Please select"
              hasMessage={formErrors.title.error && formErrors.title.message}
            />
          </div>
          <div className="cover-note__input-wrapper">
            <Input
              value={formData.firstName}
              required={formErrors.firstName.required}
              label="First name"
              smallGap
              onChange={handleOnChange}
              placeholder="Enter first name"
              name="firstName"
              maxLength={32}
              hasMessage={
                formErrors.firstName.error && formErrors.firstName.message
              }
            />
          </div>
          <div className="cover-note__input-wrapper">
            <Input
              value={formData.surname}
              required={formErrors.surname.required}
              label="Surname"
              maxLength={32}
              smallGap
              onChange={handleOnChange}
              placeholder="Enter surname"
              name="surname"
              hasMessage={
                formErrors.surname.error && formErrors.surname.message
              }
            />
          </div>
        </>
      )}
      {formData.entityType === selectOptions.nameOfInsured.entityType[1] && (
        <div className="cover-note__input-wrapper cover-note__input-wrapper--full-line">
          <Input
            value={formData.companyName}
            required={formErrors.companyName.required}
            label="Company name"
            smallGap
            onChange={handleOnChange}
            placeholder="Enter company name"
            maxLength={63}
            name="companyName"
            hasMessage={
              formErrors.companyName.error && formErrors.companyName.message
            }
          />
          <span className="typography typography--caption mt-1">
            If the Policyholder is "trading As" please enter the full title
            under Company Name
          </span>
        </div>
      )}
      <div className="cover-note__input-wrapper">
        <Input
          value={formData.addressLine1}
          required={formErrors.addressLine1.required}
          label="Address Line 1"
          smallGap
          name="addressLine1"
          onChange={handleOnChange}
          placeholder="Enter address line 1"
          maxLength={37}
          hasMessage={
            formErrors.addressLine1.error && formErrors.addressLine1.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData.addressLine2}
          smallGap
          name="addressLine2"
          onChange={handleOnChange}
          label="Address Line 2"
          maxLength={37}
          placeholder="Enter address line 2"
          hasMessage={
            formErrors.addressLine2.error && formErrors.addressLine2.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData.city}
          required={formErrors.city.required}
          label="Town/City"
          smallGap
          name="city"
          onChange={handleOnChange}
          maxLength={37}
          placeholder="Enter town/city name"
          hasMessage={formErrors.city.error && formErrors.city.message}
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData.county}
          required={formErrors.county.required}
          label="County"
          smallGap
          name="county"
          maxLength={37}
          onChange={handleOnChange}
          placeholder="Enter county"
          hasMessage={formErrors.county.error && formErrors.county.message}
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData.postCode}
          label="Post Code"
          required={formErrors.postCode.required}
          smallGap
          name="postCode"
          onChange={handleOnChange}
          maxLength={10}
          placeholder="Enter Post Code"
          hasMessage={formErrors.postCode.error && formErrors.postCode.message}
        />
      </div>
    </>
  );
};

export default NameOfInsured;
