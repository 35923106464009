import { ReactComponent as SearchIcon } from "../icons/search.svg";

interface SearchTypes {
  setReference: (reference: any) => void;
  position?: string;
}

const Search = ({ setReference }: SearchTypes): JSX.Element => {
  let delayInput: any;

  const handleChange = (event: any) => {
    clearTimeout(delayInput);
    delayInput = setTimeout(() => {
      setReference(event.target.value);
    }, 500);
  };

  return (
    <div className="search">
      <div className="search__container">
        <div className="search__icon">
          <SearchIcon />
        </div>
        <input
          autoComplete={
            !process.env.NODE_ENV || process.env.NODE_ENV === "development"
              ? "on"
              : "new-password"
          }
          className="search__input"
          name="Search"
          placeholder="Search"
          type="Text"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Search;
