import { useCallback, useEffect, useState } from "react";
import Input from "../../components/Input";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { IpRange } from "../../forms/whitelist.form";
import IPCIDR from "ip-cidr";

interface Props {
	block: IpRange;
	handleOnChange: (range: IpRange, index: number) => void;
	index: number;
	isNew: boolean;
	isRefreshing: boolean;
	handleOnDelete: (index: number) => void;
}
const CidrEntry: React.FC<Props> = ({ block, handleOnChange, index, isNew, isRefreshing, handleOnDelete }) => {
	const [disabled, setDisabled] = useState<boolean>(false);
	const [suggestions, setSuggestions] = useState<string[]>(["0.0.0.0/0", "0.0.0.0/8", "0.0.0.0/16", "0.0.0.0/24", "0.0.0.0/32"]);

	useEffect(() => {	
		if (!isRefreshing) 
			setDisabled(!isNew);
	}, [isRefreshing, isNew]);

	const handleBlockChange = useCallback(
		(event: any) => {
			handleOnChange({ cidrIp: event.target.value, description: block.description, isNew }, index);
			setSuggestions(generateCidrSuggestions(event.target.value));
		},
		[block.description, handleOnChange, index, isNew]
	);

	const handleDescriptionChange = useCallback(
		(event: any) => {
			handleOnChange({ cidrIp: block.cidrIp, description: event.target.value, isNew }, index);
		},
		[block.cidrIp, handleOnChange, index, isNew]
	);

	const generateCidrSuggestions = (input: string): string[] => {
		const suggestions: string[] = [];
		let range = [0, 8, 16, 24, 32];
		let baseSplit = input.split(/[.\/]/).filter((value) => value);
		if (baseSplit.length > 4) baseSplit = baseSplit.slice(0, 4);

		for (let i = 0; i <= 3; i++) {
			if (baseSplit.length > i) {
				if (baseSplit[i] !== "0") {
					range = i === 3 ? [32] : range.slice(1, range.length);
				}
			} else {
				baseSplit.push("0");
			}
		}
		let baseSuggestion = "";
		baseSplit.forEach((value, index) => (baseSuggestion += `${value}${baseSplit.length !== index + 1 ? "." : ""}`));
		range.forEach((value) => {
			const suggestion = `${baseSuggestion}/${value}`;
			if (IPCIDR.isValidCIDR(suggestion)) suggestions.push(suggestion);
		});
		return suggestions;
	};

	const handleEdit = () => setDisabled(false);

	return (
		<div className="whitelist__form__input-line">
			<Input
				value={block.cidrIp}
				disabled={disabled}
				name="cidrIp"
				required
				label="CIDR block"
				hasMessage={block.cidrIpMessage}
				onChange={handleBlockChange}
				list={`cidr-suggestions-${index}`}
				suggestions={suggestions}
				type="text"
				placeholder="Search..."
			/>

			<Input value={block.description} disabled={disabled} name="description" label="Description" hasMessage={block.descriptionMessage} onChange={handleDescriptionChange} />

			<div className="whitelist__form__svg">
				<TrashIcon onClick={() => handleOnDelete(index)} />
				{!isNew && disabled && <EditIcon onClick={handleEdit} />}
			</div>
		</div>
	);
};
export default CidrEntry;
