import { useCallback, useEffect, useState } from "react";

import { getData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";

const useGetAuthenticatorDetails = () => {
  const { token, silentRenew } = useAuth();
  const [authenticatorDetails, setAuthenticatorDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const getAuthenticatorDetails = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      const newToken = await silentRenew();
      const response = await getData(`users/authenticatorDetails`, newToken);
      setAuthenticatorDetails(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setAuthenticatorDetails({ code: error.code, message: error.message });
      setIsLoading(false);
    }
  }, [silentRenew]);

  useEffect((): void => {
    if (token) getAuthenticatorDetails();
  }, [token]);

  return { authenticatorDetails, isLoading };
};

export default useGetAuthenticatorDetails;
