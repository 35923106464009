import React, { Dispatch, SetStateAction, useState } from "react";
import { ReactComponent as ArrowDown } from "../icons/arrow-down.svg";
interface YearDropdownProps {
  value: number;
  options: number[];
  onChangeYear: Dispatch<SetStateAction<number>>;
}

const YearDropdown = ({ value, options, onChangeYear }: YearDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (year: number) => {
    onChangeYear(year);
    setIsOpen(false);
  };
  return (
    <div
      className="year-dropdown"
      onClick={toggleDropdown}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="year-dropdown__input">
        <div className="year-dropdown__value">{value}</div>
        <ArrowDown width={12} height={12} />
      </div>
      {isOpen && (
        <ul className="year-dropdown__dropdown-container">
          {options.map((year, index) => (
            <li
              key={index}
              className="year-dropdown__item"
              onClick={() => handleSelect(year)}
            >
              {year}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default YearDropdown;
