import { BaseErrorForm, BaseField } from "../utils/formHelper";
import { validationType } from "../utils/validations";

interface FormErrors extends BaseErrorForm {
  emailAddress: BaseField;
}

export const initialForm = {
  emailAddress: "",
};

export const initialFormErrors: FormErrors = {
  emailAddress: {
    required: true,
    error: false,
    defaultMessage: "Enter a valid email.",
    type: validationType.email,
  },
  defaultMessage: {
    error: false,
    defaultMessage: "Something went wrong",
  },
};
