import { useEffect, useRef } from "react";
import { DatePicker as RSDatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

interface Props {
  onChange?: (d: any) => void;
  value: Date | null;
  label?: string;
  disabled?: boolean;
  hasMessage?: string | boolean;
  required?: boolean;
  cleanable?: boolean;
}

const HourPicker: React.FC<Props> = ({
  label,
  value,
  disabled,
  onChange,
  hasMessage,
  required,
  cleanable = true,
}: Props) => {
  const pickerRef = useRef(null);
  useEffect(() => {
    if (!!hasMessage && pickerRef.current) {
      // @ts-ignore
      if (pickerRef.current?.root) {
        // @ts-ignore
        pickerRef.current.root.classList.add(
          "border",
          "border-primary",
          "border-solid"
        );
      }
    } else if (pickerRef.current) {
      // @ts-ignore
      if (pickerRef.current?.root) {
        // @ts-ignore
        pickerRef.current.root.classList.remove(
          "border",
          "border-primary",
          "border-solid"
        );
      }
    }
  }, [hasMessage, pickerRef]);
  return (
    <div className="date-picker">
      {label != null && (
        <p className="typography text-2base typography--bold">
          {label}
          {required && <span className="typography typography--bold">*</span>}
        </p>
      )}
      <RSDatePicker
        ref={pickerRef}
        block
        placeholder="XX:XX"
        disabled={disabled}
        format="HH:mm"
        className={hasMessage ? "date-picker__error" : ""}
        value={value}
        onChange={onChange}
        isoWeek
        cleanable={cleanable}
        onSelect={onChange}
      />
      {!!hasMessage && (
        <div className="date-picker__message">
          <span className="typography typography--error">{hasMessage}</span>
        </div>
      )}
    </div>
  );
};

export default HourPicker;
