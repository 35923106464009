interface Props {
  onChange?: (e: any) => void;
  value: string | null;
  type?: string;
  name?: string;
  labelSize?: string;
  label?: string;
  placeholder?: string;
  hasMessage?: string | boolean;
  smallGap?: boolean;
  disabled?: boolean;
  required?: boolean;
  maxLength?: number;
  list?: string;
  suggestions?: string[];
}

const Input = ({
  onChange,
  value,
  type,
  name,
  label,
  labelSize,
  required,
  placeholder,
  hasMessage,
  smallGap = false,
  disabled = false,
  maxLength,
  list,
  suggestions
}: Props) => {
  return (
    <div className={`input-wrapper ${smallGap && "input-wrapper--small-gap"}`}>
      {label != null && (
        <p
          className={`typography typography--bold ${
            labelSize === "base" ? "" : "typography--label-input"
          }`}
        >
          {label}
          {required && (
            <span
              className={`typography typography--bold ${
                labelSize === "base" ? "" : "typography--label-input"
              }`}
            >
              *
            </span>
          )}
        </p>
      )}
      <input
      list={list}
        className={`input-wrapper__input  ${
          hasMessage && "input-wrapper__input--error"
        }`}
        autoComplete={
          !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? "on"
            : "new-password"
        }
        value={value || ""}
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength ?? 250}
      />
      {!!hasMessage && typeof hasMessage === "string" && (
        <div className="input-wrapper__message">
          <span className="typography typography--error">{hasMessage}</span>
        </div>
      )}
      {list && (
        <datalist id={list}>
          {suggestions?.map((suggestion, index) => (
            <option value={suggestion}/>
          ))}
        </datalist>
      )}
    </div>
  );
};

export default Input;
