import LoadingSpinner from "./LoadingSpinner";

interface Props {
  children: any;
  isLoading?: boolean;
}

const SplitScreen = ({ children, isLoading = false }: Props) => {
  return (
    <div className="split-screen">
      {isLoading && <LoadingSpinner fullHeight={true} />}
      <div className="split-screen__background">
        <div className="split-screen__logo-wrapper">
          <img src="/images/images/logo-white.png"></img>
          <p className="typography--bold split-screen__portal-title">
            Cover Note & Green Card Portal
          </p>
        </div>
      </div>
      <div className="split-screen__content-wrapper">
        <div className="split-screen__content">{children}</div>
      </div>
    </div>
  );
};

export default SplitScreen;
