import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import SplitScreen from "../../components/SplitScreen";
import Checkbox from "../../components/Checkbox";
import { v4 as uuidv4 } from "uuid";

const Login: React.FC = () => {
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [loginInvalid, setLoginInvalid] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
  const [userInactive, setUserInactive] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(false);

  const [goToAuthenticator, setGoToAuthenticator] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const { Login, isLoading, signed, isExpired,  getRememberMe, removeRememberMe, setRememberMe } = useAuth();
  const handleLogin = useCallback(
    async (event: any) => {
      event.preventDefault();
      setLoginErrorMessage("");
      setLoginInvalid(false);
      setUserInactive(false);
      const rememberMeStorage = getRememberMe(rememberDevice);
      const storedDeviceId = rememberMeStorage?.deviceId;
      const loginResponse = await Login({
        emailAddress: emailAddress,
        password: password,
        code: code,
        deviceId: storedDeviceId,
      });

      if (
        loginResponse.success &&
        loginResponse.data?.accessToken &&
        loginResponse.data?.refreshToken
      ) {
        navigate(from, { replace: true });
        if (storedDeviceId) setRememberMe(rememberMeStorage);
      } else if (
        loginResponse.data?.hasAuthenticator === false &&
        !storedDeviceId
      ) {
        navigate("/mfaSetup", { state: { ...loginResponse.data } });
      } else if (loginResponse.data?.hasAuthenticator) {
        setGoToAuthenticator(true);
      } else {
        if (loginResponse.statusCode === 403) {
          setUserInactive(true);
        } else {
          if (
            loginResponse.errorList &&
            loginResponse.errorList[0].defaultMessage
          ) {
            setLoginErrorMessage(loginResponse.errorList[0].defaultMessage);
          }
          setLoginInvalid(true);
        }
        removeRememberMe();
        setCode("");
        setRememberDevice(false);
        setGoToAuthenticator(false);
      }
    },
    [rememberDevice, Login, emailAddress, password, code, navigate, from]
  );

  useEffect(() => {
    if (!signed || isExpired) return;

    navigate("/liveSoon");
  }, [signed]);

  return (
    <SplitScreen isLoading={isLoading}>
      <div className="login-page">
        {goToAuthenticator ? (
          <>
            <div className="login-page__authentication-header">
              <div className="typography typography--h1">
                Authentication code.
              </div>
              <div className="typography">
                For added security, we require all login attempts to be verified
                through multi-factor authentication. Please enter the code
                generated by your authenticator app.
              </div>
            </div>
            <form className="form" onSubmit={handleLogin}>
              <Input
                label="Authentication code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <div className="sign-up__checkbox">
                <Checkbox
                  title="Remember this device"
                  isRememberDevice={true}
                  setValue={setRememberDevice}
                  value={rememberDevice}
                />
              </div>
              <Button htmlType="submit" onClick={handleLogin}>
                Login
              </Button>
              <div className="login-page__mfa-message-wrapper">
                <span className="typography">
                  Don't have access to your authenticator device?
                </span>
                <span className="typography">
                  Please contact the team:{" "}
                  <Link
                    className="typography typography--link"
                    to="mailto:agency@ers.com"
                  >
                    agency@ers.com.
                  </Link>
                </span>
              </div>
            </form>
          </>
        ) : (
          <>
            <div className="login-page__header">
              <div className="typography typography--h1">Login</div>
              {loginInvalid && (
                <div className="typography typography--error">
                  {loginErrorMessage
                    ? loginErrorMessage
                    : "We couldn't find an account matching the email and password you entered. Please try again or reset your password."}
                </div>
              )}
              {userInactive && (
                <div className="typography typography--error">
                  We've not been able to log you in as this account as been
                  deactivated.<br></br> Please contact the team:{" "}
                  <Link
                    className="typography typography--error typography--link"
                    to="mailto:agency@ers.com"
                  >
                    agency@ers.com
                  </Link>
                  .
                </div>
              )}
            </div>
            <form className="form" onSubmit={handleLogin}>
              <Input
                label="Email address"
                labelSize={"base"}
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                placeholder="Enter your email address"
              />
              <div className="login-page__password-wrapper">
                <Input
                  labelSize={"base"}
                  label="Password"
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <span className="typography">
                  Forgot your password?{" "}
                  <Link
                    className="typography typography--link"
                    to="/forgotPassword"
                  >
                    Click Here
                  </Link>
                  .
                </span>
              </div>
              <Button htmlType="submit" onClick={handleLogin}>
                Login
              </Button>
              <span className="typography">
                Don't have an account?{" "}
                <Link className="typography typography--link" to="/signup">
                  Register Here
                </Link>
                .
              </span>
            </form>
          </>
        )}
      </div>
    </SplitScreen>
  );
};

export default Login;
