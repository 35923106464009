import { useCallback, useEffect, useState } from "react";

import { getData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";
import { extractFileNameFromHeader } from "../utils/helpers";

const useGetGreenCardDownloadFile = (id: string | undefined, fetch: boolean) => {
  const { token, silentRenew } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const getGreenCardDownloadFile = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        const newToken = await silentRenew();
        const response = await getData(`greenCards/${id}/download`, newToken,true);
        
        const contentDisposition = response.headers.get('content-disposition');
        const fileNameFromHeader = extractFileNameFromHeader(contentDisposition);
        const suggestedFileName = fileNameFromHeader ? fileNameFromHeader : 'GreenCard.pdf';
        setIsLoading(false);
        return { response, suggestedFileName };
      } catch (error: any) {
        setIsLoading(false);
      }
    },
    [silentRenew]
  );

  useEffect((): void => {
    if (fetch && token && id != undefined) getGreenCardDownloadFile(id);
  }, [fetch, token, id]);

  return { getGreenCardDownloadFile, isLoading };
};

export default useGetGreenCardDownloadFile;
