import React, { useCallback, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import SplitScreen from "../../components/SplitScreen";
import PasswordStrengthMeter from "../../components/PasswordStrengthMeter";
import { postData } from "../../utils/fetchApi";
import { passwordRegExp } from "../../utils/validations";

const ResetPassword: React.FC = () => {
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [notAllowed, setNotAllowed] = useState(false);

  const [password, setPassword] = useState("");
  const { state } = useLocation();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParameters] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();

      setHasError(false);
      setPasswordsDontMatch(false);
      setPasswordInvalid(false);

      if (password !== confirmPassword) {
        setPasswordsDontMatch(true);
        return;
      }

      if (!passwordRegExp.test(password)) {
        setPasswordInvalid(true);
        return;
      }

      const passwordResponse = await postData(
        `users/${state?.id ? state?.id : searchParameters.get("id")}/password`,
        {
          password: password,
        },
        state?.token ? state.token : searchParameters.get("token")
      );

      if (!passwordResponse.success) {
        if(passwordResponse.statusCode === 400){
          setPasswordInvalid(true);
          return;
        } 
        setNotAllowed(true);
        return;
      }

      if (!passwordResponse?.data?.hasAuthenticator) {
        navigate("/mfaSetup", {
          state: {
            ...passwordResponse.data,
          },
        });
      } else {
        navigate("/login", { replace: true });
      }
    },
    [password, confirmPassword, state, searchParameters]
  );

  return (
    <SplitScreen>
      <div className="reset-password">
        <div className="typography typography--h1">Set your password</div>
        {passwordInvalid && (
          <div className="typography typography--error">
            Your password doesn't match our minimum requirements listed below.
            Please enter another password.
          </div>
        )}
        {passwordsDontMatch && (
          <div className="typography typography--error">
            Your passwords don't match. Please try again.
          </div>
        )}
        {hasError && (
          <div className="typography typography--error">
            Something went wrong.
          </div>
        )}
        {notAllowed && (
          <div className="typography typography--error">
            Your token has expired. Please click{" "}
            <Link
              className="typography typography--link"
              to={"/forgotPassword"}
            >
              here
            </Link>{" "}
            to request a new token.
          </div>
        )}
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="reset-password__wrapper">
          <div className="reset-password__input-wrapper">
            <Input
              smallGap
              label="Password"
              type="password"
              hasMessage={hasError || passwordInvalid || passwordsDontMatch}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
            <Input
              smallGap
              label="Confirm Password"
              type="password"
              hasMessage={hasError || passwordInvalid || passwordsDontMatch}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
            />
          </div>
          <PasswordStrengthMeter password={password} />
        </div>
        <div className="reset-password__submit">
          <Button onClick={handleSubmit}>Set password</Button>
          <div className="reset-password__caption">
            <span className="typography typography--cap">
              *Minimum of 14 characters
            </span>
            <span className="typography typography--cap">
              *1 uppercase letter; 1 lowercase letter; 1 digit; 1 special
              character
            </span>
          </div>
        </div>
      </form>
    </SplitScreen>
  );
};

export default ResetPassword;
