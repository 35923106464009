import { useCallback, useEffect, useState } from "react";

import { getData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";

const useGetBrokers = (
  itemsPerPage: number,
  currentPage: number,
  search?: string,
  userType?: string,
  brokerStatus?: string
) => {
  const { token, silentRenew } = useAuth();
  const [brokers, setBrokers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const exportBrokers = useCallback(async () => {
    try {
      setIsLoading(true);
      const newToken = await silentRenew();
      const response = await getData(
        `users/export?search=${search}&page=${currentPage}&limit=${itemsPerPage}&userType=${userType}&brokerStatus=${brokerStatus}`,
        newToken,
        true
      );
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setIsLoading(false);
    }
  }, [currentPage, itemsPerPage, search, silentRenew, userType, brokerStatus]);

  const getBrokers = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      const newToken = await silentRenew();
      const response = await getData(
        `users?search=${search}&page=${currentPage}&limit=${itemsPerPage}&userType=${userType}&brokerStatus=${brokerStatus}`,
        newToken
      );
      setBrokers(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setBrokers({ code: error.code, message: error.message });
      setIsLoading(false);
    }
  }, [currentPage, itemsPerPage, search, silentRenew, userType, brokerStatus]);

  useEffect((): void => {
    if (token) getBrokers();
  }, [currentPage, itemsPerPage, search, token, userType, brokerStatus]);

  return { brokers, isLoading, exportBrokers };
};

export default useGetBrokers;
