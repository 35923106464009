import { useLocation, useNavigate } from "react-router";
import Button, { ButtonType } from "../Button";
import { useAuth } from "../../contexts/auth";

const AdminNavbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const brokersSelected = location.pathname.includes("/brokers");
  const coverNotesSelected = location.pathname.includes("admin/coverNotes");
  const greenCardsSelected = location.pathname.includes("admin/greenCards");
  const statsSelected = location.pathname.includes("admin/stats");

  return (
    <div className="admin-layout__navigation">
      <div className="admin-layout__navigation__container">
        { !user?.isViewOnly && (
          <div className="admin-layout__navigation__wrapper">
            <Button
              modifierClassName={`admin-layout__navigation__wrapper__button ${
                brokersSelected
                  ? "admin-layout__navigation__wrapper__button--selected"
                  : ""
              }`}
              onClick={() => navigate("/admin/brokers")}
              type={ButtonType.AdminNavBarButton}
            >
              Brokers
            </Button>
          </div>
        )}
        <div className="admin-layout__navigation__wrapper">
          <Button
            modifierClassName={`admin-layout__navigation__wrapper__button ${
              coverNotesSelected
                ? "admin-layout__navigation__wrapper__button--selected"
                : ""
            }`}
            type={ButtonType.AdminNavBarButton}
            onClick={() => navigate("/admin/coverNotes")}
          >
            Cover Notes
          </Button>
        </div>
        <div className="admin-layout__navigation__wrapper">
          <Button
            type={ButtonType.AdminNavBarButton}
            modifierClassName={`admin-layout__navigation__wrapper__button ${
              greenCardsSelected
                ? "admin-layout__navigation__wrapper__button--selected"
                : ""
            }`}
            onClick={() => navigate("/admin/greenCards")}
          >
            Green Cards
          </Button>
        </div>
        <div className="admin-layout__navigation__wrapper">
          <Button
            modifierClassName={`admin-layout__navigation__wrapper__button ${
              statsSelected
                ? "admin-layout__navigation__wrapper__button--selected"
                : ""
            }`}
            type={ButtonType.AdminNavBarButton}
            onClick={() => navigate("/admin/stats")}
          >
            Stats
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
