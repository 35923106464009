import React, { useCallback, useEffect, useState } from "react";
import MainLayout from "../../layouts/main-layout";
import Input from "../../components/Input";
import Button, { ButtonType } from "../../components/Button";
import { useAuth } from "../../contexts/auth";
import useGetBroker from "../../hooks/useGetBroker";
import { initialForm, initialFormErrors } from "../../forms/userDetails.form";
import { handleApiResponseForm, validateForm } from "../../utils/formHelper";
import { putData } from "../../utils/fetchApi";
import PasswordStrengthMeter from "../../components/PasswordStrengthMeter";
import { showAlertMessage } from "../../utils/helpers";
import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";

import { initialForm as passwordInitialForm, initialFormErrors as passwordInitialErrors } from "../../forms/changePassword.form";
import { useNavigate } from "react-router-dom";
import { passwordRegExp } from "../../utils/validations";

const Profile = () => {
	const navigate = useNavigate();
	const { user, silentRenew, Logout, bypassMfa } = useAuth();
	const [fetch, setFetch] = useState(true);
	const [editDetails, setEditDetails] = useState(false);
	const [changePassword, setChangePassword] = useState(false);
	const [passwordInvalid, setPasswordInvalid] = useState(false);
	const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
	const [oldPasswordIncorrect, setOldPasswordIncorrect] = useState(false);

	const [formData, setFormData] = useState(initialForm);
	const [formErrors, setFormErrors] = useState(initialFormErrors);
	const [loading, setLoading] = useState(false);

	const [passwordFormData, setPasswordFormData] = useState(passwordInitialForm);
	const [passwordFormErrors, setPasswordFormErrors] = useState(passwordInitialErrors);

	const { broker, isLoading } = useGetBroker(user?.userId as string, fetch);

	useEffect(() => {
		if (broker) {
			setFetch(false);
			setFormData(broker);
		}
	}, [broker]);

	const handleOnChange = useCallback(
		(e: any) => {
			const { name, value } = e.target;
			let val = value === "" ? null : value;
			setFormData({
				...formData,
				[name]: val,
			});
		},
		[formData, setFormData]
	);

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setLoading(true);
		if (!validateForm(initialFormErrors, setFormErrors, formData)) {
			setLoading(false);
			return;
		}

		await postFormData();
	};

	const handleOnPasswordChange = useCallback(
		(e: any) => {
			const { name, value } = e.target;
			setPasswordFormData({
				...passwordFormData,
				[name]: value,
			});
		},
		[passwordFormData, setPasswordFormData]
	);

	const handleChangePassword = useCallback(
		async (event: any) => {
			event.preventDefault();

			if (!validateForm(passwordInitialErrors, setPasswordFormErrors, passwordFormData)) {
				return;
			}

			setOldPasswordIncorrect(false);
			setPasswordsDontMatch(false);
			setPasswordInvalid(false);

			if (passwordFormData.newPassword !== passwordFormData.newPasswordConfirmation) {
				setPasswordsDontMatch(true);
				return;
			}

			if (!passwordRegExp.test(passwordFormData.newPassword)) {
				setPasswordInvalid(true);
				return;
			}

			const newToken = await silentRenew();
			const passwordResponse = await putData(`users/password`, passwordFormData, newToken);

			if (!passwordResponse?.success && passwordResponse?.statusCode === 400) {
				setOldPasswordIncorrect(true);
				return;
			}

			if (!passwordResponse?.success && passwordResponse?.statusCode === 401) {
				Logout(true);
				return;
			}

			if (!passwordResponse?.success) {
				showAlertMessage("Error", "An error occurred setting the password", "error");
				cancelPassword();
				return;
			}

			showAlertMessage("Success", "The password was successfully changed", "success");

			cancelPassword();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[passwordFormData, user?.accessToken]
	);

	const postFormData = useCallback(async () => {
		const newToken = await silentRenew();
		const request = await putData(user?.isAdmin ? `users/${user?.userId}/admin-profile` : `users/${user?.userId}`, formData, newToken);

		if (!request.success) {
			handleApiResponseForm(request, initialFormErrors, setFormErrors);
			showAlertMessage("Error", "An error occurred updating your profile", "error");
			setLoading(false);
			return;
		}

		showAlertMessage("Success", "Your profile was successfully updated", "success");
		setFetch(true);
		setEditDetails(false);
		setLoading(false);
		await silentRenew(true);
	}, [formData, silentRenew, user?.userId]);

	const resetPassword = () => {
		setPasswordFormData(passwordInitialForm);
		setPasswordFormErrors(passwordInitialErrors);
		setPasswordsDontMatch(false);
		setPasswordInvalid(false);
		setOldPasswordIncorrect(false);
	};

	const cancelPassword = () => {
		resetPassword();
		setChangePassword(false);
	};

	return (
		<MainLayout isPrivate loading={isLoading || loading} allowViewOnly allowInfrastructure>
			<div className="profile">
				<div className="profile__heading">
					<div className="profile__heading__text">
						<span className="typography typography--h2">Update Registration Details</span>
						<span className="typography typography--italic">*Can't edit your own super privileges</span>
					</div>
				</div>
				{broker && (
					<div className="profile__container">
						<form className="form form--small-gap">
							<div className="form__input-line">
								<Input
									value={formData?.firstName}
									name="firstName"
									required={!!formErrors.firstName.required}
									onChange={handleOnChange}
									hasMessage={formErrors.firstName.error && formErrors.firstName.message}
									maxLength={32}
									label="First Name"
									disabled={!editDetails}
									smallGap
								/>
								<Input
									value={formData?.lastName}
									onChange={handleOnChange}
									required={!!formErrors.lastName.required}
									hasMessage={formErrors.lastName.error && formErrors.lastName.message}
									maxLength={32}
									name="lastName"
									label="Surname"
									disabled={!editDetails}
									smallGap
								/>
							</div>
							<div className="form__input-line">
								<Input
									onChange={handleOnChange}
									value={formData?.jobTitle}
									required={!!formErrors.jobTitle.required}
									hasMessage={formErrors.jobTitle.error && formErrors.jobTitle.message}
									name="jobTitle"
									maxLength={75}
									label="Job Title"
									disabled={!editDetails}
									smallGap
								/>
								{user?.isInfrastructure ? (
									<Input
										onChange={handleOnChange}
										value={formData.email}
										required={!!formErrors.email.required}
										hasMessage={formErrors.email.error && formErrors.email.message}
										name="email"
										label="Email Address"
										disabled={true}
										smallGap
									/>
								) : (
									<div className="form__input-line__double">
										<Input
											onChange={handleOnChange}
											value={formData.brokerName}
											maxLength={32}
											required={!!formErrors.brokerName.required}
											hasMessage={formErrors.brokerName.error && formErrors.brokerName.message}
											name="brokerName"
											label="Broker Name"
											disabled={!user?.isAdmin || !editDetails}
											smallGap
										/>
									</div>
								)}
							</div>
							{!user?.isInfrastructure && (
								<>
									<div className="form__input-line">
										<Input
											onChange={handleOnChange}
											value={formData.companyAddressLine1}
											required={!!formErrors.companyAddressLine1.required}
											hasMessage={formErrors.companyAddressLine1.error && formErrors.companyAddressLine1.message}
											maxLength={37}
											name="companyAddressLine1"
											label="Company Address Line 1"
											disabled={!editDetails}
											smallGap
										/>
										<Input
											onChange={handleOnChange}
											value={formData.companyAddressLine2}
											label="Company Address Line 2"
											maxLength={37}
											required={!!formErrors.companyAddressLine2.required}
											hasMessage={formErrors.companyAddressLine2.error && formErrors.companyAddressLine2.message}
											name="companyAddressLine2"
											disabled={!editDetails}
											smallGap
										/>
									</div>
									<div className="form__input-line">
										<Input
											onChange={handleOnChange}
											value={formData.agencyNumber}
											required={!!formErrors.agencyNumber.required}
											hasMessage={formErrors.agencyNumber.error && formErrors.agencyNumber.message}
											maxLength={10}
											name="agencyNumber"
											label="Agency Number"
											disabled={!user?.isAdmin || !editDetails}
											smallGap
										/>
										<Input
											onChange={handleOnChange}
											value={formData.postCode}
											required={!!formErrors.postCode.required}
											maxLength={10}
											hasMessage={formErrors.postCode.error && formErrors.postCode.message}
											name="postCode"
											label="Post Code"
											disabled={!editDetails}
											smallGap
										/>
									</div>

									<div className="form__input-line">
										<Input
											onChange={handleOnChange}
											value={formData.email}
											required={!!formErrors.email.required}
											hasMessage={formErrors.email.error && formErrors.email.message}
											name="email"
											label="Email Address"
											disabled={true}
											smallGap
										/>
										<Input
											onChange={handleOnChange}
											value={formData.workTelephoneNumber}
											required={!!formErrors.workTelephoneNumber.required}
											hasMessage={formErrors.workTelephoneNumber.error && formErrors.workTelephoneNumber.message}
											name="workTelephoneNumber"
											label="Telephone Number"
											disabled={!editDetails}
											smallGap
										/>
									</div>
								</>
							)}
						</form>
						{!user?.isInfrastructure && (
							<div className="profile__actions">
								<div className="profile__actions__buttons-wrapper">
									{!editDetails ? (
										<Button type={ButtonType.Primary} onClick={() => setEditDetails(!editDetails)}>
											Edit
										</Button>
									) : (
										<>
											<Button
												type={ButtonType.Secondary}
												onClick={() => {
													setEditDetails(false);
													setFormErrors(initialFormErrors);
												}}
											>
												Cancel
											</Button>
											<Button type={ButtonType.Primary} onClick={handleSubmit}>
												Save changes
											</Button>
										</>
									)}
								</div>
							</div>
						)}
						<div className="form form--small-gap">
							<span className="typography typography--h3">Password</span>
							{!changePassword ? (
								<div className="form__input-line ">
									<div className="profile__password-input">
										<Input value={"***********************"} disabled label="Your password" smallGap />
									</div>
									<div className="profile__password-input profile__password-input--end">
										<Button type={ButtonType.Primary} onClick={() => setChangePassword(true)}>
											Change password
										</Button>
									</div>
								</div>
							) : (
								<>
									<div>
										{passwordInvalid && <div className="typography typography--error">Your password doesn't match our minimum requirements listed below. Please enter another password.</div>}
										{passwordsDontMatch && <div className="typography typography--error">Your passwords don't match. Please try again.</div>}
										{oldPasswordIncorrect && <div className="typography typography--error">We've not been able to match your old password. Please try again.</div>}
									</div>
									<div className="form__input-line ">
										<Input
											label="Old Password"
											type="password"
											hasMessage={(passwordFormErrors.oldPassword.error && passwordFormErrors.oldPassword.message) || oldPasswordIncorrect}
											value={passwordFormData.oldPassword}
											onChange={handleOnPasswordChange}
											placeholder="Confirm your old password"
											name="oldPassword"
											smallGap
											required
										/>
										<Input
											label="Confirm new password"
											type="password"
											hasMessage={(passwordFormErrors.newPasswordConfirmation.error && passwordFormErrors.newPasswordConfirmation.message) || passwordInvalid || passwordsDontMatch}
											value={passwordFormData.newPasswordConfirmation}
											onChange={handleOnPasswordChange}
											placeholder="Confirm your new password"
											name="newPasswordConfirmation"
											smallGap
											required
										/>
									</div>
									<div className="form__input-line">
										<Input
											label="New Password"
											type="password"
											hasMessage={(passwordFormErrors.newPassword.error && passwordFormErrors.newPassword.message) || passwordInvalid || passwordsDontMatch}
											value={passwordFormData.newPassword}
											onChange={handleOnPasswordChange}
											placeholder="Enter your new password"
											name="newPassword"
											smallGap
											required
										/>
										<div className="input-wrapper input-wrapper--small-gap">
											<PasswordStrengthMeter password={passwordFormData.newPassword} />
										</div>
									</div>
									<div className="profile__actions">
										<div className="profile__caps">
											<span className="typography typography--cap">*Minimum of 14 characters</span>
											<span className="typography typography--cap">*1 uppercase letter; 1 lowercase letter; 1 digit; 1 non special character</span>
										</div>
										<div className="profile__actions__buttons-wrapper">
											<Button type={ButtonType.Secondary} onClick={() => cancelPassword()}>
												Cancel
											</Button>
											<Button type={ButtonType.Primary} onClick={handleChangePassword}>
												Save changes
											</Button>
										</div>
									</div>
								</>
							)}
						</div>
						{!user?.isInfrastructure && !bypassMfa && (
							<div className="form form--small-gap">
								<span className="typography typography--h3">Multi-factor authentication</span>
								<div className="profile__info-banner">Your account is secured by multi-factor authentication.</div>
								<div className="profile__mfa-step" onClick={() => navigate("/brokerDetails/mfaSetup")}>
									<span className="profile__mfa-step-text">Configure authenticator app</span>
									<ArrowRight className="profile__mfa-step-arrow" />
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</MainLayout>
	);
};

export default Profile;
