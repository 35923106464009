import { Link } from "react-router-dom";

const Footer: React.FC = () => {
	return (
		<footer className="main-layout__footer">
			<div className="main-layout__footer__container">
				<Link to="https://www.ers.com" className="main-layout__footer__link">
					<img src="/images/images/logo-white.png" className="main-layout__footer__logo" alt="ERS Logo" />
					<span className="main-layout__footer__title">The specialist motor insurer</span>
				</Link>
			</div>
		</footer>
	);
};

export default Footer;
