import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactComponent as MigrationInfo } from "../icons/migration-info.svg";
import { Popover } from "rsuite";
import { Link } from "react-router-dom";

const DocumentMigratedInfo: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="action-menu">
        <div className="action-menu__info" onClick={toggling}>
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsOpen(false);
            }}
          > 
            <MigrationInfo />
            
            {isOpen && (
              <div className="action-menu__container action-menu__container--migrated-info">
                <Popover visible>
                  <div className="action-menu__container__header action-menu__container__header--migrated-info">
                      <span className="typography typography--bold typography--primary">i</span>
                  </div>
                  <div className="action-menu__container__migrated-info">
                    <span className="typography typography--caption">
                      As this document was created on a previous version of our portal, some actions such as issuing continuations and downloading are not possible.
                      Please issue a new document, or contact the team to download or void a previously issued document:{" "}
                      <Link
                        className="typography typography--italic typography--link text-sm "
                        to="mailto:agency@ers.com"
                      >
                        agency@ers.com
                      </Link>
                    </span>
                  </div>
                </Popover>
              </div>
            )}
          </OutsideClickHandler>
        </div>
      </div>
    </>
  );
};

export default DocumentMigratedInfo;
