import Input from "../Input";

interface Props {
  formData: any;
  formErrors: any;
  handleOnChange: any;
}

const PolicyDetails: React.FC<Props> = ({
  formData,
  formErrors,
  handleOnChange,
}: Props) => {
  return (
    <div className="green-card__input-wrapper">
      <Input
        value={formData.policyNumber}
        name="policyNumber"
        label="Policy number (if known)"
        smallGap
        maxLength={15}
        placeholder="123456789"
        onChange={handleOnChange}
        hasMessage={
          formErrors.policyNumber.error && formErrors.policyNumber.message
        }
      />
    </div>
  );
};

export default PolicyDetails;
