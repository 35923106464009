import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDownFilter } from "../icons/arrow-down-filter.svg";
import { ReactComponent as CheckIcon } from "../icons/done_light.svg";
const FilterTableDropdown = ({
  selected,
  options,
  label,
  setSelected,
}: {
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  label: string;
  options?: string[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const ref = useRef<any>(null);

  const [optionsSelected, setOptionsSelected] = useState(selected);

  const handleSelect = (option: string) => {
    if (optionsSelected.includes(option) && optionsSelected.length > 1) {
      setOptionsSelected(optionsSelected.filter((it) => it !== option));
      return
    }

    if (optionsSelected.includes(option)) {
      return;
    }
    
    setOptionsSelected([...optionsSelected, option]);
  };

  useEffect(() => {
    const handleClickOutside = (event : any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        isOpen && setIsOpen(false);

        setSelected(optionsSelected);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    if (isOpen)
      return;

    setSelected(optionsSelected); 
  }, [isOpen, setSelected]);

  return (
    <div
      className="filter-table"
      ref={ref}
    >
      <div className="filter-table__input" onClick={toggleDropdown}>
        {label}
        <ArrowDownFilter
          width={10}
          height={12}
          className={`${isOpen ? "filter-table__arrow-open" : ""}`}
        />
      </div>
      {isOpen && (
        <ul className="filter-table__dropdown-container">
          {options?.map((option: string, index: number) => (
            <li key={index} className="filter-table__item">
              <div className="checkbox">
                <div
                  className={`checkbox__box checkbox__box--filter ${
                    optionsSelected.find((it) => option === it)
                      ? "checkbox__box--checked"
                      : ""
                  }`}
                  onClick={(e: any) => { e.preventDefault(); handleSelect(option); return;}}
                >
                  {optionsSelected.find((it) => option === it) && (
                    <CheckIcon width={8} height={8} />
                  )}
                </div>
                <div className="checkbox__label checkbox__label--filter">
                  {option}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterTableDropdown;
