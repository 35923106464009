export enum BrokerStatus {
  WaitingForApproval = "WaitingForApproval",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum CoverNoteStatus {
  Active = "Active",
  Expired = "Expired",
  Extended = "Extended",
  IsExtension = "IsExtension",
  Voided = "Voided",
  Migrated = "Migrated"
}

export const userTypeOptions = [
  "Broker",
  "Admin",
  "Broker (Key Contact)",
  "View Only",
];

interface OptionsMap {
  [key: string]: string;
}

export const userTypeOptionsMap : OptionsMap = {
  "Broker": "Broker",
  "Admin": "Admin",
  "Broker (Key Contact)": "BrokerKeyContact",
  "View Only": "ViewOnly"
}

export const isActiveOptions = ["Yes", "No", "Pending"];

export const isActionOptionsMap : OptionsMap = {
  "Yes": "Approved",
  "Pending": "WaitingForApproval",
  "No": "Rejected,Inactive"
}
export enum UserType {
  Broker = "Broker",
  Admin  = "Admin",
  ViewOnly = "ViewOnly"
}
export enum HiddenUserType {
  InfrastructureAdmin = "InfrastructureAdmin"
}