import { useCallback, useEffect, useState } from "react";

import { getData } from "../utils/fetchApi";
import { extractFileNameFromHeader } from "../utils/helpers";
import { useAuth } from "../contexts/auth";

const useGetCoverNoteDownloadFile = (id: string | undefined, downloadDocument: boolean) => {
  const { token, silentRenew } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const getCoverNoteDownloadFile = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        const newToken = await silentRenew();
        const response = await getData(`coverNotes/${id}/download`, newToken, true);
        
        const contentDisposition = response.headers.get('content-disposition');
        const fileNameFromHeader = extractFileNameFromHeader(contentDisposition);
        const suggestedFileName = fileNameFromHeader ? fileNameFromHeader : 'CoverNote.pdf';

        setIsLoading(false);
        return   { response, suggestedFileName };
      } catch (error: any) {
        setIsLoading(false);
      }
    },
    [silentRenew]
  );

  useEffect((): void => {
    if (downloadDocument && token && id != undefined) getCoverNoteDownloadFile(id);
  }, [downloadDocument, token, id]);

  return {  isLoading, getCoverNoteDownloadFile };
};

export default useGetCoverNoteDownloadFile;
