import { selectOptions } from "../../forms/brokerIssueCoverNote.form";
import Dropdown from "../Dropdown";

interface Props {
  formData: any;
  formErrors: any;
  setFormData: any;
  isExtension?: boolean;
}

const PolicyUse: React.FC<Props> = ({
  formData,
  formErrors,
  setFormData,
  isExtension,
}: Props) => {
  return (
    <>
      <div className="cover-note__input-wrapper cover-note__input-wrapper--full-line">
        <Dropdown
          value={formData.policyUse}
          required={formErrors.policyUse.required}
          disabled={isExtension}
          label="Policy use"
          smallGap
          isClearable={false}
          onChange={(option) => {
            setFormData({
              ...formData,
              policyUse: option,
            });
          }}
          options={selectOptions.policyUse.policyUse}
          placeholder="Please select"
          hasMessage={
            formErrors.policyUse.error && formErrors.policyUse.message
          }
        />
      </div>
    </>
  );
};

export default PolicyUse;
