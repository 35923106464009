import { useCallback, useEffect, useState } from "react";

import { getData } from "../utils/fetchApi";
import { useAuth } from "../contexts/auth";

const useGetCoverNotes = (
  fetch: boolean,
  itemsPerPage: number,
  currentPage: number,
  search: string,
  product: string,
  start: Date | null,
  expire: Date | null,
  isAdmin?: boolean
) => {
  const { token, silentRenew } = useAuth();
  const [coverNotes, setCoverNotes] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const exportCoverNotes = useCallback(async () => {
    try {
      if (start) start.setHours(0, 0, 0);

      if (expire) expire.setHours(23, 59, 59);

      setIsLoading(true);
      const newToken = await silentRenew();
      const response = await getData(
        `coverNotes/${isAdmin ? "admin/" : ""}export?search=${search}&product=${product}&start=${
          start ? start?.toISOString() : ""
        }&expire=${expire ? expire?.toISOString() : ""}`,
        newToken,
        true
      );

      setIsLoading(false);
      return response;
    } catch (error: any) {
      setIsLoading(false);
    }
  }, [expire, product, search, silentRenew, start, isAdmin]);

  const getCoverNotes = useCallback(async (): Promise<void> => {
    try {
      if (start) start.setHours(0, 0, 0);

      if (expire) expire.setHours(23, 59, 59);

      setIsLoading(true);
      const newToken = await silentRenew();
      const response = await getData(
        `${
          isAdmin ? "coverNotes/admin" : "coverNotes"
        }?search=${search}&product=${product}&page=${currentPage}&limit=${itemsPerPage}&start=${
          start ? start?.toISOString() : ""
        }&expire=${expire ? expire?.toISOString() : ""}`,
        newToken
      );
      setCoverNotes(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setCoverNotes({ code: error.code, message: error.message });
      setIsLoading(false);
    }
  }, [
    start,
    expire,
    isAdmin,
    search,
    product,
    currentPage,
    itemsPerPage,
    token,
  ]);

  useEffect((): void => {
    if (fetch && token) getCoverNotes();
  }, [fetch, token, currentPage, itemsPerPage, isAdmin]);

  return { coverNotes, isLoading, exportCoverNotes };
};

export default useGetCoverNotes;
