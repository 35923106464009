import { isAfter, isBefore } from "date-fns";
import { useCallback, useState } from "react";
import { DatePicker as RSDatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

interface Props {
  onChange?: (d: any) => void;
  value: Date | null;
  label?: string;
  hasMessage?: string | boolean;
  disableDateBefore?: Date | null;
  disableDateAfter?: Date | null;
  valueOnOpen?: Date | null;
  disabled?: boolean;
  minDate?: Date;
  required?: boolean;
  placement?: any;
}

const DatePicker: React.FC<Props> = ({
  label,
  value,
  onChange,
  hasMessage,
  disabled,
  disableDateBefore = null,
  disableDateAfter = null,
  valueOnOpen = null,
  required,
  placement = "bottomStart"
}: Props) => {

  const [date, setDate] = useState<Date|null>(null);

  const datePicker = useCallback(() => (
    <RSDatePicker
        block
        disabled={disabled}
        placeholder="DD/MM/YYYY"
        format="dd/MM/yyyy"
        value={value || (valueOnOpen ? date : null)}
        className={hasMessage ? "date-picker__error" : ""}
        onChange={onChange}
        onOk={(date ) => onChange && onChange(date)}
        isoWeek
        oneTap
        onEnter={() => {
          if ((!date || value) && !valueOnOpen ) {
            return;
          }

          setDate(valueOnOpen)
        }}
        onSelect={onChange}
        onClose={() => setDate(null)}
        shouldDisableDate={(date) => {
          let shouldDisable = false;

          if (disableDateAfter && isAfter(date, disableDateAfter)) {
            shouldDisable = true;
          }

          if (disableDateBefore && isBefore(date, disableDateBefore)) {
            shouldDisable = true;
          }

          return shouldDisable;
        }}
        placement={placement}
      />
  ), [disabled, value, hasMessage, disableDateAfter, disableDateBefore, valueOnOpen ,placement, date]);

  return (
    <div className="date-picker">
      {label != null && (
        <p className="typography text-2base typography--bold">
          {label}
          {required && <span className="typography typography--bold">*</span>}
        </p>
      )}
      { datePicker() }
      {!!hasMessage && (
        <div className="date-picker__message">
          <span className="typography typography--error">{hasMessage}</span>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
