import Input from "../Input";

interface Props {
  formData: any;
  formErrors: any;
  setFormErrors: any;
  setFormData: any;
  handleOnChange: any;
  isExtension?: boolean;
  isReused?: boolean;
}

const BrokerDetails: React.FC<Props> = ({
  formData,
  formErrors,
  isExtension,
  handleOnChange,
}: Props) => {
  return (
    <>
      <div className="cover-note__input-wrapper ">
        <Input
          value={formData?.agencyName}
          required={formErrors?.agencyName?.required}
          label="Broker name"
          disabled={!!isExtension}
          smallGap
          name="agencyName"
          onChange={handleOnChange}
          placeholder="Enter broker name"
          hasMessage={
            formErrors?.agencyName?.error && formErrors?.agencyName?.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData?.agencyNumber}
          required={formErrors?.agencyNumber?.required}
          smallGap
          label="Agency number"
          disabled={!!isExtension}
          name="agencyNumber"
          onChange={handleOnChange}
          placeholder="Enter agency number"
          hasMessage={
            formErrors?.agencyNumber?.error && formErrors?.agencyNumber?.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper ">
        <Input
          value={formData?.agencyAddressLine1}
          required={formErrors?.agencyAddressLine1?.required}
          label="Address Line 1"
          disabled={!!isExtension}
          smallGap
          name="agencyAddressLine1"
          onChange={handleOnChange}
          placeholder="Enter address line 1"
          maxLength={37}
          hasMessage={
            formErrors?.agencyAddressLine1?.error &&
            formErrors?.agencyAddressLine1?.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData.agencyAddressLine2}
          required={formErrors.agencyAddressLine2.required}
          smallGap
          label="Address Line 2"
          disabled={!!isExtension}
          name="agencyAddressLine2"
          onChange={handleOnChange}
          maxLength={37}
          placeholder="Enter address line 2"
          hasMessage={
            formErrors.agencyAddressLine2.error &&
            formErrors.agencyAddressLine2.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData?.agencyPostCode}
          required={formErrors?.agencyPostCode?.required}
          label="Post Code"
          disabled={!!isExtension}
          smallGap
          name="agencyPostCode"
          maxLength={10}
          onChange={handleOnChange}
          placeholder="Enter Post Code"
          hasMessage={
            formErrors?.agencyPostCode?.error &&
            formErrors?.agencyPostCode?.message
          }
        />
      </div>
      <div className="cover-note__input-wrapper">
        <Input
          value={formData?.agencyTelephoneNumber}
          required={formErrors?.agencyTelephoneNumber?.required}
          label="Telephone number"
          smallGap
          disabled={!!isExtension}
          name="agencyTelephoneNumber"
          onChange={handleOnChange}
          placeholder="Enter telephone number"
          hasMessage={
            formErrors?.agencyTelephoneNumber?.error &&
            formErrors?.agencyTelephoneNumber?.message
          }
        />
      </div>
    </>
  );
};

export default BrokerDetails;
