import { useCallback, useEffect, useMemo, useState } from "react";
import useGetCidrsBlocks from "../../hooks/useGetCidrBlocks";
import CidrEntry from "./CidrEntry";
import Button, { ButtonType } from "../Button";
import { useAuth } from "../../contexts/auth";
import { ApiResponse, putData } from "../../utils/fetchApi";
import { showAlertMessage } from "../../utils/helpers";
import { IpRange, validateForm } from "../../forms/whitelist.form";
import DeleteCidrEntry from "./DeleteCidrEntry";

interface Props {
	setIsLoading: (loading: boolean) => void;
}

const CidrTable: React.FC<Props> = ({ setIsLoading }) => {
	const { silentRenew } = useAuth();
	const [refresh, setRefresh] = useState<boolean>(true);
	const { cidrBlocks } = useGetCidrsBlocks(refresh);
	const [currentCidrBlocks, setCurrentCidrBlocks] = useState<IpRange[]>([]);
	const [modalIndex, setModalIndex] = useState<number | null>(null);

	const sortedCidrBlocks = useMemo(() => {
		if (!cidrBlocks || !Array.isArray(cidrBlocks)) {
			return null;
		}
		return [...cidrBlocks].sort((a, b) => a.cidrIp.localeCompare(b.cidrIp));
	}, [cidrBlocks]);

	//#region callbacks
	const handleOnChange = useCallback(
		(range: IpRange, index: number) => {
			let replaceBlocks = [...currentCidrBlocks];
			replaceBlocks[index] = range;
			setCurrentCidrBlocks(replaceBlocks);
		},
		[currentCidrBlocks]
	);

	const handleSubmit = useCallback(async () => {
		try {
			setIsLoading(true);
			//validate before submit
			const { hasError, validateCidrBlocks } = validateForm(currentCidrBlocks);

			if (hasError) {
				setCurrentCidrBlocks(validateCidrBlocks);
				setIsLoading(false);
				return;
			}
			const newToken = await silentRenew();
			const response: ApiResponse = await putData(`Infrastructure/ip-whitelist`, currentCidrBlocks, newToken);
			if (!response || !response.success) {
				throw new Error("Failed to update ip whitelist.");
			}
			setRefresh(true);
		} catch (error: any) {
			setIsLoading(false);
			showAlertMessage("IP Whitelist", "Unable to save changes.", "error");
		}
	}, [currentCidrBlocks, setIsLoading, silentRenew]);

	const addBlock = useCallback(() => {
		setCurrentCidrBlocks([...currentCidrBlocks, { cidrIp: "", description: "", isNew: true }]);
	}, [currentCidrBlocks]);

	const removeBlock = useCallback(
		(index: number) => {
			let replaceBlocks = [...currentCidrBlocks];
			replaceBlocks.splice(index, 1);
			setCurrentCidrBlocks(replaceBlocks);
		},
		[currentCidrBlocks]
	);
	const openModal = useCallback(() => {
		if (modalIndex !== null && currentCidrBlocks.find((block: IpRange, index: number) => index === modalIndex)?.isNew) {
			setModalIndex(null);
			return removeBlock(modalIndex);
		} else {
			return (
				<DeleteCidrEntry
					modalIsOpen={modalIndex !== null}
					closeModal={() => setModalIndex(null)}
					onSubmit={() => {
						if (modalIndex !== null) removeBlock(modalIndex);
						setModalIndex(null);
					}}
				/>
			);
		}
	}, [currentCidrBlocks, modalIndex, removeBlock]);

	const printBlock = useCallback(
		() =>
			currentCidrBlocks.map((block: IpRange, index: number) => (
				<CidrEntry block={block} handleOnChange={handleOnChange} index={index} isNew={block.isNew ? true : false} isRefreshing={refresh} handleOnDelete={setModalIndex} />
			)),
		[currentCidrBlocks, handleOnChange, refresh]
	);
	//#endregion

	useEffect(() => {
		if (sortedCidrBlocks) {
			setCurrentCidrBlocks(sortedCidrBlocks);
			setRefresh(false);
			setIsLoading(false);
		}
	}, [setIsLoading, sortedCidrBlocks]);

	return (
		<>
			<div>
				<form className="whitelist__form form form--small-gap">
					<div className="whitelist__form__wrapper">{printBlock()}</div>

					<Button type={ButtonType.Primary} onClick={addBlock}>
						Add new rule
					</Button>
				</form>
				<div className="whitelist__actions">
					<div className="whitelist__actions__buttons-wrapper">
						<Button type={ButtonType.Secondary} onClick={() => setCurrentCidrBlocks(sortedCidrBlocks ? sortedCidrBlocks : [])}>
							Cancel
						</Button>
						<Button type={ButtonType.Primary} onClick={handleSubmit}>
							Save changes
						</Button>
					</div>
				</div>
			</div>
			{modalIndex !== null && openModal()}
		</>
	);
};
export default CidrTable;
