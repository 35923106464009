import TextArea from "../TextArea";

interface Props {
  formData: any;
  formErrors: any;
  handleOnChange: any;
  isExtension?: boolean;
}

const SpecialConditions: React.FC<Props> = ({
  formData,
  formErrors,
  handleOnChange,
  isExtension,
}: Props) => {
  return (
    <>
      <div className="cover-note__input-wrapper cover-note__input-wrapper--full-line">
        <TextArea
          value={formData.specialConditionsOrRestrictions}
          label="This text will appear on the cover note generated:"
          smallGap
          disabled={isExtension}
          placeholder="Type your text"
          onChange={handleOnChange}
          name="specialConditionsOrRestrictions"
          allowNewLines={false}
          maxLength={500}
          hasMessage={
            formErrors.specialConditionsOrRestrictions.error &&
            formErrors.specialConditionsOrRestrictions.message
          }
        />
      </div>
    </>
  );
};

export default SpecialConditions;
